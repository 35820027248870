import { Component, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { FfTooltipArrowPositions } from '@shared/components/ff-tooltip/ff-tooltip-arrow-positions.enum';
import { IFfTooltipOptions } from '@shared/components/ff-tooltip/ff-tooltip-options.interface';

@Component({
  selector: 'app-ff-toggle-reveal-input',
  templateUrl: './ff-toggle-reveal-input.component.html',
  styleUrls: ['./ff-toggle-reveal-input.component.scss'],
})
export class FfToggleRevealInputComponent {
  @Output()
  masked: EventEmitter<boolean> = new EventEmitter();

  @Input()
  iconClassList?: string = '';

  @Output()
  revealed: EventEmitter<boolean> = new EventEmitter();

  @Input()
  showToggleButton?: boolean = false;

  active: boolean = false;
  el: HTMLInputElement;
  revealing: boolean = false;
  tooltipOptions: IFfTooltipOptions = {
    arrowPosition: FfTooltipArrowPositions.bottomRight,
    showOnHover: true,
    showOnLoad: false,
  };

  constructor(private _renderer: Renderer2) {}

  @ViewChild('inputWrap', { static: false })
  set element(inputWrap: ElementRef) {
    if (!inputWrap.nativeElement.children || inputWrap.nativeElement.children.length > 1) {
      console.error('This component requires a single input field in ng-content');
    }

    this.el = inputWrap.nativeElement.children[0];
    this.revealing = this.el.type !== 'password';
  }

  @HostListener('mouseup')
  hostMouseUp() {
    return false;
  }

  /**
   * Toggles the projected <input type=""> between text & password
   */
  toggle() {
    if (this.el.type === 'text') {
      this._renderer.setProperty(this.el, 'type', 'password');
      this.revealing = false;
      this.masked.emit(true);
    } else {
      this._renderer.setProperty(this.el, 'type', 'text');
      this.revealing = true;
      this.revealed.emit(true);
      this.el.select();
    }
  }
}
