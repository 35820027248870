<ul class="users">
  @if (!users || users.length < 1) {
    <li class="user well">
      <span>Ingen testbrukere</span>
    </li>
  }

  @for (usr of users; track usr) {
    <li class="user well">
      <div class="user__info">
        <div class="info name">
          @if (!isUserAdmin) {
            <span>{{ usr.firstName + ' ' + usr.lastName }}</span>
          }
          @if (isUserAdmin) {
            <a [routerLink]="['/users/' + usr.uuid]">{{ usr.firstName + ' ' + usr.lastName }}</a>
          }
        </div>
        @if (usr.email) {
          <div class="info email">
            <a [title]="'Mailto: ' + usr.email" href="mailto:{{ usr.email }}">
              <svg-icon [applyClass]="true" src="assets/icons/envelope.svg" role="presentation"></svg-icon>
            </a>
            <div class="data">{{ usr.email }}</div>
          </div>
        }
        @if (usr.phone) {
          <div class="info phone">
            <a [title]="'Call: ' + usr.phone" href="tel:{{ usr.phone }}">
              <svg-icon [applyClass]="true" src="assets/icons/handset.svg" role="presentation"></svg-icon>
            </a>
            <div class="data">{{ usr.phone }}</div>
          </div>
        }
      </div>
      <app-ff-delete-button
        (clicked)="confirmRemoval(usr)"
        [disabled]="!isAppAdmin"
        [processing]="usr.processing"
        class="user__remove"
        titleText="Fjern tilgang"
      ></app-ff-delete-button>
    </li>
  }
</ul>

@if (processing) {
  <div class="processing">
    <app-ff-loading-indicator class="stroke--mine-shaft" size="s20"></app-ff-loading-indicator>
  </div>
}
