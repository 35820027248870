import { ScopeEntity } from '@core/entities/oauth/scope.entity';
import { alias, list, object, serializable } from 'serializr';

export class ConsentStatusEntity {
  @serializable(alias('approvedScopes', list(object(ScopeEntity))))
  private _approvedScopes: ScopeEntity[] = [];

  @serializable(alias('consentRequired'))
  private _consentRequired: boolean;

  @serializable(alias('rejectedScopes', list(object(ScopeEntity))))
  private _rejectedScopes: ScopeEntity[] = [];

  @serializable(alias('scopes', list(object(ScopeEntity))))
  private _scopes: ScopeEntity[] = [];

  get approvedScopes(): ScopeEntity[] {
    return this._approvedScopes;
  }

  set approvedScopes(value: ScopeEntity[]) {
    this._approvedScopes = value;
  }

  get consentRequired(): boolean {
    return this._consentRequired;
  }

  set consentRequired(value: boolean) {
    this._consentRequired = value;
  }

  get rejectedScopes(): ScopeEntity[] {
    return this._rejectedScopes;
  }

  set rejectedScopes(value: ScopeEntity[]) {
    this._rejectedScopes = value;
  }

  get scopes(): ScopeEntity[] {
    return this._scopes;
  }

  set scopes(value: ScopeEntity[]) {
    this._scopes = value;
  }
}
