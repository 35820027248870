import { HttpClient, HttpResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { CLIENT_ENTITY_SCHEMA } from "@core/entities/api/api-client-entity.schema";
import { ClientEntity } from "@core/entities/client/client.entity";
import { GrantEntity } from "@core/entities/oauth/grant.entity";
import { EnvironmentHelper } from "@core/helpers/environment.helper";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { deserialize } from "serializr";

@Injectable({
  providedIn: "root",
})
export class GrantService {
  readonly #httpClient = inject(HttpClient);

  getOauthGrants(): Observable<GrantEntity[]> {
    return this.#httpClient
      .get(EnvironmentHelper.fetchAPIBase("v1/oauth-grants"), {
        observe: "response",
      })
      .pipe(
        map((response: HttpResponse<{ oauthGrants: object[] }>) => {
          if (response.status === 204) {
            return [];
          } else {
            return deserialize(GrantEntity, response.body.oauthGrants);
          }
        })
      );
  }

  addGrantToClient(
    clientUuid: string,
    grantUuid: string
  ): Observable<ClientEntity> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase(
          "v1/oauth-clients/" + clientUuid + "/allowed-grants/" + grantUuid
        ),
        {}
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  removeGrantFromClient(
    clientUuid: string,
    grantUuid: string
  ): Observable<ClientEntity> {
    return this.#httpClient
      .delete(
        EnvironmentHelper.fetchAPIBase(
          "v1/oauth-clients/" + clientUuid + "/allowed-grants/" + grantUuid
        )
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  addGrantToScope(
    clientUuid: string,
    scopeUuid: string,
    grantUuid: string
  ): Observable<ClientEntity> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase(
          "v1/oauth-clients/" +
            clientUuid +
            "/scopes/" +
            scopeUuid +
            "/allowed-grants/" +
            grantUuid
        ),
        {}
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  removeGrantFromScope(
    clientUuid: string,
    scopeUuid: string,
    grantUuid: string
  ): Observable<ClientEntity> {
    return this.#httpClient
      .delete(
        EnvironmentHelper.fetchAPIBase(
          "v1/oauth-clients/" +
            clientUuid +
            "/scopes/" +
            scopeUuid +
            "/allowed-grants/" +
            grantUuid
        )
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }
}
