import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import { SsnValidationResponseEntity } from '@core/entities/response/ssn-validation-response.entity';
import { UserVerificationEntity } from '@core/entities/user/user-verification.entity';
import { User } from '@core/entities/user/user.entity';
import { EnvironmentHelper, EnvironmentHelper as EnvHelper } from '@core/helpers/environment.helper';
import { UserStoreService } from '@core/services/stores/user-store.service';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, take, tap } from 'rxjs/operators';
import { deserialize, serialize } from 'serializr';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _fetchingUser: boolean = false;

  constructor(private _http: HttpClient, private _userStore: UserStoreService) {}

  checkValidSsn(ssn: string): Observable<SsnValidationResponseEntity> {
    return this._http.get(EnvironmentHelper.fetchAPIBase('v1/validate/ssn/' + ssn)).pipe(
      map((response: object) => {
        return deserialize(SsnValidationResponseEntity, response);
      }),
    );
  }

  deleteUser(userId: string): Observable<User> {
    return this._http.delete(EnvironmentHelper.fetchAPIBase('v2/users/' + userId)).pipe(
      map((response: { user: object }) => {
        return deserialize(User, response.user);
      }),
    );
  }

  existsBySsn(ssn: string): Observable<boolean> {
    return this._http
      .get(EnvHelper.fetchAPIBase('v1/users/exists/by-ssn/' + ssn))
      .pipe(map((response: { exists: boolean }) => response.exists));
  }

  /**
   * Fetches user
   *
   * NB! To fetch logged-in user, use getSelf()
   */
  getUser(userId: string, includeNin = false): Observable<User> {
    const params = includeNin ? { include: 'nin' } : null;
    return this._http.get(EnvHelper.fetchAPIBase('v2/users/' + userId), { params }).pipe(
      map((response: { user: object }) => {
        return deserialize(User, response.user);
      }),
    );
  }

  getPendingInformationUpdates(oauthClientId?: string): Observable<PendingInformationUpdates> {
    let params = new HttpParams();

    if (oauthClientId) {
      params = params.set('client_id', oauthClientId);
    }

    return this._http
      .get(EnvironmentHelper.fetchAPIBase('v1/users/pending-user-info-updates'), {
        params
      })
      .pipe(
        map((response: { userInfo: object }) => {
          return deserialize(PendingInformationUpdates, response.userInfo);
        }),
      );
  }

  /**
   * Returns local instance of logged-in user, or fetches it from API
   *
   * NB! To fetch other user, use getUser()
   *
   */
  getSelf(): Observable<User | null> {
    const user$ = this._http.get(EnvHelper.fetchAPIBase('v2/users/self')).pipe(
      map((response: { user: object }) => {
        return deserialize(User, response.user);
      }),
      tap((user: User) => {
        this._userStore.user = user;
      }),
    );

    let userIsUnauthenticated = false;
    const existingUser: User = this._userStore.user;

    if (existingUser === null && !this._fetchingUser) {
      this._fetchingUser = true;

      return user$.pipe(
        tap(() => {
          this._fetchingUser = false;
        }),
        catchError((error) => {
          if (error.status === 401) {
            userIsUnauthenticated = true;
            this._userStore.user = null;
          }
          this._fetchingUser = false;
          return of(null);
        }),
        concatMap(() => {
          return this._userStore.user$;
        }),
        filter((user: User | null) => {
          if (userIsUnauthenticated) {
            return true;
          } else {
            return null !== user;
          }
        }),
      );
    } else {
      return this._userStore.user$;
    }
  }

  putSelf(user: User, includeNin = false): Observable<User> {
    const params = includeNin ? { include: 'nin' } : null;

    return this._http
      .put(
        EnvHelper.fetchAPIBase('v1/users/self'),
        {
          user: this._convertToOld(user, 'rawSsn'),
        },
        { params },
      )
      .pipe(
        map((response: { user: object }) => {
          const user = this._convertToNew(response.user);
          this._userStore.user = user;
          return user;
        }),
      );
  }

  isSelf(userId: string): Observable<boolean> {
    return this.getSelf().pipe(
      map((user: User | null) => {
        return user.uuid === userId;
      }),
      take(1),
    );
  }

  postUser(user: User): Observable<User> {
    return this._http
      .post(EnvHelper.fetchAPIBase('v1/users'), {
        user: this._convertToOld(user, 'rawSsn'),
      })
      .pipe(
        map((response: { user: object }) => {
          return this._convertToNew(response.user);
        }),
      );
  }

  putUser(userId: string, user: User, includeNin = false): Observable<User> {
    const params = includeNin ? { include: 'nin' } : null;

    return this._http
      .put(
        EnvHelper.fetchAPIBase('v2/users/' + userId),
        {
          user: this._convertToOld(user, 'rawSsn'),
        },
        { params },
      )
      .pipe(
        map((response: { user: object }) => {
          return this._convertToNew(response.user);
        }),
      );
  }

  putUserStatus(userUuid: string, status: string, reason: string): Observable<User> {
    const user$ = this._http
      .put(EnvHelper.fetchAPIBase('v2/users/' + userUuid + '/status'), {
        status,
        reason,
      })
      .pipe(
        map((response: { user: object }) => {
          return deserialize(User, response.user);
        }),
      );

    return this.isSelf(userUuid).pipe(
      concatMap((isSelf: boolean) => {
        return user$.pipe(
          tap((user: User) => {
            if (isSelf) {
              this._userStore.user = user;
            }
          }),
        );
      }),
    );
  }

  putRoles(userUuid: string, enabledRolesUuids: Array<string>): Observable<User> {
    const user$ = this._http
      .put(EnvironmentHelper.fetchAPIBase('v2/users/' + userUuid + '/roles'), {
        roleUuids: enabledRolesUuids,
      })
      .pipe(
        map((response: { user: object }) => {
          return deserialize(User, response.user);
        }),
      );

    return this.isSelf(userUuid).pipe(
      concatMap((isSelf: boolean) => {
        return user$.pipe(
          tap((user: User) => {
            if (isSelf) {
              this._userStore.user = user;
            }
          }),
        );
      }),
    );
  }

  syncUser(user: User): Observable<User> {
    return this._http
      .post(EnvHelper.fetchAPIBase('v1/users/' + user.uuid + '/sync'), {
        syncSystem: 'fane2',
      })
      .pipe(
        map((response: { user: object }) => {
          return deserialize(User, response.user);
        }),
      );
  }

  updatePassword(userId: string, password: string, repeatPassword: string, oldPassword?: string): Observable<object> {
    return this._http.patch(EnvHelper.fetchAPIBase('v2/users/' + userId + '/change-password'), {
      password,
      repeatPassword,
      oldPassword: oldPassword ? oldPassword : null,
    });
  }

  verifyUser(user: User, idType: string): Observable<User> {
    return this._http
      .post(EnvHelper.fetchAPIBase('v1/user-verifications'), {
        source: idType,
        userUuid: user.uuid,
      })
      .pipe(
        map((response: { userVerification: object }) => {
          const verification = deserialize(UserVerificationEntity, response.userVerification);
          const index = user.verifications.findIndex((v) => v.type === verification.type);
          if (index > -1) {
            user.verifications[index] = verification;
          } else {
            user.verifications.push(verification);
          }
          this.isSelf(user.uuid).subscribe((isSelf: boolean) => {
            if (isSelf) {
              this._userStore.user = user;
            }
          });
          return user;
        }),
      );
  }

  /**
   * Convert from User class to old definition
   *
   * @param newUser
   * @param ssnParam Which of the old ssn params to convert the new nin param to
   */
  private _convertToOld(newUser: User, ssnParam: 'ssn' | 'rawSsn') {
    const user = serialize(newUser);
    user[ssnParam] = newUser.nin;
    user['isTestUser'] = newUser.isTestUser;
    user['bankAccount'] = newUser.bankAccount || null;
    return user;
  }

  /**
   *  Convert to User class from old definition
   */
  private _convertToNew(oldUser: object) {
    const user = deserialize(User, oldUser);
    user.nin = oldUser['nin'] || oldUser['ssn'] || oldUser['rawSsn'];
    user.isTestUser = oldUser['isTestUser'];
    return user;
  }
}
