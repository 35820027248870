import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ForbiddenResponse } from '@core/entities/http-responses/forbidden-response.entity';
import { UserFeedbackEntity } from '@core/entities/user/user-feedback.entity';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';
import { ElevatedAccessService } from '@core/services/authentication/elevated-access.service';
import { FfClientSecretModalDataInterface } from '@shared/components/ff-modals/ff-client-secret-modal/ff-client-secret-modal-data.interface';

import { FfModalBaseClassDirective } from '@shared/components/ff-modals/ff-modal-base.class';
import { throwError } from 'rxjs';
import { catchError, finalize, take, tap } from 'rxjs/operators';

@Component({
  selector: 'app-ff-client-secret-modal',
  templateUrl: './ff-client-secret-modal.component.html',
  styleUrls: ['./ff-client-secret-modal.component.scss'],
})
export class FfClientSecretModalComponent extends FfModalBaseClassDirective implements OnInit {
  @Input()
  override data: FfClientSecretModalDataInterface;

  actionCtrl: UntypedFormControl;
  feedbackMessages: UserFeedbackEntity[] = [];
  modalForm: UntypedFormGroup;
  passCtrl: UntypedFormControl;
  secretCtrl: UntypedFormControl;
  submitted = false;
  processing = false;

  constructor(private _elevatedAccessService: ElevatedAccessService, private _fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {
    this.modalForm = this._fb.group({
      password: this._fb.control(null, Validators.required),
      secret: this._fb.control(null),
    });
    this.actionCtrl = this.modalForm.get('action') as UntypedFormControl;
    this.passCtrl = this.modalForm.get('password') as UntypedFormControl;
    this.secretCtrl = this.modalForm.get('secret') as UntypedFormControl;
  }

  /**
   * Callback on cancel/decline
   */
  cancel(): void {
    this.closeModal.emit();
  }

  /**
   * Callback on confirm
   */
  elevate(): void {
    this.submitted = true;

    this._applyConditionalValidators();

    if (!this.modalForm.get('password').value || ReactiveFormsHelper.getFormErrors(this.modalForm).size) {
      this.showErrorsIfSubmitted();
      return;
    }

    this.processing = true;

    this._elevatedAccessService
      .elevateAccess(this.modalForm.get('password').value)
      .pipe(
        tap((elevated: boolean) => {
          if (!elevated) {
            throw new ForbiddenResponse();
          } else if (!!this.data.elevatedCallback) {
            this.data.elevatedCallback(this.secretCtrl.value);
            this.closeModal.emit();
          }
        }),
        catchError((response: HttpErrorResponse) => {
          if (response.status === 403) {
            this.modalForm.get('password').setErrors({ invalid: true });
          }
          this.showErrorsIfSubmitted();
          return throwError(() => response);
        }),
        finalize(() => {
          this.processing = false;
        }),
        take(1),
      )
      .subscribe();
  }

  showErrorsIfSubmitted(): void {
    if (!this.submitted) {
      return;
    }

    const errors = ReactiveFormsHelper.composeErrorMessages([
      { ctrl: this.secretCtrl, err: 'minlength', msg: ErrorStringsEnum.SECRET_TOO_SHORT },
      { ctrl: this.passCtrl, err: 'required', msg: ErrorStringsEnum.PASS_REQUIRED },
      { ctrl: this.passCtrl, err: 'invalid', msg: ErrorStringsEnum.PASS_INVALID },
    ]);

    this.feedbackMessages = errors.errorMessages;
  }

  private _applyConditionalValidators(): void {
    this.secretCtrl.clearValidators();
    if (this.secretCtrl.value && this.secretCtrl.value.length > 0) {
      this.secretCtrl.setValidators([Validators.minLength(12)]);
    }
    this.secretCtrl.updateValueAndValidity();
  }
}

/**
 * Error and validation messages
 */
enum ErrorStringsEnum {
  PASS_REQUIRED = 'Passord må angis',
  PASS_INVALID = 'Feil passord',
  SECRET_TOO_SHORT = 'Hemmelig nøkkel må være minst 12 tegn',
}
