import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-ff-dropdown',
  templateUrl: './ff-dropdown.component.html',
  styleUrls: ['./ff-dropdown.component.scss'],
})
export class FfDropdownComponent<T> implements OnInit, OnChanges {
  @Input()
  isDisabled? = false;

  @Input()
  isOpen = false;

  @Output()
  itemSelected: EventEmitter<T | null> = new EventEmitter();

  @Input()
  items: T[];

  @ViewChild('selectRef', { static: false })
  selectRef: ElementRef<HTMLElement>;

  @Input()
  selectPrefix?: string;

  @Input()
  selectText: string;

  @Input()
  selectedItem?: T;

  @Input()
  valuePropertyName?: string; // Not needed if array primitives

  currentItem: T;
  useOverlay = false;

  ngOnInit(): void {
    if (this.selectedItem) {
      if (typeof this.selectedItem === 'string') {
        this.items.forEach((item) => {
          if (item === this.selectedItem) {
            this.currentItem = item;
          }
        });
      } else {
        this.currentItem = this.selectedItem;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['selectedItem'] && !changes['selectedItem'].isFirstChange()) {
      this.currentItem = changes['selectedItem'].currentValue;
    }

    if (changes['items'] && !changes['items'].isFirstChange()) {
      this.items = changes['items'].currentValue;

      // Reset if no longer available
      if (this.currentItem && this.items.indexOf(this.currentItem) === -1) {
        this.currentItem = undefined;
      }
    }
  }

  close(): void {
    this.isOpen = false;
  }

  /**
   * Chooses the given (/selected) item
   */
  pickItem(item): void {
    this.useOverlay = true;

    if (this.currentItem === item) {
      this.close();
      return;
    }

    this.currentItem = item;
    this.close();
    // Moves focus to select, then blurs to hide :focus styling
    this.selectRef.nativeElement.focus();
    this.selectRef.nativeElement.blur();
    this.itemSelected.emit(this.currentItem);
  }

  /**
   * Returns the display text of given item
   * @see valuePropertyName
   */
  resolveText(item: any): string {
    if (typeof item === 'string') {
      return item;
    }

    const getText = (segments: Array<string>, value: object, index: number = 0): string => {
      value = value[segments[index]];

      const nextIndex = index + 1;

      if (segments[nextIndex]) {
        return getText(segments, value, nextIndex);
      }

      return value.toString();
    };

    return getText(this.valuePropertyName.split('.'), item);
  }

  /**
   * Toggles open state
   */
  toggleState(): void {
    this.useOverlay = true;

    if (this.isDisabled) {
      this.close();
      return;
    }

    this.isOpen = !this.isOpen;
  }
}
