import { ErrorResponse, ErrorResponseSchema } from '@core/entities/http-responses/error-response.entity';
import { raw } from 'serializr';

export class ClientErrorResponse extends ErrorResponse {}

export const ClientErrorResponseSchema = {
  factory: () => new ClientErrorResponse(),
  extends: ErrorResponseSchema,
  props: {
    error: raw(),
  },
};
