import { ValidationViolationResponseEntity } from '@core/entities/response/validation-violation-response.entity';
import { alias, list, object, primitive, serializable } from 'serializr';

export class PhoneValidationResponseEntity {
  @serializable(alias('region', primitive()))
  private _region?: string;

  @serializable(alias('status', primitive()))
  private _status: number;

  @serializable(alias('type', primitive()))
  private _type?: string;

  @serializable(alias('valid', primitive()))
  private _valid: boolean;

  @serializable(alias('violations', list(object(ValidationViolationResponseEntity))))
  private _violations: ValidationViolationResponseEntity[] = [];

  /**
   * @returns {string}
   */
  get region(): string {
    return this._region;
  }

  /**
   * @param {string} value
   */
  set region(value: string) {
    this._region = value;
  }

  /**
   * @returns {number}
   */
  get status(): number {
    return this._status;
  }

  /**
   * @param {number} value
   */
  set status(value: number) {
    this._status = value;
  }

  /**
   * @returns {string}
   */
  get type(): string {
    return this._type;
  }

  /**
   * @param {string} value
   */
  set type(value: string) {
    this._type = value;
  }

  /**
   * @returns {boolean}
   */
  get valid(): boolean {
    return this._valid;
  }

  /**
   * @param {boolean} value
   */
  set valid(value: boolean) {
    this._valid = value;
  }

  /**
   * @returns {ValidationViolationResponseEntity[]}
   */
  get violations(): ValidationViolationResponseEntity[] {
    return this._violations;
  }

  /**
   * @param {ValidationViolationResponseEntity[]} value
   */
  set violations(value: ValidationViolationResponseEntity[]) {
    this._violations = value;
  }
}
