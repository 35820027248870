import { alias, serializable } from 'serializr';

export class PostalCodeLookupResponse {
  @serializable(alias('postalArea'))
  private _postalArea: string;

  @serializable(alias('hasLookup'))
  private _hasLookup: boolean;

  @serializable(alias('valid'))
  private _valid: boolean;

  @serializable(alias('postalCode'))
  private _postalCode: string;

  private _postalCodeLookupId: string;

  public get postalArea(): string {
    return this._postalArea;
  }

  public get hasLookup(): boolean {
    return this._hasLookup;
  }

  public get valid(): boolean {
    return this._valid;
  }

  public set valid(value: boolean) {
    this._valid = value;
  }

  public get postalCode(): string {
    return this._postalCode;
  }

  public get postalCodeLookupId(): string {
    return this._postalCodeLookupId;
  }

  public set postalCodeLookupId(postalCodeLookupId: string) {
    this._postalCodeLookupId = postalCodeLookupId;
  }
}
