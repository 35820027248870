import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { RevokeUrlEntity } from '@core/entities/api/revoke-url.entity';
import { UserFeedbackEntity } from '@core/entities/user/user-feedback.entity';
import { UserFeedbackTypesEnum } from '@core/enums/user-feedback-types.enum';
import { ElementRefHelper } from '@core/helpers/element-ref.helper';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';
import { ApiService } from '@core/services/api/api.service';
import { ModalService } from '@core/services/modal/modal.service';
import { FfConfirmModalDataInterface } from '@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal-data.interface';
import { FfConfirmModalComponent } from '@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal.component';
import { Subject, throwError } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { deserialize } from 'serializr';

@Component({
  selector: 'app-revoke-urls',
  templateUrl: './revoke-urls.component.html',
  styleUrls: ['./revoke-urls.component.scss'],
})
export class RevokeUrlsComponent implements OnDestroy {
  @Output()
  deleteUrl: EventEmitter<RevokeUrlEntity> = new EventEmitter<RevokeUrlEntity>();

  @Output()
  saveUrl: EventEmitter<RevokeUrlEntity> = new EventEmitter<RevokeUrlEntity>();

  @Input()
  serviceId: string;

  @Input()
  urls: Array<RevokeUrlEntity> = [];

  feedbackMessages: Array<UserFeedbackEntity> = [];
  formHelper = ReactiveFormsHelper;
  processing: boolean = false;
  showAddForm: boolean;
  uriForm: UntypedFormGroup;

  private readonly _onDestroy$ = new Subject<void>();

  private _messagesRef: ElementRef;

  constructor(private _apiService: ApiService, private _fb: UntypedFormBuilder, private _modalService: ModalService) {}

  @ViewChild('feedbackMessagesRef', { read: ElementRef, static: false })
  set messagesRef(elRef: ElementRef) {
    this._messagesRef = elRef;
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  confirmRemoval(uri: RevokeUrlEntity) {
    const confirmContent: FfConfirmModalDataInterface = {
      component: FfConfirmModalComponent,
      title: 'Er du sikker?',
      body: 'Vil du faktisk slette ugyldiggjøringsadressen med URL «' + uri.url + '»?',
      confirmCallback: () => {
        this.delete(uri);
      },
    };

    this._modalService.openModal(confirmContent);
  }

  delete(revokeUrl: RevokeUrlEntity) {
    revokeUrl.processing = true;
    this._apiService
      .deleteRevokeUrl(revokeUrl.id)
      .pipe(
        tap((redir) => {
          revokeUrl.processing = false;
          this.deleteUrl.emit(redir);
        }),
        catchError((e: HttpErrorResponse) => {
          revokeUrl.processing = false;
          return throwError(() => e);
        }),
        takeUntil(this._onDestroy$),
      )
      .subscribe();
  }

  save() {
    if (this.uriForm.invalid) {
      return;
    }

    const uriObj = deserialize(RevokeUrlEntity, this.uriForm.value);
    this.processing = true;
    this._apiService
      .postRevokeUrl(this.serviceId, uriObj)
      .pipe(
        tap((uri) => {
          this.processing = false;
          this.saveUrl.emit(uri);
          this.showAddForm = false;
          this.uriForm.reset();
        }),
        catchError((e: HttpErrorResponse) => {
          this.processing = false;
          this._processErrorResponse(e);
          return throwError(() => e);
        }),
        takeUntil(this._onDestroy$),
      )
      .subscribe();
  }

  toggleAddForm() {
    this.uriForm = this._buildUrlGroup(new RevokeUrlEntity());
    this.processing = false;
    this.showAddForm = !this.showAddForm;
  }

  private _buildUrlGroup(revokeUrl: RevokeUrlEntity): UntypedFormGroup {
    return this._fb.group({
      id: [!revokeUrl ? null : revokeUrl.id],
      url: !revokeUrl ? [null] : [revokeUrl.url, [Validators.required]],
      processing: [false],
    });
  }

  /**
   * Converts API error response to translated user friendly error messages
   */
  private _processErrorResponse(e: HttpErrorResponse) {
    this.feedbackMessages = [];
    if (e.error.violations) {
      for (const v of e.error.violations) {
        // Show to user if there is an actual translation string for that key
        if (RevokeUrlsErrorEnum['API_' + v.errorName.toUpperCase()]) {
          this.feedbackMessages.push(
            new UserFeedbackEntity(
              RevokeUrlsErrorEnum['API_' + v.errorName.toUpperCase()],
              UserFeedbackTypesEnum.ERROR,
            ),
          );
        }
      }
    }

    // If no custom messages matched, add generic
    if (this.feedbackMessages.length < 1) {
      this.feedbackMessages.push(new UserFeedbackEntity(RevokeUrlsErrorEnum.API_GENERIC, UserFeedbackTypesEnum.ERROR));
    }

    if (this.feedbackMessages.length > 0) {
      ElementRefHelper.scrollTo(this._messagesRef);
    }
  }
}

enum RevokeUrlsErrorEnum {
  API_GENERIC = 'Noe gikk galt',
  API_INVALID_URL_ERROR = 'Url er ugyldig. Må starte med «https://»',
  API_IS_BLANK_ERROR = 'Url er påkrevd',
}
