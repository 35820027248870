import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objToKeys',
})
export class ObjToKeysPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return value;
    }

    return Object.keys(value);
  }
}
