import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Email } from '@core/entities/user/email.entity';
import { User } from '@core/entities/user/user.entity';
import { EmailService } from '@core/services/user/email.service';
import { EmailValidator } from '@shared/validators/email.validator';
import { Observable, of, throwError } from 'rxjs';
import { catchError, concatMap, filter, finalize, map, tap } from 'rxjs/operators';
import { deserialize } from 'serializr';
import { EmailForm } from '../../types/emails-form.type';

@Component({
  selector: 'app-email-form',
  templateUrl: './email-form.component.html',
  styleUrls: ['./email-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailFormComponent implements AfterViewInit, OnInit {
  @Output()
  changed = new EventEmitter<void>();

  @Output()
  removed = new EventEmitter<number>();

  @Input()
  formGroupCtrl: FormGroup<EmailForm>;

  @Input()
  index: number;

  @ViewChild('inputRef', { static: false })
  inputRef: ElementRef<HTMLInputElement>;

  @Input()
  isAdminContext = false;

  @Input()
  isOptional = false;

  @Input()
  user: User;

  emailCtrl: EmailForm['emailAddress'];
  focusCtrl: EmailForm['autofocus'];
  uuidCtrl: EmailForm['uuid'];
  processing = false;
  showErrors = false;

  constructor(private _emailService: EmailService, private readonly _cdr: ChangeDetectorRef) {}

  get isExisting(): boolean {
    return !!this.formGroupCtrl.value.uuid;
  }

  /**
   * Focus the input after clicking add new
   */
  ngAfterViewInit(): void {
    if (this.focusCtrl.value && !this.uuidCtrl.value) {
      this.inputRef.nativeElement.focus();
    }
  }

  ngOnInit() {
    this.emailCtrl = this.formGroupCtrl.controls.emailAddress;
    this.focusCtrl = this.formGroupCtrl.controls.autofocus;
    this.uuidCtrl = this.formGroupCtrl.controls.uuid;
  }

  createEmail(index: number) {
    this.processing = true;

    this.validate()
      .pipe(
        tap((valid: boolean) => {
          this.processing = valid;
        }),
        filter((valid: boolean) => valid),
        concatMap(() => {
          this.emailCtrl.setErrors(null);

          return this._emailService.postEmail(this.user.uuid, deserialize(Email, this.formGroupCtrl.value)).pipe(
            tap((email: Email) => {
              if (this.isAdminContext) {
                this.user.emails.push(email);
              }
              this.removed.emit(index);
              // Verifications are not sent in admin context
              email.verificationSent = !this.isAdminContext;
            }),
            catchError((e) => {
              this.emailCtrl.setErrors({
                [EmailValidator.ERROR_UNSAVED_CHANGES]: true,
                create_failed: true,
              });
              this.emailCtrl.updateValueAndValidity();
              return throwError(() => e);
            }),
          );
        }),
        finalize(() => {
          this.processing = false;
          this._cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  /**
   * Emit to parent that an input field was changed
   */
  fieldChanged() {
    this.changed.emit();
  }

  removeEmail() {
    if (this.uuidCtrl.value) {
      this._emailService
        .deleteEmail(this.user.uuid, this.uuidCtrl.value)
        .pipe(
          tap(() => {
            this.emailCtrl.setErrors(null);
            this.emailCtrl.reset();
            this.removed.emit(this.index);
          }),
          catchError((e) => {
            this.emailCtrl.setErrors({
              [EmailValidator.ERROR_UNSAVED_CHANGES]: true,
              update_failed: true,
            });
            this.emailCtrl.updateValueAndValidity();
            return throwError(() => e);
          }),
          finalize(() => {
            this._cdr.detectChanges();
          }),
        )
        .subscribe();
    } else {
      this.emailCtrl.setErrors(null);
      this.emailCtrl.reset();
      this.removed.emit(this.index);
    }
  }

  updateInvalidEmail(index: number) {
    this.processing = true;

    this.validate()
      .pipe(
        tap((valid: boolean) => {
          this.processing = valid;
        }),
        filter((valid: boolean) => valid),
        concatMap(() => {
          this.emailCtrl.setErrors(null);

          return this._emailService.deleteEmail(this.user.uuid, this.uuidCtrl.value).pipe(
            tap(() => {
              this.removed.emit(index);
            }),
            concatMap(() => {
              return this._emailService.postEmail(this.user.uuid, deserialize(Email, this.formGroupCtrl.value));
            }),
            tap((createdEmail: Email) => {
              createdEmail.verificationSent = !this.isAdminContext;
            }),
            catchError((e) => {
              this.emailCtrl.setErrors({
                [EmailValidator.ERROR_UNSAVED_CHANGES]: true,
                update_failed: true,
              });
              this.emailCtrl.updateValueAndValidity();
              return throwError(() => e);
            }),
            finalize(() => {
              this.processing = false;
              this._cdr.detectChanges();
            }),
          );
        }),
      )
      .subscribe();
  }

  /**
   * Pass validation if no errors, or if only error is unsaved_changes
   */
  validate(): Observable<boolean> {
    this.showErrors = true;

    if (!this.emailCtrl.value || !this.emailCtrl.value.length) {
      this.emailCtrl.setErrors({ [EmailValidator.ERROR_REQUIRED]: true });
      return of(false);
    }

    return this._emailService.checkValidEmail(this.emailCtrl.value).pipe(
      map((valid: boolean) => {
        if (!valid) {
          this.emailCtrl.setErrors({ [EmailValidator.ERROR_INVALID_EMAIL]: true });
        }

        // Has errors besides "unsaved"?
        return (
          this.emailCtrl.errors === null ||
          (Object.keys(this.emailCtrl.errors).length === 1 &&
            this.emailCtrl.hasError(EmailValidator.ERROR_UNSAVED_CHANGES))
        );
      }),
    );
  }
}
