import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { validateAccountNumber } from '@halloverden/number-utilities-ts';

export const bankAccountValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  return validateAccountNumber(control.value) === false ? { invalidBankAccount: true } : null;
};

function isEmptyInputValue(value: any): boolean {
  return value === null || value.length === 0;
}

export class BankAccountNumberValidators {
  static required: ValidatorFn = ({ value }) => {
    return isEmptyInputValue(value)
      ? ({ bankAccountNumberRequired: true })
      : null;
  };

  static valid: ValidatorFn = (control) => {
    return bankAccountValidator(control);
  };
}
