import { Address } from '@core/entities/user/address.entity';

/**
 *
 */
export class AddressHelper {
  /**
   *
   * @param addresses
   * @param newAddress
   */
  static replaceAddress(addresses: Address[], newAddress: Address): Array<Address> {
    addresses.forEach((address: Address, index) => {
      if (address.uuid === newAddress.uuid) {
        addresses[index] = newAddress;
      }
    });

    return addresses;
  }
}
