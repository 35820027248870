<div
  [class.disabled]="formCtrl.disabled"
  [class.item-selected]="!!selectedItem"
  [class.panel-open]="!!panelVisible"
  aria-haspopup="listbox"
  aria-owns="items-result"
  class="combobox"
  role="combobox"
  title="{{ selectedItem ? formatDisplay(selectedItem) : '' }}"
  >
  <div [hidden]="!panelVisible" (click)="closePanel(false)" class="backdrop"></div>

  <app-ff-icon-input
    (clicked)="openPanel()"
    [disabled]="formCtrl.disabled"
    [tabIndex]="-1"
    iconClassList="s20"
    iconPath="assets/icons/{{ selectedItem && !panelVisible ? 'caret_down' : 'spyglass' }}.svg"
    [iconPosition]="iconPosition"
    role="graphics-symbol"
    >
    <input
      #fieldRef
      (click)="handleInputClicked()"
      (focus)="focusInput()"
      (keydown)="keyDown($event)"
      (touchend)="focusInput()"
      (wheel)="handleScroll($event)"
      [class.ng-invalid]="formCtrl.invalid"
      [disabled]="formCtrl.disabled"
      [id]="inputHtmlId"
      aria-autocomplete="list"
      aria-controls="items-result"
      autocomplete="something-not-recognized-by-the-browser"
      class="search"
      inputmode="search"
      placeholder="{{ textPlaceholder }}"
      role="searchbox"
      type="text"
      />
  </app-ff-icon-input>

  <ng-template #defaultTemplate let-item="item">
    {{ formatDisplay(item) }}
  </ng-template>

  <div [attr.aria-expanded]="!!panelVisible" [hidden]="!panelVisible" class="result">
    <div #panelRef [class.hidden]="processing" class="result__panel" role="listbox">
      @for (item of itemsFiltered; track item; let idx = $index) {
        <app-ff-list-item
          #listItemRef
          (click)="selectItem(item)"
          [class.result__item--default]="!resultTemplate"
          [item]="item"
          [label]="formatDisplay(item)"
          class="result__item"
          role="listitem"
          title="{{ formatDisplay(item) }}"
          >
          <ng-container
          *ngTemplateOutlet="
            resultTemplate ? resultTemplate : defaultTemplate;
            context: {
              active: listItemRef.isActive,
              index: idx,
              item: item
            }
          "
        ></ng-container>
      </app-ff-list-item>
    }
  </div>

  @if (processing) {
    <app-ff-loading-indicator
      class="result__item result__item--default stroke--mine-shaft"
      role="spinbutton"
      size="s24"
    ></app-ff-loading-indicator>
  }

  @if (formCtrl.touched && formCtrl.value.length > 0 && !processing && items.length < 1) {
    <div
      class="result__item result__item--default"
      role="listitem"
      >
      {{ textEmptyResults }}
    </div>
  }
</div>
</div>
