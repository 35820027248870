import { HttpParameterCodec } from '@angular/common/http';

/**
 * Needed for URIs with until encoding issues are solved
 * https://github.com/angular/angular/issues/18261
 */
export class CustomHttpParameterCodec implements HttpParameterCodec {
  /**
   *
   * @param key
   */
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  /**
   *
   * @param value
   */
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  /**
   *
   * @param key
   */
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  /**
   *
   * @param value
   */
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
