<label
  [attr.for]="inputId"
  [class.disabled]="isDisabled"
  [class.focused]="focused"
  [class.has-help]="helpText?.length > 0"
  [class.has-label]="labelText?.length > 0"
  [id]="labelId"
  class="custom checkbox"
  tabindex="-1"
  >
  <input
    (click)="toggle($event)"
    (focusin)="focused = true"
    (focusout)="focused = false"
    (keydown.enter)="toggle($event)"
    (keydown.space)="toggle($event)"
    [formControl]="checkCtrl"
    [id]="inputId"
    [title]="inputTitle || ''"
    type="checkbox"
    tabindex="0"
    />

  @if (!processing) {
    <svg-icon
      [applyClass]="true"
      [class.active]="isChecked"
      [class.disabled]="isDisabled"
      [title]="inputTitle || ''"
      class="icon checkbox"
      src="assets/icons/form_checkbox.svg"
      role="presentation"
    ></svg-icon>
  }

  @if (processing) {
    <app-ff-loading-indicator
      size="s16"
      class="icon spinner stroke--mine-shaft"
    ></app-ff-loading-indicator>
  }

  <ng-template #defaultTemplate>
    @if (labelText) {
      <div class="label-txt">
        {{ labelText }}
        @if (descriptionText) {
          <div class="description">{{ descriptionText }}</div>
        }
      </div>
    }
  </ng-template>

  <ng-container *ngTemplateOutlet="customTemplate ?? defaultTemplate; context: { labelText, descriptionText }">
  </ng-container>

  @if (helpText) {
    <app-ff-tooltip [text]="helpText" class="help">
      <svg-icon
        [applyClass]="true"
        class="icon"
        role="presentation"
        src="assets/icons/circled_question_mark_inverted_24.svg"
      ></svg-icon>
    </app-ff-tooltip>
  }
</label>
