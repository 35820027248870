import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { WindowRefHelper } from "@core/helpers/window-ref.helper";

let checkboxIndex: number = 0;

@Component({
  selector: "app-ff-checkbox",
  templateUrl: "./ff-checkbox.component.html",
  styleUrls: ["./ff-checkbox.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FfCheckboxComponent implements OnInit, OnChanges {
  private readonly HTML_ID_PREFIX: string = "ff_checkbox_";
  private readonly HTML_ID_LABEL_POSTFIX: string = "_label";

  @Output()
  changed: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input({ required: false })
  customTemplate?: TemplateRef<any>;

  @Input({ required: false })
  checked?: boolean = false;

  // Displays as text under label
  @Input()
  descriptionText?: string;

  @HostBinding()
  @Input({ required: false })
  disabled = null; // disabled=false still disables, while null omits attrib

  // Optional. If specified, the [checked] input is not needed.
  @Input({ required: false })
  formCtrl?: UntypedFormControl;

  // Displays as tooltip from ?-icon
  @Input({ required: false })
  helpText?: string;

  @Input({ required: false })
  inputId?: string = this.HTML_ID_PREFIX + checkboxIndex++;

  @Input({ required: false })
  inputTitle?: string;

  @Input({ required: false })
  labelText?: string;

  @HostBinding()
  @Input({ required: false })
  processing = false;

  @HostBinding()
  tabindex = "-1";

  focused: boolean = false;
  form: UntypedFormGroup;

  constructor(
    private readonly _cdr: ChangeDetectorRef,
    private readonly _fb: UntypedFormBuilder,
    @Inject(WindowRefHelper.WINDOW)
    private readonly _window: Window
  ) {
    if (checkboxIndex === 0) {
      checkboxIndex =
        this._window.document.querySelectorAll(
          '[id^="' + this.HTML_ID_PREFIX + '"]'
        ).length + 1;
    } else {
      checkboxIndex++;
    }
  }

  get checkCtrl(): UntypedFormControl {
    return this.formCtrl
      ? this.formCtrl
      : (this.form.get("checked") as UntypedFormControl);
  }

  @HostBinding("attr.aria-checked")
  get isChecked(): boolean {
    return !!this.checkCtrl.value;
  }

  get isDisabled(): boolean {
    return this.disabled || (this.formCtrl && this.formCtrl.disabled);
  }

  @HostBinding("attr.aria-labeledby")
  get labelId(): string {
    return this.inputId + this.HTML_ID_LABEL_POSTFIX;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["checked"] && !changes["checked"].isFirstChange()) {
      this.checkCtrl.setValue(!!changes["checked"].currentValue);
      this._cdr.detectChanges();
    }

    if (changes["processing"] && !changes["processing"].isFirstChange()) {
      this._cdr.detectChanges();
    }
  }

  ngOnInit(): void {
    if (!this.formCtrl) {
      this.form = this._fb.group({
        checked: [!!this.checked],
      });
    }
  }

  toggle(event: Event): void {
    if (!this.disabled) {
      this.checkCtrl.setValue(!this.isChecked);
      this.changed.emit(this.isChecked);
      this._cdr.detectChanges();
    }

    event.preventDefault();
  }
}
