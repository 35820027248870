import { Component, Input } from '@angular/core';
import { UserFormBuilder } from '../user-form.factory';

@Component({
  selector: 'app-user-name-form',
  templateUrl: './user-name-form.component.html',
  styleUrls: ['./user-name-form.component.scss'],
})
export class UserNameFormComponent {
  @Input()
  nameForm = this._ufb.UserNames({});

  @Input()
  readonly = false;

  constructor(private _ufb: UserFormBuilder) {}

  submitted = false;
}
