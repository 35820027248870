import { PhonePropertyUpdate } from '@shared/components/user-form/phones/phones.component';
import { BehaviorSubject, noop } from 'rxjs';
import { alias, custom, date, identifier, serializable } from 'serializr';

export class Phone {
  @serializable(alias('createdAt', date()))
  private readonly _createdAt: Date;

  @serializable(alias('phoneNumber'))
  private _phoneNumber: string;

  @serializable(alias('primary'))
  private _primary: boolean = false;

  @serializable(alias('uuid', identifier()))
  private readonly _uuid: string;

  @serializable(
    alias(
      'verifiedAt',
      custom(noop, (value, context) => {
        context.target.verified$.next(!!value);
        return value;
      }),
    ),
  )
  private readonly _verifiedAt: Date | null;

  updatedProperty: PhonePropertyUpdate;
  verified$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  verificationSent: boolean = false;

  /**
   * @param {string} uuid
   */
  constructor(uuid?: string) {
    if (uuid) {
      this._uuid = uuid;
    }
  }

  /**
   * @returns {Date}
   */
  get createdAt(): Date {
    return this._createdAt;
  }

  /**
   * @returns {string}
   */
  get phoneNumber(): string {
    return this._phoneNumber;
  }

  /**
   * @param {string} value
   */
  set phoneNumber(value: string) {
    this._phoneNumber = value;
  }

  /**
   * @returns {boolean}
   */
  get primary(): boolean {
    return this._primary;
  }

  /**
   * @param {boolean} value
   */
  set primary(value: boolean) {
    this._primary = value;
  }

  /**
   * @returns {string}
   */
  get uuid(): string {
    return this._uuid;
  }

  /**
   * @returns {boolean}
   */
  get verified(): boolean {
    return !!this._verifiedAt;
  }

  /**
   * @returns {string}
   */
  get numberWithCountryPrefix(): string {
    return this.phoneNumber;
  }

  public get verifiedAt(): Date {
    return this._verifiedAt;
  }
}
