import { MainMenuItem } from '@core/entities/site/main-menu.entity';

export abstract class MainMenuCommonContext {
  mainMenuItems: MainMenuItem[] = [];

  /**
   * @private
   */
  public setActive(url: string): void {
    const rootNode =
      this.mainMenuItems.filter((i) => i.children.filter((c) => c.route === url)[0])[0] ||
      this.mainMenuItems.filter((c) => c.route === url)[0];
    const node = rootNode ? rootNode.children.filter((c) => c.route === url)[0] : null;

    this.changeActiveTo(node ? node : rootNode);
  }

  /**
   * @param {MainMenuItem} item
   */
  changeActiveTo(item: MainMenuItem): void {
    if (item) {
      this.mainMenuItems.forEach((i) => {
        i.active = false;
        i.children.forEach((c) => (c.active = false));
      });

      item.active = true;
      if (item.parent) {
        item.parent.active = true;
      }
    }
  }
}
