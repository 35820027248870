import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-member-id-form',
  templateUrl: './user-member-id-form.component.html',
  styleUrls: ['./user-member-id-form.component.scss'],
})
export class UserMemberIdFormComponent {
  @Input()
  memberId: string;
}
