<ul class="apis">
  @if (!apis || apis.length < 1) {
    <li class="client well">
      <span>Ingen API-er</span>
    </li>
  }

  @for (api of apis; track api) {
    <li class="api well">
      <div class="api__info">
        <div class="api__name">
          <a [routerLink]="isAppAdmin ? '/apis/' + api.uuid : null">{{ api.name }}</a>
        </div>
        <div class="api__details">
          <div class="detail detail--description">
            <svg-icon [applyClass]="true" src="assets/icons/document_a_24.svg" role="presentation"></svg-icon>
            <span>{{ api.description }}</span>
          </div>
        </div>
      </div>
      <app-ff-delete-button
        (clicked)="confirmRemoval(api)"
        [disabled]="!isAppAdmin"
        [processing]="api.processing"
        class="api__remove"
        titleText="Fjern"
      ></app-ff-delete-button>
    </li>
  }
</ul>

@if (processing) {
  <div class="processing">
    <app-ff-loading-indicator class="stroke--mine-shaft" size="s20"></app-ff-loading-indicator>
  </div>
}
