import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { ScopeService } from '@core/services/scope/scope.service';
import { of } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap } from 'rxjs/operators';

function isEmptyInputValue(value: any): boolean {
  return value === null || value.length === 0;
}

export class ScopeValidator {
  static readonly ERROR_INVALID = 'scope_invalid';
  static readonly ERROR_TAKEN = 'scope_taken';
  static readonly SCOPE_REGEX = /^[\x21\x23-\x5B\x5D-\x7E]*$/;

  /**
   * @param {AbstractControl} control
   * @returns {ValidationErrors | null}
   */
  static syntax(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null;
    }

    return !ScopeValidator.isValidScopeSyntax(control.value) ? { [ScopeValidator.ERROR_INVALID]: true } : null;
  }

  /**
   * @link https://stackoverflow.com/a/57009006/504075
   * @param scopeService
   * @returns {AsyncValidatorFn}
   */
  static unique(scopeService: ScopeService): AsyncValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value || isEmptyInputValue(control.value) || !control.valueChanges) {
        return of(null);
      }

      return control.valueChanges.pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((value) => scopeService.existsByName(value)),
        map((exists: boolean) => (exists ? { [ScopeValidator.ERROR_TAKEN]: true } : null)),
        first(), // to make observable finite, important
      );
    };
  }

  /**
   * Regular method that returns whether given string is valid scope.
   * Separate method so it can be called directly and not only as validator
   *
   * @returns {boolean}
   * @param scope
   */
  static isValidScopeSyntax(scope: string): boolean {
    const regex = new RegExp(ScopeValidator.SCOPE_REGEX);
    return regex.test(scope);
  }
}
