<form [class.submitted]="submitted" [formGroup]="scopeForm" class="form scope-form">
  <div class="form-group">
    <div class="form-control">
      <label for="name">
        Navn
        @if (api) {
          <span class="tip"> (anbefalt prefix: {{ api.namespace.toLowerCase().trim() + '.' }}) </span>
        }
      </label>
      <app-ff-icon-input
        tooltipText="{{
          scope.global
            ? 'Navn kan ikke endres for globale scopes'
            : 'Alfanumerisk, bruk . og _ som skilletegn. Bør prefixes med namespace fra APIet, f.eks. mynamespace.my_app.some_scope'
        }}"
        >
        <input
          (input)="showErrorsIfSubmitted()"
          [formControl]="formHelper.getCtrl(scopeForm, 'name')"
          id="name"
          inputmode="text"
          placeholder="Navn"
          type="text"
          />
      </app-ff-icon-input>
    </div>
  </div>

  <div class="form-group">
    <div class="form-control">
      <label for="description">
        Beskrivelse
        <span>- Forklaring for sluttbruker</span>
      </label>
      <textarea
        (input)="updateExampleScope()"
        [formControl]="formHelper.getCtrl(scopeForm, 'description')"
        autocomplete="on"
        class="description"
        id="description"
        placeholder="Beskrivelse"
        type="text"
      ></textarea>
    </div>
  </div>

  @if (scope?.uuid) {
    <div class="form-group">
      <div class="form-control">
        <app-ff-checkbox
          [formCtrl]="formHelper.getCtrl(scopeForm, 'deprecated')"
          [descriptionText]="'Om scopet skal fases ut. Scopet ' + (scope.inUse ? 'er' : 'er ikke') + ' i bruk nå.'"
          labelText="Utfases («Deprecated»)"
          inputId="deprecated"
        ></app-ff-checkbox>
      </div>
    </div>
  }

  @if (formHelper.getCtrl(scopeForm, 'deprecated').value) {
    <div class="well well--padded">
      <div class="form-group">
        <div class="form-control">
          <label for="sunset">
            Utfases fra dato
            <span>- Scopet markeres for utfasing fra denne datoen</span>
          </label>
          <app-ff-icon-input
            [tooltipText]="scope.scopeExpirationDate ? 'Kan ikke endres, start ev. ny utfasing.' : 'Format: DD.MM.ÅÅÅÅ'"
            >
            <input
              (input)="showErrorsIfSubmitted()"
              [formControl]="formHelper.getCtrl(scopeForm, 'scopeExpirationDate.deprecatedSince')"
              id="deprecatedSince"
              inputmode="text"
              placeholder="DD.MM.ÅÅÅÅ"
              type="text"
              />
          </app-ff-icon-input>
        </div>
      </div>
      <div class="form-group">
        <div class="form-control">
          <label for="sunset">
            Siste bruksdag («Sunset»)
            <span>- Scopet er ubrukelig etter denne datoen</span>
          </label>
          <app-ff-icon-input
          [tooltipText]="
            scope.scopeExpirationDate ? 'Kan ikke endres, start ev. ny utfasing.' : 'Valgfritt felt. Format: DD.MM.ÅÅÅÅ'
          "
            >
            <input
              (input)="showErrorsIfSubmitted()"
              [formControl]="formHelper.getCtrl(scopeForm, 'scopeExpirationDate.sunset')"
              id="sunset"
              inputmode="text"
              placeholder="DD.MM.ÅÅÅÅ"
              type="text"
              />
          </app-ff-icon-input>
        </div>
      </div>
      <div class="form-group">
        <div class="form-control">
          <label for="notes">
            Forklaring
            <span>- Hvorfor utfases scopet, finnes et alternativ?</span>
          </label>
          <textarea
            (input)="showErrorsIfSubmitted()"
            [formControl]="formHelper.getCtrl(scopeForm, 'scopeExpirationDate.notes')"
            autocomplete="on"
            class="description"
            id="notes"
            placeholder="Forklaring"
            type="text"
          ></textarea>
        </div>
      </div>
    </div>
  }

  <div class="buttons">
    <app-ff-button (clicked)="submitForm()" [processing]="processing" color="red" label="Lagre"></app-ff-button>

    @if (canBeDeleted()) {
      <app-ff-button
        (clicked)="confirmDelete()"
        [processing]="processing"
        color="beige"
        label="Slett"
      ></app-ff-button>
    }
  </div>
</form>

<app-feedback-messages #feedbackMessagesRef [messages]="feedbackMessages"></app-feedback-messages>

<div class="example">
  <h2>Eksempel som viser hva sluttbruker vil se:</h2>
  <app-oauth-consent
    [clientInfo]="exampleClient"
    [consentStatus]="exampleConsentStatus"
    [example]="true"
    class="well"
  ></app-oauth-consent>
</div>
