import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';

@Component({
  selector: 'app-reactive-checkbox-tree-view',
  templateUrl: './reactive-checkbox-tree-view.component.html',
  styleUrls: ['./reactive-checkbox-tree-view.component.scss'],
})
export class ReactiveCheckboxTreeViewComponent {
  @Output()
  childChecked = new EventEmitter();

  @Input()
  controlName: string;

  @Input()
  formGroupCtrl?: UntypedFormGroup;

  @Input()
  isFirst: boolean;

  @Input()
  levelsDeep = 0;

  formHelper = ReactiveFormsHelper;
  levels: number[];

  getCheckedCtrl(index: number): UntypedFormControl {
    return ReactiveFormsHelper.getCtrl(this.formGroupCtrl, this.controlName + '.' + index + '.isChecked');
  }

  check(item: AbstractControl): void {
    const newState = item.get('isChecked').value;
    this.checkChildren(item, newState);
    this.childChecked.emit(newState);
  }

  checkChildren(item: AbstractControl, state: boolean): void {
    const children = item.get('children') as UntypedFormArray;

    children.controls.forEach((itemChild: AbstractControl) => {
      itemChild.patchValue({ isChecked: state });
      this.checkChildren(itemChild, state);
    });
  }

  checkParent(item: AbstractControl): void {
    let newState = true;
    const children = item.get('children') as UntypedFormArray;

    children.controls.forEach((itemChild: AbstractControl) => {
      if (!itemChild.get('isChecked').value) {
        newState = false;
      }
    });

    item.patchValue({ isChecked: newState });
  }

  hasChildren(ctrl: AbstractControl): boolean {
    return !!(ctrl.get('children') as UntypedFormArray).length;
  }

  isChecked(ctrl: AbstractControl): boolean {
    if (ctrl instanceof UntypedFormGroup) {
      return ctrl.get('isChecked').value;
    }

    return false;
  }

  toggle(ctrl: AbstractControl): void {
    let newState: boolean;

    if (ctrl instanceof UntypedFormGroup) {
      newState = !ctrl.get('isChecked').value;
    }

    ctrl.get('isChecked').setValue(newState);
    this.checkChildren(ctrl, newState);
    this.childChecked.emit(newState);
  }
}
