<!-- existing / none -->
<ul class="existing">
  @if (uris.length < 1) {
    <li class="cors well">Ingen adresser lagt til</li>
  }

  @for (uri of uris; track uri; let idx = $index) {
    <li class="cors well">
      <app-ff-delete-button
        (clicked)="confirmRemoval(uri)"
        [processing]="uri.processing"
        class="delete"
        titleText="Slett denne CORS-adressen"
      ></app-ff-delete-button>
      <div class="uri">{{ uri.url }}</div>
    </li>
  }
</ul>

<!-- Add new -->
@if (showAddForm) {
  <form [formGroup]="uriForm" class="form well new-cors" [class.submitted]="submitted">
    <h3>Ny CORS-adresse</h3>
    <div class="subtitle">Oppgi adresse (URL) som tillates for CORS</div>
    <div class="form-group">
      <div class="form-control cors-uri">
        <input
          (input)="showErrorsIfSubmitted()"
          [formControl]="formHelper.getCtrl(uriForm, 'url')"
          class="uri"
          id="new_cors_uri"
          placeholder="URL"
          type="text"
          />
      </div>
    </div>
    <div class="buttons">
      @if (showAddForm) {
        <app-ff-button
          (clicked)="save()"
          [processing]="processing"
          class="inline padded"
          label="Legg til CORS-adresse"
        ></app-ff-button>
      }
      @if (showAddForm) {
        <app-ff-button
          (clicked)="toggleAddForm()"
          class="inline padded"
          color="gray"
          label="Avbryt"
        ></app-ff-button>
      }
    </div>
    <app-feedback-messages #feedbackMessagesRef [messages]="feedbackMessages"></app-feedback-messages>
  </form>
}

<!-- New trigger toggle -->
<div class="buttons">
  @if (!showAddForm) {
    <app-ff-button
      (clicked)="toggleAddForm()"
      class="inline padded"
      label="Legg til CORS-adresse"
    ></app-ff-button>
  }
</div>
