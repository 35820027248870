import { User } from '@core/entities/user/user.entity';

/**
 * The AbstractControls that can be expected to exist in a UserForm
 *
 * @see UserFormDisabledSectionsInterface
 * @see UserFormOptionalSectionsInterface
 */
export enum UserFormControlsEnum {
  accountStatus = 'accountStatus',
  postalAddresses = 'postalAddresses',
  emailAddresses = 'emailAddresses',
  employment = 'employment',
  names = 'names',
  roles = 'roles',
  phoneNumbers = 'phoneNumbers',
  nin = 'nin',
  testUser = 'testUser',
}

export type UserFormControlsKeys = keyof User;
