import { DOCUMENT } from '@angular/common';
import {
  ApplicationRef,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Inject,
  Injectable,
  Injector,
  Renderer2,
  RendererFactory2,
  Type,
  ViewContainerRef,
} from '@angular/core';
import { FfModalDataInterface } from '@shared/components/ff-modals/ff-modal-data.interface';
import { FfModalHostComponent } from '@shared/components/ff-modals/ff-modal-host/ff-modal-host.component';

@Injectable({ providedIn: 'root' })
export class ModalService {
  readonly MODAL_HOST_ID = 'modal-host';

  private readonly _body: HTMLElement;
  private readonly _document: Document;
  private readonly _renderer: Renderer2;

  /**
   * @param {ApplicationRef} _appRef
   * @param {Document} _doc
   * @param {ComponentFactoryResolver} _factoryResolver
   * @param {Injector} _injector
   * @param {RendererFactory2} rendererFactory
   */
  constructor(
    private _appRef: ApplicationRef,
    @Inject(DOCUMENT)
    private _doc: Document,
    private _factoryResolver: ComponentFactoryResolver,
    private _injector: Injector,
    rendererFactory: RendererFactory2,
  ) {
    this._document = _doc;
    this._body = this._document.body;
    this._renderer = rendererFactory.createRenderer(null, null);
  }

  /**
   * @param data
   */
  openModal(data: FfModalDataInterface) {
    const host = this._loadComponent(FfModalHostComponent);
    host.instance.data = data;

    host.instance.closeModal.subscribe(() => {
      this.closeModal();
    });
  }

  closeModal() {
    const existing: HTMLElement = this._document.getElementById(this.MODAL_HOST_ID);
    if (existing) {
      this._renderer.removeChild(this._body, existing);
    }
  }

  /**
   * @returns {HTMLElement}
   * @private
   */
  private _getHostAnchor(): HTMLElement {
    const existing: HTMLElement = this._document.getElementById(this.MODAL_HOST_ID);
    if (existing) {
      return existing;
    }

    const hostElement: HTMLElement = this._renderer.createElement('div');
    hostElement.id = this.MODAL_HOST_ID;
    this._renderer.insertBefore(this._body, hostElement, this._body.firstElementChild);

    return this._document.getElementById(this.MODAL_HOST_ID);
  }

  /**
   * @param comp
   * @param parentNode
   * @private
   */
  private _loadComponent<T>(
    comp: Type<T>,
    parentNode: HTMLElement | ViewContainerRef = this._getHostAnchor(),
  ): ComponentRef<T> {
    const factory: ComponentFactory<T> = this._factoryResolver.resolveComponentFactory(comp);
    const ref = factory.create(this._injector, [], parentNode);
    this._appRef.attachView(ref.hostView);
    return ref;
  }
}
