import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from "@angular/forms";
import { ProcessableInterface } from "@core/interfaces/processable.interface";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";

@Component({
  selector: "app-ff-assign-collection-item",
  templateUrl: "./ff-assign-collection-item.component.html",
  styleUrls: ["./ff-assign-collection-item.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FfAssignCollectionItemComponent
  implements OnInit, ProcessableInterface
{
  readonly #destroyRef = inject(DestroyRef);

  @HostBinding("class.expanded")
  private _showForm: boolean = false;

  @Output()
  chosen: EventEmitter<object> = new EventEmitter<object>();

  @Input({ required: true })
  items$!: Observable<object[]>;

  @Output()
  queried: EventEmitter<string> = new EventEmitter<string>();

  @Input({ required: false })
  resultTemplate?: TemplateRef<any>;

  @Input({ required: false })
  textAssign: string = "Legg til";

  @Input({ required: false })
  textCancel: string = "Avbryt";

  @Input({ required: false })
  textPlaceholder: string = "Søk..";

  @Input({ required: false })
  textTitle: string = "Legg til";

  @Input({ required: false })
  textSubtitle: string = "Søk opp og legg til i lista";

  ctrl: UntypedFormControl;
  processing = false;
  reactiveForm: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder) {}

  get showForm(): boolean {
    return this._showForm;
  }

  set showForm(show: boolean) {
    // Clear old search value when hiding form
    if (!show) {
      this.ctrl.setValue(undefined);
    }

    this._showForm = !!show;
  }

  ngOnInit(): void {
    this.reactiveForm = this._fb.group({
      item: this._fb.control(null),
    });

    this.ctrl = this.reactiveForm.get("item") as UntypedFormControl;

    this.ctrl.valueChanges
      .pipe(
        tap((val?) => {
          if (val) {
            this.chosen.emit(val);
            this.showForm = false;
          }
        }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();

    this.items$
      .pipe(
        tap(() => {
          this.processing = false;
        }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();
  }

  filterUpdate(str: string): void {
    this.processing = true;
    this.queried.emit(str);
  }
}
