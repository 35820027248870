import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormGroup, UntypedFormGroup } from '@angular/forms';
import { PhoneCountryCodeEntity } from '@core/entities/country-codes/phone-country-code.entity';
import { Phone } from '@core/entities/user/phone.entity';
import { User } from '@core/entities/user/user.entity';
import { PhoneHelper } from '@core/helpers/phone.helper';
import { BehaviorSubject, Observable, catchError, finalize, tap, throwError } from 'rxjs';
import { PhoneForm } from '@shared/components/user-form/types/phone-form.type';
import { PhoneService } from '@core/services/user/phone.service';
import { UserFormBuilder } from '../user-form.factory';
import { PHONE_ERROR_MAPPING, PhoneValidator } from '@shared/validators/phone.validator';

@Component({
  selector: 'app-phones',
  templateUrl: './phones.component.html',
  styleUrls: ['./phones.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhonesComponent implements OnInit {
  @Input()
  isAdminContext = false;

  @Input()
  isOptional = false;

  @Input()
  phonesFormArray: FormArray<FormGroup<PhoneForm>>;

  @Input()
  selfUser?: User; // If editing other user, the user who's editing goes here

  @Input()
  user: User;

  @Output()
  validateFormEvent = new EventEmitter<boolean>();

  @Input()
  submitted = false;

  countryCodeSubject$ = new BehaviorSubject<PhoneCountryCodeEntity[]>([]);
  countryCodes$: Observable<PhoneCountryCodeEntity[]>;
  processing = false;

  constructor(private _cdr: ChangeDetectorRef, private _ufb: UserFormBuilder, private _phoneService: PhoneService) {}

  get parentGroup(): UntypedFormGroup {
    return this.phonesFormArray.parent as UntypedFormGroup;
  }

  ngOnInit() {
    /**
     * https://stackoverflow.com/a/38937802 - Use timeout to delay one tick to
     * avoid ExpressionChangeAfterItWasCheckedError on [formGroup]="phonesFormArray.parent"
     */
    setTimeout((_) => {
      this._buildPhones();
    });
    this.countryCodes$ = this._phoneService.getPhoneCountryCodes();
  }

  /**
   * For use when removing a phone that wasn't saved yet (aka. missing uuid)
   */
  cancelPhone(phoneIdx: number): void {
    this.phonesFormArray.controls.splice(phoneIdx, 1);
    this.phonesFormArray.updateValueAndValidity();
    this.validateForm();
  }

  newPhone() {
    this.phonesFormArray.push(this._ufb.phone(null, !this.isOptional, this.user.phones, true));
  }

  phoneUpdated(phone: Phone) {
    this.user.phones.forEach((e: Phone) => {
      if (phone.updatedProperty === PhonePropertyUpdate.PRIMARY) {
        e.primary = e.uuid === phone.uuid;
      }

      if (e.uuid !== phone.uuid) {
        e.verificationSent = false;
      }
    });
    this.user.phones = [...this.user.phones];
  }

  removePhone(removed: Phone) {
    const idx = this.user.phones.findIndex((ex: Phone) => ex.uuid === removed.uuid);
    if (idx > -1) {
      this.user.phones.splice(idx, 1);
    }
  }

  validateForm() {
    this.validateFormEvent.emit(true);
  }

  submitPhone(index: number) {
    this.submitted = true;
    const phoneControl = this.phonesFormArray.controls[index];

    if (phoneControl.invalid && !this._hasOnlyUnsavedError(phoneControl.controls.number)) {
      return;
    }

    this.processing = true;
    const phoneNumber = `+${phoneControl.value.countryCode}${phoneControl.value.number}`;

    this._phoneService
      .postPhone(this.user.uuid, phoneNumber)
      .pipe(
        tap((response) => {
          response.verificationSent = !this.isAdminContext;
          if (this.isAdminContext) {
            this.user.phones.push(response);
          }
          this.phonesFormArray.removeAt(index);
        }),
        catchError((error) => {
          if (error.status === 400 || error.status === 422) {
            phoneControl.setErrors(PhoneValidator.convertViolationsToValidationErrors(error.error.violations));
          }
          return throwError(() => error);
        }),
        finalize(() => {
          this.processing = false;
          this._cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  private _buildPhones(): void {
    if (this.user.phones.length === 0 && this.phonesFormArray.length === 0 && this.phonesFormArray.enabled) {
      this.phonesFormArray.push(this._ufb.phone(null, !this.isOptional));
    }
    PhoneHelper.sortPhones(this.user.phones);
    this._cdr.detectChanges();
  }

  private _hasOnlyUnsavedError(control: PhoneForm['number']): boolean {
    return (
      control.errors === null ||
      (Object.keys(control.errors).length === 1 && control.hasError(PHONE_ERROR_MAPPING.UNSAVED_CHANGES))
    );
  }
}

export enum PhonePropertyUpdate {
  PRIMARY = 'PRIMARY',
  VERIFIED = 'VERIFIED',
}
