import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ElevatedAccessService {
  constructor(private _httpClient: HttpClient) {}

  /**
   * Prepares elevated access for the next request against an endpoint requiring elevated access.
   *
   * @param {string} password
   * @returns {Observable<boolean>}
   */
  elevateAccess(password: string): Observable<boolean> {
    return this._httpClient.post(EnvironmentHelper.fetchAPIBase('v1/elevated-access-session'), { password }).pipe(
      map((response: { elevatedAccessSession?; status: number }) => {
        return response.elevatedAccessSession;
      }),
    );
  }
}
