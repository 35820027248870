<h3>Kontostatus</h3>

<div class="well flex flex--between">
  <span class="pad-left flex">
    @if (!user.isActive) {
      <svg-icon
        [applyClass]="true"
        src="assets/icons/triangled_exclamation.svg"
        class="s18"
      ></svg-icon>
    }
    {{ user.isActive ? 'Aktivert' : 'Deaktivert' }}
  </span>

  <a class="button inline red padded edit-status" routerLink="/users/{{ user.uuid }}/status"> Endre status </a>
</div>
