import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Phone } from '@core/entities/user/phone.entity';
import { User } from '@core/entities/user/user.entity';
import { ProcessableInterface } from '@core/interfaces/processable.interface';
import { PhoneService } from '@core/services/user/phone.service';
import { PhonePropertyUpdate } from '@shared/components/user-form/phones/phones.component';
import { throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

@Component({
  selector: 'app-existing-phone',
  templateUrl: './existing-phone.component.html',
  styleUrls: ['./existing-phone.component.scss'],
})
export class ExistingPhoneComponent implements AfterViewInit, ProcessableInterface {
  @Output()
  deletedPhone: EventEmitter<Phone> = new EventEmitter<Phone>();

  @Input()
  formDisabled?: boolean;

  @ViewChild('inputRef', { static: false })
  inputRef: ElementRef<HTMLInputElement>;

  @Input()
  isAdminContext = false;

  @Input()
  isOptional?: boolean = false;

  @Input()
  phone: Phone;

  @Input()
  selfUser: User;

  @Output()
  updatedPhone: EventEmitter<Phone> = new EventEmitter<Phone>();

  @Input()
  user: User;

  processing: boolean = false;
  submitted: boolean = false;
  verifyCtrl: UntypedFormControl = this._fb.control(null, [Validators.required, Validators.minLength(6)]);

  constructor(private _fb: UntypedFormBuilder, private _phoneService: PhoneService, private _cdr: ChangeDetectorRef) {}

  get isLastRemaining(): boolean {
    return this.user.phones.length < 2;
  }

  ngAfterViewInit(): void {
    if (this.phone.verificationSent) {
      this.inputRef.nativeElement.focus();
    }
  }

  deletePhone(): void {
    if (this.processing) {
      return;
    }

    this.processing = true;

    this._phoneService
      .deletePhone(this.user.uuid, this.phone.uuid)
      .pipe(
        tap(() => {
          this.deletedPhone.emit(this.phone);
        }),
        finalize(() => {
          this.processing = false;
          this._cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  sendVerificationSms(): void {
    this.processing = true;
    this._phoneService
      .sendVerificationSms(this.phone.uuid)
      .pipe(
        tap((phone: Phone) => {
          this.phone = phone;
          this.phone.verificationSent = true;
          this.inputRef.nativeElement.focus();
        }),
        finalize(() => {
          this.processing = false;
          this._cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  setPrimary(): void {
    this.processing = true;
    this._phoneService
      .setPrimaryPhone(this.phone.uuid, this.user.uuid)
      .pipe(
        tap((updatedPhone: Phone) => {
          updatedPhone.updatedProperty = PhonePropertyUpdate.PRIMARY;
          this.updatedPhone.emit(updatedPhone);
        }),
        finalize(() => {
          this.processing = false;
          this._cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  verifyNumber(): void {
    this.submitted = true;
    if (this.verifyCtrl.invalid) {
      return;
    }

    this.processing = true;

    this._phoneService
      .verifyPhone(this.phone.uuid, this.verifyCtrl.value, this.user.uuid)
      .pipe(
        tap((updatedPhone: Phone) => {
          this.processing = false;
          this.phone = updatedPhone;
          this.phone.verified$.next(true);
          this.phone.updatedProperty = PhonePropertyUpdate.VERIFIED;
          this.updatedPhone.emit(this.phone);
        }),
        catchError((e) => {
          if (e.error.error === 'TOTP_CODE_INVALID') {
            this.verifyCtrl.setErrors({ invalid: true });
          } else {
            this.verifyCtrl.setErrors({ failed: true });
          }

          this.processing = false;
          return throwError(() => e);
        }),
      )
      .subscribe();
  }
}
