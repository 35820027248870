import { ScopeEntity } from '@core/entities/oauth/scope.entity';
import { ClientEntity } from '@core/entities/client/client.entity';
import { RevokeUrlEntity } from '@core/entities/api/revoke-url.entity';
import { ProcessableInterface } from '@core/interfaces/processable.interface';
import { UserInterface } from '@core/entity-interfaces/user/user.interface';

/**
 * @see API_ENTITY_SCHEMA
 */
export class ApiEntity implements ProcessableInterface {
  processing: boolean;

  private _clients: ClientEntity[] = [];
  private _createdAt: Date;
  private _description: string;
  private _developers: UserInterface[] = [];
  private _name: string;
  private _namespace: string;
  private _revokeUrls: RevokeUrlEntity[] = [];
  private _scopes: ScopeEntity[] = [];
  private _uuid: string;

  get clients(): ClientEntity[] {
    return this._clients;
  }

  set clients(value: ClientEntity[]) {
    this._clients = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get developers(): UserInterface[] {
    return this._developers;
  }

  set developers(developers: UserInterface[]) {
    developers.forEach((developer) => {
      this.addDeveloper(developer);
    });
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get namespace(): string {
    return this._namespace;
  }

  set namespace(value: string) {
    this._namespace = value;
  }

  get revokeUrls(): RevokeUrlEntity[] {
    return this._revokeUrls;
  }

  set revokeUrls(value: RevokeUrlEntity[]) {
    this._revokeUrls = value;
  }

  get scopes(): ScopeEntity[] {
    return this._scopes;
  }

  set scopes(value: ScopeEntity[]) {
    this._scopes = value;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  /**
   * @param {UserInterface} developer
   */
  addDeveloper(developer: UserInterface) {
    this._developers.push(developer);
  }
}
