import { Component } from '@angular/core';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss'],
})

export class PublicHeaderComponent {
  hover: boolean = false;
}
