<div class="primary-label">{{ phone.primary ? 'Hovednummer' : 'Reservenummer' }}.</div>
<div class="phone-label">{{ phone.phoneNumber }}</div>
@if (processing) {
  <app-ff-loading-indicator
    class="well__header__indicator stroke--mine-shaft"
    size="small"
  ></app-ff-loading-indicator>
}

<div class="verified-label">
  <svg-icon
    [applyClass]="true"
    [src]="'assets/icons/circled_' + ((phone.verified$ | async) ? 'check_mark' : 'x') + '_24.svg'"
    class="footer__icon s18 monochrome stroke-text-50 no-fill"
  ></svg-icon>
  <span>{{ (phone.verified$ | async) ? 'Bekreftet' : 'Ubekreftet' }}.</span>
</div>

<div class="actions">
  <app-ff-menu-button triggerIcon="icons/ellipsis.svg" [triggerIconSize]="18" triggerText="Valg">
    @if ((phone.verified$ | async) && !phone.primary) {
      <app-ff-menu-item
        (clicked)="setPrimary()"
        iconPath="icons/medal.svg"
        label="Sett som hovednummer"
      ></app-ff-menu-item>
    }

    @if (!isAdminContext && (phone.verified$ | async) === false) {
      <app-ff-menu-item
        (clicked)="sendVerificationSms()"
        iconPath="icons/arrow_circular.svg"
        label="Send sms med ny bekreftelseskode"
      ></app-ff-menu-item>
    }

    @if (!formDisabled && (isAdminContext || (!isLastRemaining && !phone.primary))) {
      <app-ff-menu-item
        (clicked)="deletePhone()"
        iconPath="icons/trash.svg"
        label="Fjern dette"
      ></app-ff-menu-item>
    }

    @if (!isAdminContext && (isLastRemaining || phone.primary)) {
      <app-ff-menu-item
        [disabled]="true"
        iconPath="icons/trash.svg"
        label="Kan ikke slette hovednummer"
      ></app-ff-menu-item>
    }
  </app-ff-menu-button>
</div>

<div [hidden]="!phone.verificationSent || (phone.verified$ | async)" aria-live="assertive" class="verify">
  <div class="info">
    Vi har sendt en sms til <strong>{{ phone.phoneNumber || 'din telefon' }}</strong
    >. Angi engangskoden fra den meldingen for å bekrefte nummeret.
  </div>

  <app-ff-accept-cancel-input
    (accepted)="verifyNumber()"
    [processing]="processing"
    exclude="cancel"
    labelAccept="Bekreft"
    >
    <input
      #inputRef
      (keyup.enter)="verifyNumber()"
      [formControl]="verifyCtrl"
      [readOnly]="processing ? true : null"
      autocomplete="one-time-code"
      data-cy="phone-otp-input"
      inputmode="numeric"
      placeholder="Bekreftelseskode fra SMS"
      type="text"
      />
  </app-ff-accept-cancel-input>

  @if (submitted && verifyCtrl.errors) {
    <div class="error">
      @if (verifyCtrl.hasError('failed')) {
        <span> &#32;Noe gikk galt, prøv igjen. </span>
      }
      @if (verifyCtrl.hasError('invalid')) {
        <span> &#32;Ugyldig engangskode. </span>
      }
      @if (verifyCtrl.hasError('minlength')) {
        <span> &#32;For kort engangskode. </span>
      }
      @if (verifyCtrl.hasError('required')) {
        <span> &#32;Fyll ut engangskode du mottok på SMS. </span>
      }
    </div>
  }
</div>
