<label for="phoneInput">Mobilnummer</label>
<div class="phone-form-group" [formGroup]="phoneFormGroup" [class.submitted]="showErrors">
  <app-ff-typeahead
    [formCtrl]="countryCodeControl"
    [items$]="countryCodes$"
    [itemPropertiesForSearching]="['name', 'code', 'phoneCode']"
    itemDisplayFormat="+ %phoneCode%"
    itemsSource="local"
    textPlaceholder="Land"
    inputHtmlId="country-codes"
    [resultTemplate]="countryCodeSearchTemplate"
    >
  </app-ff-typeahead>
  <div class="separator">&nbsp;</div>
  <input
    #phoneInput
    id="phoneInput"
    formControlName="number"
    class="number-input"
    placeholder="Mobilnr"
    inputmode="tel"
    autocomplete="tel"
    type="text"
    />
</div>

@if (showErrors && errors$ | async; as errors) {
  <div class="error" aria-live="assertive" role="alert">
    @if (errors.length) {
      Feil: {{ errors }}
    }
  </div>
}
<ng-template #countryCodeSearchTemplate let-active="active" let-index="index" let-item="item">
  <div
    [attr.data-index]="index"
    [class.active]="active"
    class="typeahead-result-item"
    title="{{ item.name }} (+{{ item.phoneCode }})"
    >
    <div class="result-wrapper">
      <div class="name">{{ item.name }}</div>
      <div class="phone-code">+&nbsp;{{ item.phoneCode }}</div>
    </div>
  </div>
</ng-template>
