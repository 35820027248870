<h2>
  Tilgang

  @if (!isUserAdmin()) {
    <span class="subtitle"> Du har tilgang til å se men ikke endre brukeres tilganger </span>
  }
</h2>

<app-reactive-checkbox-tree-view
  [controlName]="userFormControls.roles"
  [formGroupCtrl]="rfh.getAsGroup(rolesFormArray.parent)"
  [isFirst]="true"
></app-reactive-checkbox-tree-view>
