<ul [formGroup]="formGroupCtrl" [class.first]="isFirst" class="level-{{ levelsDeep }}">
  @for (groupCtrl of formHelper.getArray(formGroupCtrl, controlName).controls; track groupCtrl; let idx = $index) {
    <li
      [formArrayName]="controlName"
      >
      <div formGroupName="{{ idx }}">
        <label
          (keyup.enter)="toggle(groupCtrl)"
          class="custom checkbox"
          [class.checked]="isChecked(groupCtrl)"
          tabindex="0"
          >
          <input type="checkbox" [formControl]="getCheckedCtrl(idx)" (change)="check(groupCtrl)" tabindex="-1" />
          <span class="icons">
            @if (!isFirst) {
              <svg-icon
                [applyClass]="true"
                class="icon sub item"
                role="presentation"
                src="assets/icons/arrow_sub_level_item.svg"
              ></svg-icon>
            }
            <svg-icon
              [applyClass]="true"
              [class.active]="isChecked(groupCtrl)"
              class="icon checkbox"
              role="presentation"
              src="assets/icons/form_checkbox.svg"
            ></svg-icon>
          </span>
          <span class="details">
            <span class="name">{{ groupCtrl.get('name').value }}</span>
            <span class="description">{{ groupCtrl.get('description').value }}</span>
          </span>
        </label>
      </div>
      @if (hasChildren(groupCtrl)) {
        <app-reactive-checkbox-tree-view
          (childChecked)="checkParent(groupCtrl)"
          [formGroupCtrl]="formHelper.getAsGroup(groupCtrl)"
          [isFirst]="false"
          [levelsDeep]="levelsDeep + 1"
          controlName="children"
        ></app-reactive-checkbox-tree-view>
      }
    </li>
  }
</ul>
