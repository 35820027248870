import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateHelper } from '@core/helpers/date.helper';

function isEmptyInputValue(value: any): boolean {
  return value === null || value.length === 0;
}

export class DateValidator {
  static readonly ERROR_NEWER_THAN = 'date_newer_than';
  static readonly ERROR_SYNTAX = 'date_syntax';

  /**
   * @param {AbstractControl} control
   * @returns {ValidationErrors | null}
   */
  static syntax(control: AbstractControl): ValidationErrors | null {
    if (isEmptyInputValue(control.value)) {
      return null;
    }

    return !DateValidator.isValidNorShortSyntax(control.value) ? { [DateValidator.ERROR_SYNTAX]: true } : null;
  }

  /**
   * Check current control value parsed as date is newer than compared date
   */
  static newerThan(compareToField: AbstractControl): ValidatorFn {
    return (control: AbstractControl) => {
      if (
        !compareToField ||
        !compareToField.value ||
        !control.value ||
        isEmptyInputValue(control.value) ||
        !control.valueChanges
      ) {
        return null;
      }

      const currDate = DateHelper.parseDateStringShortNor(control.value);
      const compareToDate = DateHelper.parseDateStringShortNor(compareToField.value);
      return currDate && currDate <= compareToDate ? { [DateValidator.ERROR_NEWER_THAN]: true } : null;
    };
  }

  /**
   * Regular method that returns whether given string is valid syntax for norwegian date short.
   * Separate method so it can be called directly and not only as Validator
   */
  static isValidNorShortSyntax(str: string): boolean {
    const regex = new RegExp(DateHelper.REGEX_DATE_NOR_SHORT);
    return regex.test(str);
  }
}
