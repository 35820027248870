import { ActiveDescendantKeyManager } from "@angular/cdk/a11y";
import { ENTER, ESCAPE, TAB } from "@angular/cdk/keycodes";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  ViewChild,
} from "@angular/core";
import { FfMenuItemComponent } from "@shared/components/ff-menu-item/ff-menu-item.component";
import { FfTooltipArrowPositions } from "@shared/components/ff-tooltip/ff-tooltip-arrow-positions.enum";

@Component({
  selector: "app-ff-menu-button",
  templateUrl: "./ff-menu-button.component.html",
  styleUrls: ["./ff-menu-button.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FfMenuButtonComponent implements AfterViewInit {
  active: boolean = false;

  @Input({ required: false })
  arrowPosition?: FfTooltipArrowPositions;

  @Input({ required: false })
  disabled = null; // disabled=false still disables, while null omits attrib

  hasMenuItems: boolean = false;

  @ContentChildren(FfMenuItemComponent, { descendants: true })
  items: QueryList<FfMenuItemComponent>;

  keyManager: ActiveDescendantKeyManager<FfMenuItemComponent>;

  @ViewChild("menu", { static: false })
  menu: ElementRef;

  role = "button";

  @Input({ required: false })
  tabindex = "0";

  @Input({ required: false })
  triggerButton?: string;

  @Input({ required: false })
  triggerButtonIconClass?: string;

  @Input({ required: false })
  triggerButtonIconColor?:
    | "blue"
    | "beige"
    | "gray"
    | "green"
    | "red"
    | "transparent"
    | "white";

  @Input({ required: false })
  triggerButtonIconSize?: number = 16;

  @Input({ required: false })
  triggerIcon?: string;

  @Input({ required: false })
  triggerIconSize?: number = 20;

  @Input({ required: true })
  triggerText: string;

  menuOpen: boolean;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _elementRef: ElementRef
  ) {}

  get buttonClassList(): string {
    return this.triggerButtonIconClass || "";
  }

  ngAfterViewInit() {
    this.keyManager = new ActiveDescendantKeyManager(this.items)
      .withWrap()
      .withTypeAhead();

    setTimeout(() => {
      this.hasMenuItems = this.#shouldBeVisible();
      this._cdr.detectChanges();
    });

    this.items.changes.subscribe(() => {
      this.hasMenuItems = this.#shouldBeVisible();
      this._cdr.detectChanges();
    });
  }

  @HostListener("window:click", ["$event"])
  handleMouseClick(event: MouseEvent): void {
    // If mouse click outside autocomplete menu or its ancestor, hide menu
    if (!this._elementRef.nativeElement.contains(event.target)) {
      this.hideMenu();
    }
  }

  /**
   * Callback for keypress inside input
   */
  @HostListener("keydown", ["$event"])
  handleKeypress(event: KeyboardEvent) {
    if (this.menuOpen) {
      event.stopImmediatePropagation();
      event.stopPropagation();
      event.preventDefault();
    }

    const key = event.key || event.keyCode;

    switch (key) {
      case "Tab":
      case TAB:
        this.nextItem();
        break;

      case "Escape":
      case ESCAPE:
        this.hideMenu();
        break;

      case "Enter":
      case ENTER:
        if (!this.menuOpen) {
          this.showMenu();
        } else {
          // Select item
          this.hideMenu();

          if (this.items.length > 1 && this.keyManager.activeItem) {
            // Trigger the regular mouse click on the selected item
            this.keyManager.activeItem.elementRef.nativeElement.click();
            return;
          }
        }
        break;

      default:
        // All other keys are passed on to the manager
        this.keyManager.onKeydown(event);
        break;
    }
    return;
  }

  hideMenu(): void {
    this.menuOpen = false;
  }

  nextItem(): boolean {
    if (!this.menuOpen) {
      return true;
    }

    this.keyManager.setNextItemActive();
    return true;
  }

  showMenu(): void {
    this.menuOpen = true;
    this._elementRef.nativeElement.focus();
  }

  @HostListener("click")
  toggleMenu() {
    this.menuOpen ? this.hideMenu() : this.showMenu();
  }

  #shouldBeVisible(): boolean {
    return this.items.length > 0;
  }
}
