import { alias, date, identifier, serializable } from 'serializr';

export class GrantEntity {
  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  @serializable(alias('name'))
  private _name: string;

  @serializable(alias('createdAt', date()))
  private _createdAt: Date;

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get icon(): string {
    if (this.name === 'authorization_code') {
      return 'assets/icons/grant-p2c.svg';
    } else if (this.name === 'client_credentials') {
      return 'assets/icons/grant-c2c.svg';
    } else {
      return '';
    }
  }
}
