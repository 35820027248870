import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { UserFeedbackEntity } from "@core/entities/user/user-feedback.entity";
import { ElementRefHelper } from "@core/helpers/element-ref.helper";
import { ReactiveFormsHelper } from "@core/helpers/reactive-forms.helper";
import { ClientService } from "@core/services/client/client.service";
import { LogService } from "@core/services/log/log.service";
import { ModalService } from "@core/services/modal/modal.service";
import { CorsUriEntity } from "@shared/components/cors-uris/cors-uri.entity";
import { FfConfirmModalDataInterface } from "@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal-data.interface";
import { FfConfirmModalComponent } from "@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal.component";
import { UniqueValidator } from "@shared/validators/unique.validator";
import { deserialize } from "serializr";

@Component({
  selector: "app-cors-uris",
  templateUrl: "./cors-uris.component.html",
  styleUrls: ["./cors-uris.component.scss"],
})
export class CorsUrisComponent {
  private _messagesRef: ElementRef;

  @Input({ required: true })
  clientId!: string;

  @Output()
  deleteUri: EventEmitter<CorsUriEntity> = new EventEmitter<CorsUriEntity>();

  @Output()
  saveUri: EventEmitter<CorsUriEntity> = new EventEmitter<CorsUriEntity>();

  @Input({ required: true })
  uris!: CorsUriEntity[];

  feedbackMessages: Array<UserFeedbackEntity> = [];
  formHelper = ReactiveFormsHelper;
  processing: boolean = false;
  showAddForm: boolean;
  submitted: boolean = false;
  uriForm: UntypedFormGroup;

  constructor(
    private _clientService: ClientService,
    private _fb: UntypedFormBuilder,
    private _logService: LogService,
    private _modalService: ModalService
  ) {}

  @ViewChild("feedbackMessagesRef", { read: ElementRef, static: false })
  set messagesRef(elRef: ElementRef) {
    this._messagesRef = elRef;
  }

  confirmRemoval(uri: CorsUriEntity) {
    const confirmContent: FfConfirmModalDataInterface = {
      component: FfConfirmModalComponent,
      title: "Er du sikker?",
      body: "Vil du faktisk fjerne denne som CORS-URL? «" + uri.url + "»",
      confirmCallback: () => {
        this.delete(uri);
      },
    };

    this._modalService.openModal(confirmContent);
  }

  delete(uri: CorsUriEntity) {
    uri.processing = true;
    this._clientService.deleteCors(uri.id).subscribe(
      (redir) => {
        uri.processing = false;
        this.deleteUri.emit(redir);
      },
      () => {
        this.processing = false;
      }
    );
  }

  save() {
    this.feedbackMessages = [];
    this.submitted = true;

    if (this.uriForm.invalid) {
      this.showErrorsIfSubmitted();
      return;
    }

    const uriObj = deserialize(CorsUriEntity, this.uriForm.value);
    this.processing = true;
    this._clientService.postCors(this.clientId, uriObj.url).subscribe(
      (uri) => {
        this.processing = false;
        this.saveUri.emit(uri);
        this.showAddForm = false;
        this.uriForm.reset();
      },
      () => {
        this.processing = false;
      }
    );
  }

  showErrorsIfSubmitted(): void {
    if (!this.submitted) {
      return;
    }

    this.feedbackMessages = ReactiveFormsHelper.getErrorStrings(
      this.uriForm,
      CorsUrisErrorEnum,
      this._logService
    );

    if (this.feedbackMessages.length > 0) {
      ElementRefHelper.scrollTo(this._messagesRef);
    }
  }

  toggleAddForm() {
    this.uriForm = this.#buildUriGroup(new CorsUriEntity());
    this.uriForm.reset();
    this.feedbackMessages = [];
    this.submitted = false;
    this.processing = false;
    this.showAddForm = !this.showAddForm;
  }

  #buildUriGroup(cors: CorsUriEntity): UntypedFormGroup {
    return this._fb.group({
      id: [!cors ? null : cors.id],
      url: !cors
        ? [null]
        : [
            cors.url,
            [
              Validators.required,
              UniqueValidator.uniqueInArray(this.uris.map((u) => u.url)),
            ],
          ],
      processing: [false],
    });
  }
}

enum CorsUrisErrorEnum {
  URL_DUPLICATE = "URL-en ligger allerede i listen over CORS-adresser",
  URL_REQUIRED = "Oppgi en URL å legge til",
}
