import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class FfLoadingScreenService {
  private _dependants: Array<string> = [];
  private _stateSubject: ReplaySubject<boolean> = new ReplaySubject(1);

  getSubject(): Observable<boolean> {
    return this._stateSubject;
  }

  addDependant(dependant: string) {
    if (this._dependants.indexOf(dependant) === -1) {
      this._dependants.push(dependant);

      if (this._dependants.length > 0) {
        this._show();
      }
    }
  }

  removeDependant(dependant: string) {
    if (this._dependants.indexOf(dependant) !== -1) {
      this._dependants.splice(this._dependants.indexOf(dependant), 1);
    }

    if (this._dependants.length < 1) {
      this._hide();
    }
  }

  private _hide(): void {
    this._stateSubject.next(true);
  }

  private _show(): void {
    this._stateSubject.next(false);
  }
}

export enum LoadingScreenDependencies {
  IMPERSONATING = 'IMPERSONATING',
  NAVIGATION = 'NAVIGATION',
}
