import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
} from "@angular/core";

@Component({
  selector: "app-ff-button",
  templateUrl: "./ff-button.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FfButtonComponent implements AfterViewInit, OnChanges {
  private _darkColors: ButtonColors[] = [
    "red",
    "blue",
    "green",
    "primary",
    "secondary",
  ];

  private _lightColors: ButtonColors[] = [
    "beige",
    "gray",
    "sand",
    "transparent",
    "white",
  ];

  @Output()
  clicked: EventEmitter<void> = new EventEmitter();

  // Color for button bg. Label for color will be adjusted
  @Input({ required: false })
  color?: ButtonColors = "red";

  @Input({ required: false })
  disabled = null;

  @HostBinding("class")
  hostClass = "btn";

  @Input({ required: false })
  iconPath?: string;

  @Input({ required: false })
  iconSize?: number;

  @HostBinding("attr.aria-label")
  @Input({ required: false })
  label?: string;

  @HostBinding("class.processing")
  @Input({ required: false })
  processing = false;

  @HostBinding("attr.role")
  role = "button";

  @HostBinding("tabindex")
  tabindex = "0";

  active: boolean = false;
  hover: boolean = false;

  constructor(private readonly _cdr: ChangeDetectorRef) {}

  /**
   * disabled=false still disables, while null omits attrib which is what we really want
   */
  @HostBinding("disabled")
  get disabledAttrib(): true | null {
    return this.disabled ? true : null;
  }

  ngAfterViewInit(): void {
    // delay 1 tick to skip first change detection
    setTimeout(() => {
      this.#updateHostClass();
    }, 1);
  }

  ngOnChanges(): void {
    this.#updateHostClass();
  }

  @HostListener("mouseenter", ["$event"])
  onEnter(): void {
    this.hover = true;
    this._cdr.detectChanges();
  }

  @HostListener("mouseleave", ["$event"])
  onLeave(): void {
    this.hover = false;
    this._cdr.detectChanges();
  }

  @HostListener("mousedown", ["$event"])
  onMouseDown(): void {
    this.active = true;
    this._cdr.detectChanges();
  }

  @HostListener("mouseup", ["$event"])
  onMouseRelease(): void {
    this.active = false;
    this._cdr.detectChanges();
  }

  @HostListener("click", ["$event"])
  @HostListener("keydown.enter", ["$event"])
  clickCallback(e: Event | null): boolean {
    if (e) {
      // The Karma test runner doesn't supply event?
      e.stopPropagation();
      e.preventDefault();
    }

    if (!this.disabled && !this.processing) {
      this.clicked.emit();
    }

    return true;
  }

  @HostBinding("class.active")
  get isActive(): boolean {
    return this.active;
  }

  @HostBinding("class.disabled")
  get isDisabled(): boolean {
    return this.disabled || this.processing;
  }

  /**
   * Retrieve the classes to apply to the svg element
   */
  getChildrenClassList(): string {
    let classes: string[] = [];

    if (this.iconSize) {
      classes.push("s" + this.iconSize);
    }

    if (this.label?.length > 0) {
      classes.push("has-label");
    }

    if (this._lightColors.indexOf(this.color) > -1) {
      classes.push("monochrome--dark");
    } else if (this._darkColors.indexOf(this.color) > -1) {
      classes.push("monochrome--white");
    }

    if (this.hover) {
      classes.push("active");
    }

    return classes.join(" ");
  }

  #updateHostClass(): void {
    if (!this.color) {
      this.color = "red";
    }

    this.hostClass += " btn--" + this.color;
    this._cdr.detectChanges();
  }
}

export type ButtonColors =
  | "beige"
  | "blue"
  | "gray"
  | "green"
  | "primary"
  | "red"
  | "sand"
  | "secondary"
  | "transparent"
  | "white";
