import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import { UserFeedbackEntity } from '@core/entities/user/user-feedback.entity';
import { User } from '@core/entities/user/user.entity';
import { UserFeedbackTypesEnum } from '@core/enums/user-feedback-types.enum';
import { ElementRefHelper } from '@core/helpers/element-ref.helper';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';
import { ProcessableInterface } from '@core/interfaces/processable.interface';
import { LogService } from '@core/services/log/log.service';
import { UserService } from '@core/services/user/user.service';
import { catchError, finalize, of, tap } from 'rxjs';

@Component({
  selector: 'app-user-password-form',
  templateUrl: './user-password-form.component.html',
  styleUrls: ['./user-password-form.component.scss'],
})
export class UserPasswordFormComponent implements OnInit, ProcessableInterface {
  @Output()
  failed: EventEmitter<any> = new EventEmitter();

  @Input()
  inputLabel?: string;

  @Input()
  pendingInfoUpdates?: PendingInformationUpdates;

  /**
   * Fires when password has been saved
   *
   * @type {EventEmitter<boolean>}
   */
  @Output()
  saved: EventEmitter<boolean> = new EventEmitter();

  @Input()
  submitIcon?: string;

  @Input()
  user: User;

  feedbackMessages: Array<UserFeedbackEntity> = [];
  formHelper = ReactiveFormsHelper;
  existingCtrl: UntypedFormControl;
  passCtrl: UntypedFormControl;
  passwordForm: UntypedFormGroup;
  processing: boolean = false;
  showExisting: boolean = false;
  submitted: boolean = false;

  private _messagesRef: ElementRef;

  constructor(
    private _formBuilder: UntypedFormBuilder,
    private _logService: LogService,
    private _userService: UserService,
    private _cdr: ChangeDetectorRef
  ) {}

  @ViewChild('feedbackMessagesRef', { read: ElementRef, static: false })
  set messagesRef(elRef: ElementRef) {
    this._messagesRef = elRef;
  }

  ngOnInit() {
    this.passwordForm = this._formBuilder.group({
      password: [
        null,
        {
          validators: [Validators.required],
          updateOn: 'change',
        },
      ],
      existing: [null],
    });

    this.existingCtrl = ReactiveFormsHelper.getCtrl(this.passwordForm, 'existing');
    this.passCtrl = ReactiveFormsHelper.getCtrl(this.passwordForm, 'password');

    this._toggleExistingPasswordField();
  }

  onPasswordValueChange() {
    this.showErrorsIfSubmitted();
  }

  savePassword() {
    this.submitted = true;
    this.feedbackMessages = [];

    if (this.passwordForm.invalid) {
      this.showErrorsIfSubmitted();
      return;
    }

    this.processing = true;
    this._userService
      .updatePassword(this.user.uuid, this.passCtrl.value, this.passCtrl.value, this.existingCtrl.value)
      .pipe(
        tap(() => {
          // This emit is crucial, it fires callback for SSO + OAuth
          this.saved.emit(true);

          // Update local comp + view
          this.processing = false;
          this.submitted = false;
          this.passwordForm.reset();
          this._toggleExistingPasswordField();
          this.showErrorsIfSubmitted();
          this.feedbackMessages.push(new UserFeedbackEntity('Lagret', UserFeedbackTypesEnum.INFORMATIVE));
        }),
        catchError((err) => {
          this.failed.emit(err);
          this.processing = false;
          this.feedbackMessages.push(new UserFeedbackEntity('Fikk ikke lagret', UserFeedbackTypesEnum.ERROR));
          if (err.error.error === 'VALIDATION_ERROR' && err.error.violations) {
            err.error.violations.forEach((v) => {
              this.feedbackMessages.push(
                new UserFeedbackEntity(
                  UserPasswordFormErrorsEnum['API_' + v.errorName.toUpperCase()],
                  UserFeedbackTypesEnum.ERROR
                )
              );
            });
          }
          if (err.error.error === 'WRONG_PASSWORD') {
            this.feedbackMessages.push(
              new UserFeedbackEntity(
                'Det du oppga som eksisterende passord stemmer ikke med våre data',
                UserFeedbackTypesEnum.ERROR
              )
            );
          }
          return of(null);
        }),
        finalize(() => {
          this._cdr.detectChanges();
        })
      )
      .subscribe();
  }

  /**
   * @returns {boolean}
   */
  showErrorsIfSubmitted(): void {
    if (!this.submitted) {
      return;
    }

    this.feedbackMessages = ReactiveFormsHelper.getErrorStrings(
      this.passwordForm,
      UserPasswordFormErrorsEnum,
      this._logService
    );

    if (this.feedbackMessages.length > 0) {
      ElementRefHelper.scrollTo(this._messagesRef);
    }
  }

  /**
   * Sets validators and toggles display of existing password field
   */
  private _toggleExistingPasswordField() {
    if (this.user.tfaActivated) {
      this.existingCtrl.setValidators(Validators.required);
      this.showExisting = true;
    } else {
      this.existingCtrl.clearValidators();
      this.showExisting = false;
    }
  }
}

enum UserPasswordFormErrorsEnum {
  API_COMPROMISED_PASSWORD_ERROR = 'Passordet du valgte er allerede blitt kompromittert i datalekkasje hos andre nettsider, og er derfor ikke trygt å bruke. Velg et annet',
  API_INVALID_TYPE_ERROR = 'Noe gikk galt, prøv igjen',
  API_IS_BLANK_ERROR = 'Passord må angis',
  API_NOT_TRUE_ERROR = 'Eksisterende passord og gjenta eksisterende passord må være like',
  API_TOO_SHORT_ERROR = 'Passordet du valgte er for kort',
  EXISTING_REQUIRED = 'Vennligst oppi ditt eksisterende (gamle) passord for å bekrefte at du er du',
  PASSWORD_MINLENGTH = 'Passordet du valgte er for kort',
  PASSWORD_REQUIRED = 'Passord må angis',
}
