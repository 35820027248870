import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Email } from '@core/entities/user/email.entity';
import { EmailHelper } from '@core/helpers/email.helper';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { UserService } from '@core/services/user/user.service';
import { Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { deserialize, serialize } from 'serializr';
import { UserStoreService } from '../stores/user-store.service';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(private _http: HttpClient, private _userService: UserService, private _userStore: UserStoreService) {}

  /**
   * Convert from Email class to old definition
   */
  private _convertToOld(newEmail: Email) {
    const email = serialize(newEmail);
    email['email'] = newEmail.emailAddress;
    return email;
  }

  /**
   *  Convert to Email class from old definition
   */
  private _convertToNew(oldEmail: object) {
    const email = deserialize(Email, oldEmail);
    email.emailAddress = oldEmail['email'];
    return email;
  }

  /**
   * @param {string} emailAdr
   * @returns {Observable<boolean>}
   */
  checkValidEmail(emailAdr: string): Observable<boolean> {
    return this._http
      .get(EnvironmentHelper.fetchAPIBase('v1/validate/email/' + emailAdr))
      .pipe(map((response: { valid: boolean }) => response.valid));
  }

  /**
   * @param {string} userUuid
   * @param {string} emailUuid
   * @returns {Observable<Email>}
   */
  deleteEmail(userUuid: string, emailUuid: string): Observable<Email> {
    const deleteEmail$ = this._http.delete(EnvironmentHelper.fetchAPIBase('v1/emails/' + emailUuid)).pipe(
      map((response: { email: object }) => {
        return this._convertToNew(response.email);
      }),
    );

    return this._userService.isSelf(userUuid).pipe(
      concatMap((isSelf: boolean) => {
        return deleteEmail$.pipe(
          tap((deleted: Email) => {
            if (isSelf) {
              const user = this._userStore.user;
              const newEmails = [...user.emails];
              user.emails = EmailHelper.removeEmail(newEmails, deleted.uuid);
              this._userStore.user = user;
            }
          }),
        );
      }),
    );
  }

  /**
   *
   * @param userId
   * @param newEmail
   */
  postEmail(userId: string, newEmail: Email): Observable<Email> {
    const postedEmail$ = this._http
      .post(EnvironmentHelper.fetchAPIBase('v1/emails'), {
        email: this._convertToOld(newEmail),
        userUuid: userId,
        frontEndUrl: EnvironmentHelper.fetchFrontEndURL('/'),
        verifyUrl: EnvironmentHelper.fetchFrontEndURL('/verification/email'),
      })
      .pipe(
        map((response: { email: object }) => {
          return this._convertToNew(response.email);
        }),
      );

    return this._userService.isSelf(userId).pipe(
      concatMap((isSelf: boolean) => {
        return postedEmail$.pipe(
          tap((posted: Email) => {
            if (isSelf) {
              const user = this._userStore.user;
              const newEmails = [...user.emails];
              newEmails.push(posted);
              user.emails = newEmails;
              this._userStore.user = user;
            }
          }),
        );
      }),
    );
  }

  /**
   * @param {string} emailUuid
   * @returns {Observable<Email>}
   */
  sendVerificationEmail(emailUuid: string): Observable<Email> {
    return this._http
      .post(EnvironmentHelper.fetchAPIBase('v1/emails/' + emailUuid + '/send-verification-email'), {
        verifyUrl: EnvironmentHelper.fetchFrontEndURL('/verification/email'),
      })
      .pipe(map((response: { email: object }) => this._convertToNew(response.email)));
  }

  /**
   * @param {string} emailUuid
   * @param userUuid
   * @returns {Observable<Email>}
   */
  setPrimaryEmail(emailUuid: string, userUuid: string): Observable<Email> {
    const primaryEmail$ = this._http
      .post(EnvironmentHelper.fetchAPIBase('v1/emails/' + emailUuid + '/set-primary'), {})
      .pipe(map((response: { email: object }) => this._convertToNew(response.email)));

    return this._userService.isSelf(userUuid).pipe(
      concatMap((isSelf: boolean) => {
        return primaryEmail$.pipe(
          tap((primary: Email) => {
            if (isSelf) {
              const user = this._userStore.user;
              user.emails = this._updatePrimary(primary);
              this._userStore.user = user;
            }
          }),
        );
      }),
    );
  }

  /**
   * @param {string} emailUuid
   * @param {string} code
   * @param userUuid
   * @returns {Observable<Email>}
   */
  verifyEmail(emailUuid: string, code: string, userUuid?: string): Observable<Email> {
    const verifyEmail$ = this._http
      .post(EnvironmentHelper.fetchAPIBase('v1/emails/' + emailUuid + '/verify/' + code), undefined)
      .pipe(
        map((response: { email: object }) => {
          return this._convertToNew(response.email);
        }),
      );

    if (!userUuid) {
      return verifyEmail$;
    }

    return this._userService.isSelf(userUuid).pipe(
      concatMap((isSelf: boolean) => {
        return verifyEmail$.pipe(
          tap((verified: Email) => {
            if (isSelf) {
              const user = this._userStore.user;
              user.emails = this._updatePrimary(verified);
              this._userStore.user = user;
            }
          }),
        );
      }),
    );
  }

  /**
   * Get emails from store, return array with new primary
   *
   * @param {Email} newPrimary
   * @returns {Email[]}
   * @private
   */
  private _updatePrimary(newPrimary: Email): Email[] {
    const existingEmails = [...this._userStore.user.emails];
    existingEmails.forEach((existingEmail: Email) => {
      existingEmail.primary = existingEmail.uuid === newPrimary.uuid;
    });
    return existingEmails;
  }
}
