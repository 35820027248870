@if (processing) {
  <app-ff-loading-indicator
    [ngClass]="getChildrenClassList()"
    role="status"
    size="s20"
  ></app-ff-loading-indicator>
}

@if (iconPath && !processing) {
  <svg-icon
    class="icon"
    [ngClass]="getChildrenClassList()"
    [src]="'assets/' + iconPath"
    [svgStyle]="{ 'height.px': iconSize?.toString(), 'width.px': iconSize?.toString() }"
    role="presentation"
  ></svg-icon>
}

@if (label && !processing) {
  <span>{{ label }}</span>
}

<ng-content></ng-content>
