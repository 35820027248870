<div class="container">
  <div class="content">
    <main>
      <h1>Uffda!</h1>

      <!-- TODO: ngSwitch always showed its default case. Troubleshoot and refactor as ngSwitch -->

      @if (errorType === errorsEnum.USER_DEACTIVATED) {
        <div>
          Brukerkontoen er deaktivert. Ta kontakt med Fagforbundet eller
          <a href="https://www.fagforbundet.no">gå til Fagforbundets hovedside</a>.
        </div>
      }

      @if (errorType === errorsEnum.CONSENT_MISSING) {
        <div>
          Kan ikke sende deg videre til applikasjonen du var på vei til, da vi mangler tilstrekkelig godkjenning av
          tilgang til informasjon.
          <a href="https://www.fagforbundet.no">Gå til Fagforbundets hovedside</a>.
        </div>
      }

      @if (errorType === errorsEnum.LOGIN) {
        <div>
          Her gikk noe galt. Det ser ut til at siden du skulle til er satt opp feil. Vi har gitt beskjed til de
          ansvarlige. Du kan prøve igjen senere, eller
          <a href="https://www.fagforbundet.no">gå til Fagforbundets hovedside</a>.
        </div>
      }

      @if (errorType === errorsEnum.PAGE_NOT_FOUND) {
        <div>
          Her gikk noe galt. Sjekk at nettadressen er skrevet riktig eller
          <a [routerLink]="'/'">gå til hovedsiden</a>.
        </div>
      }

      @if (errorType === errorsEnum.SESSION_EXPIRED) {
        <div>
          Sesjonen utløp. Gå til tjenesten og prøv å logge inn på ny, eller
          <a [routerLink]="'/'">gå til hovedsiden</a>.
        </div>
      }

      @if (!errorType || errorType === errorsEnum.UNKNOWN) {
        <div>
          Her gikk noe galt. Om denne feilen gjentar seg, ta kontakt med Fagforbundet.
          <a [routerLink]="'/'">Gå til hovedsiden</a>.
        </div>
      }
    </main>
  </div>
</div>
