import { Injectable } from '@angular/core';
import { PhoneCountryCodeEntity } from '@core/entities/country-codes/phone-country-code.entity';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PhonesStoreService {
  private readonly _phoneCountryCodes$ = new BehaviorSubject<ReadonlyArray<PhoneCountryCodeEntity>>(null);

  phoneCountryCodes$ = this._phoneCountryCodes$.asObservable();

  get phoneCountryCodes(): ReadonlyArray<PhoneCountryCodeEntity> {
    return this._phoneCountryCodes$.value;
  }

  set phoneCountryCodes(value: ReadonlyArray<PhoneCountryCodeEntity>) {
    this._phoneCountryCodes$.next(value);
  }
}
