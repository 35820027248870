import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { GrantEntity } from "@core/entities/oauth/grant.entity";
import { ScopeEntity } from "@core/entities/oauth/scope.entity";
import { ArrayHelper } from "@core/helpers/array.helper";
import { DateHelper } from "@core/helpers/date.helper";
import { ReactiveFormsHelper } from "@core/helpers/reactive-forms.helper";
import { ModalService } from "@core/services/modal/modal.service";
import { GrantService } from "@core/services/oauth/grant.service";
import { FfConfirmModalDataInterface } from "@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal-data.interface";
import { FfConfirmModalComponent } from "@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal.component";
import { FfTooltipArrowPositions } from "@shared/components/ff-tooltip/ff-tooltip-arrow-positions.enum";
import { IFfTooltipOptions } from "@shared/components/ff-tooltip/ff-tooltip-options.interface";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { tap } from "rxjs/operators";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { OauthClient } from "@core/domain-models/clients/oauth/oauth-client";

@Component({
  selector: "app-scopes-list",
  templateUrl: "./scopes-list.component.html",
  styleUrls: ["./scopes-list.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScopesListComponent implements OnChanges, OnInit {
  readonly #destroyRef = inject(DestroyRef);

  readonly #scopesSubject$: BehaviorSubject<ScopeEntity[]> =
    new BehaviorSubject([]);

  readonly #searchSubject$: Subject<void> = new Subject();

  @Output()
  added: EventEmitter<ScopeEntity> = new EventEmitter<ScopeEntity>();

  // Use if scopes belong to a client (as opposed to a service)
  @Input({ required: false })
  client?: OauthClient;

  @Input({ required: false })
  collapsible = false;

  @Input()
  enableFiltering = false;

  // Use if scopes belong to a client (as opposed to a service)
  @Input({ required: false })
  grants?: GrantEntity[] = [];

  @Input({ required: true })
  isAppAdmin!: boolean;

  @Output()
  removed: EventEmitter<ScopeEntity> = new EventEmitter<ScopeEntity>();

  @Input({ required: false })
  routePrefix?: string;

  @Input({ required: true })
  scopes: ScopeEntity[] = [];

  @Output()
  toggleRequired: EventEmitter<ScopeEntity> = new EventEmitter<ScopeEntity>();

  deprecationTooltipOpts: IFfTooltipOptions = {
    arrowPosition: FfTooltipArrowPositions.bottomLeft,
  };

  expandedItems: boolean[] = [];

  filterForm = this._fb.group({
    query: [null],
  });

  grantAbbreviations = grantAbbreviationsEnum;
  now = new Date();
  processingFilters = false;
  processingToggles: boolean[] = [];
  rfh = ReactiveFormsHelper;
  scopesFiltered$: Observable<ScopeEntity[]>;
  showPrefixes: boolean = true;
  textCannotRemove: string;
  textRemove: string;

  constructor(
    private _cdr: ChangeDetectorRef,
    private _fb: UntypedFormBuilder,
    private _grantService: GrantService,
    private _modalService: ModalService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes["scopes"].isFirstChange()) {
      this.#searchSubject$.next();
    }
  }

  ngOnInit(): void {
    this.scopesFiltered$ = this.#scopesSubject$.asObservable();
    this.#scopesSubject$.next(this.#sortScopes(this.scopes));

    this.grants.sort(ArrayHelper.fieldSorter(["name"]));

    if (this.client) {
      this.textCannotRemove = "Scopet er låst og kan ikke fjernes fra klienten";
      this.textRemove = "Fjern scopet fra klienten";
    }

    this.#setupSubscriptions();
    this._cdr.detectChanges();
  }

  canBeManaged(scope: ScopeEntity): boolean {
    if (!this.client) {
      // API scenario, listing the API scopes
      return !scope.inUse;
    } else {
      // Client scenario, listing Clients scopes
      return scope.editable;
    }
  }

  collapse(s: ScopeEntity): void {
    this.expandedItems[s.uuid] = false;
  }

  collapseAll(): void {
    this.scopes.forEach((s) => {
      this.collapse(s);
    });

    this._cdr.detectChanges();
  }

  confirmRemoval(scope: ScopeEntity) {
    const confirmContent: FfConfirmModalDataInterface = {
      component: FfConfirmModalComponent,
      title: "Er du sikker?",
      body: "Vil du faktisk fjerne scopet «" + scope.name + "»?",
      confirmCallback: () => {
        this.remove(scope);
      },
    };

    this._modalService.openModal(confirmContent);
  }

  deprecationText(scope: ScopeEntity): string {
    if (!scope.scopeExpirationDate) {
      return "";
    }

    let text = "Utfases";

    if (scope.scopeExpirationDate.deprecatedSince) {
      text +=
        " fra: " +
        DateHelper.toShortNorString(scope.scopeExpirationDate.deprecatedSince);
    }

    text += ". ";

    if (scope.scopeExpirationDate.sunset) {
      text +=
        scope.scopeExpirationDate.sunset > this.now ? "Utgår: " : "Utgikk: ";

      text +=
        DateHelper.toShortNorString(scope.scopeExpirationDate.sunset) + ". ";
    }

    text += "Begrunnelse: " + scope.scopeExpirationDate.notes;
    return text;
  }

  expand(s: ScopeEntity): void {
    this.expandedItems[s.uuid] = true;
  }

  expandAll(): void {
    this.scopes.forEach((s) => {
      this.expand(s);
    });

    this._cdr.detectChanges();
  }

  filter() {
    this.processingFilters = true;
    let filteredScopes = this.scopes;

    // Filter by name/desc
    if (this.filterForm.get("query").value) {
      const query = this.filterForm.get("query").value.toLowerCase();

      filteredScopes = filteredScopes.filter(
        (s: ScopeEntity) =>
          s.name.toLowerCase().includes(query) ||
          s.description.toLowerCase().includes(query)
      );
    }

    filteredScopes.forEach((s) => {
      if (s.expand) {
        this.expand(s);
      }
    });

    this.#scopesSubject$.next(this.#sortScopes(filteredScopes));
    this.processingFilters = false;
    this._cdr.detectChanges();
  }

  grantIsAvailable(grant: GrantEntity): boolean {
    if (!this.client) {
      return false;
    }

    let isAvailable = false;

    this.client.allowedGrants.forEach((existingGrant: GrantEntity) => {
      if (existingGrant.uuid === grant.uuid) {
        isAvailable = true;
      }
    });

    return isAvailable;
  }

  pastSunset(scope: ScopeEntity): boolean {
    return (
      scope.scopeExpirationDate &&
      scope.scopeExpirationDate.sunset &&
      scope.scopeExpirationDate.sunset.getTime() < this.now.getTime()
    );
  }

  scopeHasGrant(scope: ScopeEntity, grant: GrantEntity): boolean {
    let hasGrant = false;

    scope.allowedGrants.forEach((existingGrant: GrantEntity) => {
      if (existingGrant.uuid === grant.uuid) {
        hasGrant = true;
      }
    });

    return hasGrant;
  }

  remove(scope: ScopeEntity) {
    if (!this.canBeManaged(scope)) {
      return;
    }

    this.removed.emit(scope);
    scope.processing = true;
    this._cdr.detectChanges();
  }

  toggleGrant(scope: ScopeEntity, grant: GrantEntity): void {
    const processCode = "scope." + scope.uuid + ".grant." + grant.uuid;
    this.processingToggles[processCode] = true;

    if (this.scopeHasGrant(scope, grant)) {
      this._grantService
        .removeGrantFromScope(this.client.uuid, scope.uuid, grant.uuid)
        .pipe(
          tap(() => {
            scope.allowedGrants.forEach((existingGrant: GrantEntity) => {
              if (existingGrant.uuid === grant.uuid) {
                scope.allowedGrants.splice(
                  scope.allowedGrants.indexOf(existingGrant),
                  1
                );
              }
            });

            this.processingToggles[processCode] = false;
            this._cdr.detectChanges();
          })
        )
        .subscribe();
    } else {
      this._grantService
        .addGrantToScope(this.client.uuid, scope.uuid, grant.uuid)
        .pipe(
          tap(() => {
            scope.allowedGrants.push(grant);
            this.processingToggles[processCode] = false;
            this._cdr.detectChanges();
          })
        )
        .subscribe();
    }
  }

  togglePrefixes() {
    this.showPrefixes = !this.showPrefixes;
    this._cdr.detectChanges();
  }

  #setupSubscriptions() {
    this.#scopesSubject$
      .pipe(
        tap(() => this._cdr.detectChanges()),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();

    // Listener runs filter() on any change to filter form
    this.filterForm.valueChanges
      .pipe(
        tap(() => this.#searchSubject$.next()),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();

    // Run through filter whenever search or collection has changed
    this.#searchSubject$
      .pipe(
        tap(() => this.filter()),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();
  }

  #sortScopes(scopes: ScopeEntity[]): ScopeEntity[] {
    return scopes.sort(ArrayHelper.fieldSorter(["name", "-global", "-locked"]));
  }
}

enum grantAbbreviationsEnum {
  authorization_code = "AC",
  client_credentials = "CC",
  implicit = "IM",
  refresh_token = "RT",
}
