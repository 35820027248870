import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ValidationViolationResponseEntity } from '@core/entities/response/validation-violation-response.entity';
import { Phone } from '@core/entities/user/phone.entity';
import { PhoneService } from '@core/services/user/phone.service';
import { PhoneForm } from '@shared/components/user-form/types/phone-form.type';
import { Observable, map, catchError, of } from 'rxjs';

function isEmptyInputValue(value: any): boolean {
  return value === null || value.length === 0;
}

/**
 * Error codes from the api mapped to error codes used in the validators.
 * Most of the error codes originate from libphonenumber.
 * https://github.com/halloverden/symfony-validator-constraints-bundle/blob/e6b7393982a5df98d77b47c0bb15640ebcfafbd5/Constraints/PhoneNumber.php#L38
 */
export enum PHONE_ERROR_MAPPING_API {
  INVALID_COUNTRY = 'invalid_country',
  INVALID_LENGTH = 'invalid_length',
  INVALID_TYPE = 'invalid_type',
  INVALID_PHONE_NUMBER = 'invalid_phone_number',
  NOT_A_NUMBER = 'not_a_number',
  NOT_UNIQUE_ERROR = 'duplicate_number',
  TOO_LONG = 'too_long',
  TOO_SHORT = 'too_short',
}

export enum PHONE_ERROR_MAPPING {
  CREATE_FAILED = 'create_failed',
  UPDATE_FAILED = 'update_failed',
  DUPLICATE_NUMBER = 'duplicate_number',
  INVALID = 'invalid',
  INVALID_COUNTRY = 'invalid_country',
  REQUIRED = 'required',
  UNSAVED_CHANGES = 'unsaved_changes',
}

export type PhoneErrorApi = keyof typeof PHONE_ERROR_MAPPING_API;
export type PhoneError = keyof typeof PHONE_ERROR_MAPPING;

@Injectable({ providedIn: 'root' })
export class PhoneValidator {
  constructor(private _phoneService: PhoneService) {}

  /**
   * Check if phone exists
   *
   * @param {Phone[]} userPhones
   * @returns {ValidatorFn}
   */
  static duplicateNumber(userPhones: Phone[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmptyInputValue(control.value)) {
        return null;
      }

      if (userPhones.map((obj) => obj.phoneNumber).indexOf(control.value) > -1) {
        return { [PHONE_ERROR_MAPPING.DUPLICATE_NUMBER]: true };
      }

      return null;
    };
  }

  /**
   * Check if the control has been saved to API (has uuid)
   *
   * @param {AbstractControl} control
   * @returns {ValidationErrors | null}
   */
  static unsaved(control: AbstractControl): ValidationErrors | null {
    // Void check if email not filled
    if (isEmptyInputValue(control.value) || !control.parent || !control.parent.get('uuid')) {
      return null;
    }

    const error: ValidationErrors = { [PHONE_ERROR_MAPPING.UNSAVED_CHANGES]: true };
    return control.parent.get('uuid').value && control.parent.get('uuid').value.length ? null : error;
  }

  validateNumber(phoneGroup: FormGroup<PhoneForm>): Observable<ValidationErrors | null> {
    return this._phoneService.checkValidPhone(`+${phoneGroup.value.countryCode}${phoneGroup.value.number}`).pipe(
      map((validation) => {
        if (!validation.valid) {
          return PhoneValidator.convertViolationsToValidationErrors(validation.violations);
        }
        return null;
      }),
      catchError(() => of(null)),
    );
  }

  static convertViolationsToValidationErrors(violations: ValidationViolationResponseEntity[]): ValidationErrors {
    return violations.reduce((validationErrors, violation) => {
      validationErrors[PHONE_ERROR_MAPPING_API[violation.errorName.toUpperCase()]] = true;
      return validationErrors;
    }, {});
  }
}
