var t = {
    d: (n, r) => {
      for (var e in r) t.o(r, e) && !t.o(n, e) && Object.defineProperty(n, e, {
        enumerable: !0,
        get: r[e]
      });
    },
    o: (t, n) => Object.prototype.hasOwnProperty.call(t, n)
  },
  n = {};
function r(t, n, r, e) {
  void 0 === r && (r = [0]), void 0 === e && (e = "-");
  var i = t.length;
  if (i !== n.length) throw new Error("A weight needs to be assigned to each digit");
  for (var a = 0, o = 0; o < i; o++) {
    if (!new RegExp("^\\d$").test(t[o])) throw new Error("All characters in the input string must be digits");
    a += parseInt(t[o], 10) * n[o];
  }
  var u = a % 11;
  return -1 !== r.indexOf(u) ? u.toString() : 1 === u ? e : (11 - u).toString();
}
function e(t, n, e) {
  void 0 === e && (e = !0);
  for (var a = [], o = n; o > 0; o--) {
    var u = r(t, i(t.length));
    if ("-" === u && e) throw new Error("A control digit was 1. Aborting...");
    a.push(u), t += u.toString();
  }
  return a;
}
function i(t) {
  for (var n = 2, r = [], e = 0; e < t; e++) r.push(n), n = n >= 7 ? 2 : n + 1;
  return r.reverse();
}
function a(t) {
  if (!/\d{11}|\d{4}\.\d{2}\.\d{5}/.test(t)) return !1;
  var n = i((t = t.toString().replace(/\./g, "")).length - 1);
  return r(t.substring(0, t.length - 1), n) === t.substring(t.length - 1, t.length);
}
t.d(n, {
  W: () => I,
  n_: () => N,
  mj: () => p,
  Db: () => y,
  MU: () => M,
  mV: () => P,
  Tv: () => T,
  YF: () => m,
  J6: () => b,
  j$: () => U,
  Sp: () => h,
  Br: () => d,
  et: () => i,
  px: () => s,
  AB: () => v,
  $h: () => Y,
  Zw: () => r,
  eV: () => e,
  Fl: () => l,
  FY: () => g,
  w9: () => B,
  uz: () => A,
  RF: () => E,
  kz: () => c,
  x0: () => f,
  sH: () => L,
  Ki: () => D,
  Gc: () => G,
  xE: () => H,
  Iz: () => F,
  F9: () => C,
  qv: () => u,
  Fz: () => o,
  RH: () => a,
  u2: () => R,
  iE: () => _,
  HX: () => S,
  cX: () => w
});
var o = [{
    indStart: 500,
    indEnd: 749,
    fromYear: 1854,
    toYear: 1899
  }, {
    indStart: 900,
    indEnd: 999,
    fromYear: 1940,
    toYear: 1999
  }, {
    indStart: 0,
    indEnd: 499,
    fromYear: 1900,
    toYear: 1999
  }, {
    indStart: 500,
    indEnd: 999,
    fromYear: 2e3,
    toYear: 2039
  }],
  u = [{
    indStart: 0,
    indEnd: 499,
    fromYear: 1854,
    toYear: 1999
  }, {
    indStart: 500,
    indEnd: 999,
    fromYear: 2e3,
    toYear: 2300
  }];
function s(t, n) {
  void 0 === n && (n = P.TYPE_REGULAR_NIN);
  for (var r = [], e = 0, i = n === P.TYPE_D_NUMBER ? u : o; e < i.length; e++) {
    var a = i[e];
    t >= a.fromYear && t <= a.toYear && r.push(a);
  }
  if (0 === r.length) throw new Error("No valid range for given year: " + t.toString());
  return r.map(function (t) {
    return {
      indStart: t.indStart,
      indEnd: t.indEnd
    };
  });
}
function g(t, n) {
  void 0 === n && (n = P.TYPE_REGULAR_NIN);
  for (var r = [], e = 0, i = n === P.TYPE_D_NUMBER ? u : o; e < i.length; e++) {
    var a = i[e];
    t >= a.indStart && t <= a.indEnd && r.push(a);
  }
  if (0 === r.length) throw new Error("No valid range for given individual number: " + t.toString());
  return r;
}
function f(t, n) {
  return void 0 === n && (n = P.TYPE_REGULAR_NIN), g(t, n).map(function (t) {
    return {
      fromYear: t.fromYear,
      toYear: t.toYear
    };
  });
}
function h(t) {
  var n = d(t),
    r = l(t),
    e = E(t.nin);
  if (!function (t, n, r) {
    var e = new Date(t, n - 1, r);
    return e.getFullYear() === t && e.getMonth() === n - 1 && e.getDate() === r;
  }(e, r, n)) throw new Error("Invalid date");
  var i = new Date(e, r - 1, n);
  return i.setHours(0), i.setMinutes(0), i.setSeconds(0), i.setMilliseconds(0), i;
}
function d(t) {
  var n = parseInt(t.nin.substring(0, 2), 10);
  return t.isDNumber() ? n - 40 : n;
}
function l(t) {
  var n = parseInt(t.nin.substring(2, 4), 10);
  return t.isHNumber() ? n - 40 : t.isNprSyntheticNin() ? n - 80 : n;
}
function c(t) {
  return E(t.nin);
}
function E(t) {
  var n = parseInt(t.substring(4, 6), 10),
    r = parseInt(t.substring(6, 9), 10);
  if (r >= 0 && r <= 499) return 1900 + n;
  if (r >= 500 && r <= 749 && n >= 54 && n <= 99) return 1800 + n;
  if (r >= 500 && r <= 999 && n >= 0 && n <= 39) return 2e3 + n;
  if (r >= 900 && r <= 999 && n >= 40 && n <= 99) return 1900 + n;
  throw new Error("Invalid nin");
}
var N = [3, 7, 6, 1, 8, 9, 4, 5, 2],
  p = i(10);
function _(t) {
  if (!F(t)) return !1;
  var n = t.substring(9, 10),
    e = t.substring(10, 11);
  if (r(t.substring(0, t.length - 2), N) !== n) return !1;
  if (r(t.substring(0, t.length - 1), p) !== e) return !1;
  for (var i = g(parseInt(t.substring(6, 9), 10)), a = s(E(t)), o = !1, u = function (t, n, r) {
      for (var e = 0, i = t; e < i.length; e++) {
        var a = i[e];
        if (a.indStart === n && a.indEnd === r) return !0;
      }
      return !1;
    }, f = 0, h = i; f < h.length; f++) {
    var d = h[f];
    u(a, d.indStart, d.indEnd) && (o = !0);
  }
  return o;
}
function v(t) {
  for (var n = 2, r = [], e = t.length - 1; e >= 0; e--) {
    for (var i = 0, a = (parseInt(t[e], 10) * n).toString(); i < a.length; i++) {
      var o = a[i];
      r.push(parseInt(o, 10));
    }
    n = n % 2 + 1;
  }
  var u = r.reduce(function (t, n) {
    return t + n;
  }, 0) % 10;
  return u ? 10 - u : u;
}
function Y(t, n) {
  for (var r = [], e = n; e > 0; e--) {
    var i = v(t);
    r.push(i), t += i.toString();
  }
  return r;
}
function m(t) {
  return v(t.substring(0, t.length - 1)) === parseInt(t.charAt(t.length - 1), 10);
}
function b(t, n) {
  for (var r = n - 1; r >= 0; r--) if (!m(t.substring(0, t.length - r))) return !1;
  return !0;
}
function R(t) {
  var n = t.charAt(t.length - 1),
    e = t.substring(0, t.length - 1),
    a = n === r(e, i(e.length)),
    o = parseInt(n, 10) === v(e);
  return a || o;
}
var P,
  T = i(8);
function S(t) {
  return !(!(t = t.replace(new RegExp(/ /g), "")) || 9 !== t.length) && t.charAt(t.length - 1) === r(t.substring(0, t.length - 1), T);
}
!function (t) {
  t.TYPE_REGULAR_NIN = "TYPE_REGULAR_NIN", t.TYPE_D_NUMBER = "TYPE_D_NUMBER", t.TYPE_H_NUMBER = "TYPE_H_NUMBER", t.TYPE_NPR_SYNTHETIC = "TYPE_NPR_SYNTHETIC";
}(P || (P = {}));
var I = [P.TYPE_REGULAR_NIN, P.TYPE_D_NUMBER, P.TYPE_H_NUMBER, P.TYPE_NPR_SYNTHETIC],
  y = function () {
    function t(t) {
      if (this._nin = t, !_(this._nin)) throw new Error("Invalid nin");
    }
    return Object.defineProperty(t.prototype, "type", {
      get: function () {
        return this.isRegularNin(), this._type;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t.prototype, "nin", {
      get: function () {
        return this._nin;
      },
      enumerable: !1,
      configurable: !0
    }), t.prototype.isRegularNin = function () {
      return !(this._type !== P.TYPE_REGULAR_NIN && (this.isDNumber() || this.isHNumber() || this.isNprSyntheticNin()) || (this._type = P.TYPE_REGULAR_NIN, 0));
    }, t.prototype.isDNumber = function () {
      return !(this._type !== P.TYPE_D_NUMBER && !L(this._nin) || (this._type = P.TYPE_D_NUMBER, 0));
    }, t.prototype.isHNumber = function () {
      return !(this._type !== P.TYPE_H_NUMBER && !G(this._nin) || (this._type = P.TYPE_H_NUMBER, 0));
    }, t.prototype.isNprSyntheticNin = function () {
      return !(this._type !== P.TYPE_NPR_SYNTHETIC && !C(this._nin) || (this._type = P.TYPE_NPR_SYNTHETIC, 0));
    }, t.prototype.isMaleNinPattern = function () {
      return H(this._nin);
    }, t.prototype.isFemaleNinPattern = function () {
      return D(this._nin);
    }, t;
  }(),
  M = function () {
    function t() {
      this._female = [], this._male = [];
    }
    return Object.defineProperty(t.prototype, "female", {
      get: function () {
        return this._female;
      },
      enumerable: !1,
      configurable: !0
    }), Object.defineProperty(t.prototype, "male", {
      get: function () {
        return this._male;
      },
      enumerable: !1,
      configurable: !0
    }), t.prototype.addFemale = function (t) {
      return this._female.includes(t) || this._female.push(t), this;
    }, t.prototype.addMale = function (t) {
      return this._male.includes(t) || this._male.push(t), this;
    }, t;
  }();
function w(t, n) {
  if (void 0 === n && (n = 2), t.length >= n) return t;
  for (; t.length < n;) t = "0" + t;
  return t.slice(-n);
}
function F(t) {
  return new RegExp(/^[0-9]{11}$/).test(t);
}
function D(t) {
  return !!F(t) && parseInt(t.substring(8, 9), 10) % 2 == 0;
}
function H(t) {
  return !!F(t) && parseInt(t.substring(8, 9), 10) % 2 != 0;
}
function U(t) {
  for (var n = new M(), e = s(t.getFullYear()), a = w(t.getDate().toString()) + w((t.getMonth() + 1).toString()) + t.getFullYear().toString().substring(2, 4), o = 0, u = e; o < u.length; o++) for (var g = u[o], f = g.indStart; f <= g.indEnd;) {
    var h = a + w(f.toString(), 3),
      d = r(h, N);
    if ("-" !== d) {
      var l = h + d,
        c = r(l, i(l.length));
      if ("-" !== c) {
        var E = l + c;
        _(E) && (D(E) ? n.addFemale(E) : H(E) && n.addMale(E)), f++;
      } else f++;
    } else f++;
  }
  return n;
}
function A(t, n) {
  return Math.floor(Math.random() * (n - t + 1) + t);
}
function B(t, n) {
  void 0 === n && (n = P.TYPE_REGULAR_NIN);
  var e,
    i = function (t, n) {
      return n === P.TYPE_D_NUMBER ? t + 40 : t;
    }(t.getDate(), n),
    a = function (t, n) {
      switch (n) {
        case P.TYPE_H_NUMBER:
          return t + 40;
        case P.TYPE_NPR_SYNTHETIC:
          return t + 80;
        default:
          return t;
      }
    }(t.getMonth() + 1, n),
    o = t.getFullYear(),
    u = s(o),
    g = u[A(0, u.length - 1)],
    f = "-",
    h = "-",
    d = "";
  do {
    var l = A(g.indStart, g.indEnd);
    "-" !== (f = r(d = w(i.toString()) + w(a.toString()) + w((e = o, parseInt(e.toString().substring(2, 4), 10)).toString()) + w(l.toString(), 3), N)) && (h = r(d + f, p));
  } while ("-" === f || "-" === h);
  return new y(d + f + h);
}
function L(t) {
  return !!F(t) && parseInt(t.charAt(0), 10) >= 4;
}
function G(t) {
  if (!F(t)) return !1;
  var n = parseInt(t.charAt(2), 10);
  return n >= 4 && n < 8;
}
function C(t) {
  return !!F(t) && parseInt(t.charAt(2), 10) >= 8;
}
var x = n.W,
  j = n.n_,
  z = n.mj,
  O = n.Db,
  W = n.MU,
  $ = n.mV,
  k = n.Tv,
  V = n.YF,
  X = n.J6,
  K = n.j$,
  q = n.Sp,
  J = n.Br,
  Z = n.et,
  Q = n.px,
  tt = n.AB,
  nt = n.$h,
  rt = n.Zw,
  et = n.eV,
  it = n.Fl,
  at = n.FY,
  ot = n.w9,
  ut = n.uz,
  st = n.RF,
  gt = n.kz,
  ft = n.x0,
  ht = n.sH,
  dt = n.Ki,
  lt = n.Gc,
  ct = n.xE,
  Et = n.Iz,
  Nt = n.F9,
  pt = n.qv,
  _t = n.Fz,
  vt = n.RH,
  Yt = n.u2,
  mt = n.iE,
  bt = n.HX,
  Rt = n.cX;
export { x as ALL_NIN_TYPES, j as C1_WEIGHTS, z as C2_WEIGHTS, O as NinEntity, W as NinList, $ as NinType, k as ORG_NUMBER_WEIGHTS, V as checkLuhnControlDigit, X as checkLuhnControlDigits, K as generateEveryNinForDate, q as getBirthDateFromNinEntity, J as getDayFromNinEntity, Z as getDefaultMod11WeightsForLength, Q as getIndRangesFromYear, tt as getLuhnControlDigit, nt as getLuhnControlDigits, rt as getMod11ControlDigit, et as getMod11ControlDigits, it as getMonthFromNinEntity, at as getNinIndividualNumberRangeMappingTypesFromInd, ot as getRandomNinForDate, ut as getRandomNumberFromRange, st as getYearFromNin, gt as getYearFromNinEntity, ft as getYearRangesFromInd, ht as isDNumberPattern, dt as isFemaleNinPattern, lt as isHNumberPattern, ct as isMaleNinPattern, Et as isNinPattern, Nt as isNprSyntheticNinPattern, pt as ninIndividualNumberDNumberMappings, _t as ninIndividualNumberMappings, vt as validateAccountNumber, Yt as validateKidNumber, mt as validateNin, bt as validateOrganizationNumber, Rt as zeroPadding };
