import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_ENTITY_SCHEMA } from '@core/entities/api/api-client-entity.schema';
import { ApiEntity } from '@core/entities/api/api.entity';
import { DeveloperEntity } from '@core/entities/api/developer.entity';
import { PageableApisEntity } from '@core/entities/api/pageable-apis.entity';
import { RevokeUrlEntity } from '@core/entities/api/revoke-url.entity';
import { ClientEntity } from '@core/entities/client/client.entity';
import { User } from '@core/entities/user/user.entity';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { deserialize, serialize } from 'serializr';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(private _http: HttpClient) {}

  addClient(api: ApiEntity, client: ClientEntity): Observable<ApiEntity> {
    return this._http.post(EnvironmentHelper.fetchAPIBase('v1/apis/' + api.uuid + '/clients/' + client.id), {}).pipe(
      map((response: { api: object }) => {
        return deserialize<ApiEntity>(API_ENTITY_SCHEMA, response.api);
      }),
    );
  }

  addDeveloper(api: ApiEntity, user: User): Observable<ApiEntity> {
    return this._http.post(EnvironmentHelper.fetchAPIBase('v1/apis/' + api.uuid + '/developers/' + user.uuid), {}).pipe(
      map((response: { api: object }) => {
        return deserialize<ApiEntity>(API_ENTITY_SCHEMA, response.api);
      }),
    );
  }

  deleteRevokeUrl(revokeId: string): Observable<RevokeUrlEntity> {
    return this._http.delete(EnvironmentHelper.fetchAPIBase('v1/api-revoke-urls/' + revokeId)).pipe(
      map((response: { apiRevokeUrl: object }) => {
        return deserialize(RevokeUrlEntity, response.apiRevokeUrl);
      }),
    );
  }

  deleteApi(api: ApiEntity): Observable<ApiEntity> {
    return this._http.delete(EnvironmentHelper.fetchAPIBase('v1/apis/' + api.uuid)).pipe(
      map((response: { api: object }) => {
        return deserialize(ApiEntity, response.api);
      }),
    );
  }

  getApi(apiId: string): Observable<ApiEntity> {
    return this._http.get(EnvironmentHelper.fetchAPIBase('v1/apis/' + apiId)).pipe(
      map((response: { api: object }) => {
        return deserialize<ApiEntity>(API_ENTITY_SCHEMA, response.api);
      }),
    );
  }

  getApis(paginationParams: PageableApisEntity): Observable<PageableApisEntity> {
    return this._http
      .get(EnvironmentHelper.fetchAPIBase('v1/apis'), {
        observe: 'response',
        params: paginationParams.apiRequestParams(),
      })
      .pipe(
        map((response: HttpResponse<{ apis: object[]; count: string }>) => {
          if (response.status === 204) {
            return new PageableApisEntity();
          } else {
            return deserialize(PageableApisEntity, response.body);
          }
        }),
      );
  }

  postRevokeUrl(apiId: string, url: RevokeUrlEntity): Observable<RevokeUrlEntity> {
    return this._http
      .post(EnvironmentHelper.fetchAPIBase('v1/api-revoke-urls'), {
        apiUuid: apiId,
        apiRevokeUrl: serialize(url),
      })
      .pipe(
        map((response: { apiRevokeUrl: object }) => {
          return deserialize(RevokeUrlEntity, response.apiRevokeUrl);
        }),
      );
  }

  postApi(api: ApiEntity): Observable<ApiEntity> {
    return this._http
      .post(EnvironmentHelper.fetchAPIBase('v1/apis'), {
        api: serialize(api),
      })
      .pipe(
        map((response: { api: object }) => {
          return deserialize<ApiEntity>(API_ENTITY_SCHEMA, response.api);
        }),
      );
  }

  putApi(apiId: string, api: ApiEntity): Observable<ApiEntity> {
    return this._http
      .put(EnvironmentHelper.fetchAPIBase('v1/apis/' + apiId), {
        api: serialize(api),
      })
      .pipe(
        map((response: { api: object }) => {
          return deserialize<ApiEntity>(API_ENTITY_SCHEMA, response.api);
        }),
      );
  }

  removeClient(api: ApiEntity, client: ClientEntity): Observable<ApiEntity> {
    return this._http.delete(EnvironmentHelper.fetchAPIBase('v1/apis/' + api.uuid + '/clients/' + client.id), {}).pipe(
      map((response: { api: object }) => {
        return deserialize<ApiEntity>(API_ENTITY_SCHEMA, response.api);
      }),
    );
  }

  removeDeveloper(api: ApiEntity, developer: DeveloperEntity): Observable<ApiEntity> {
    return this._http
      .delete(EnvironmentHelper.fetchAPIBase('v1/apis/' + api.uuid + '/developers/' + developer.uuid), {})
      .pipe(
        map((response: { api: object }) => {
          return deserialize<ApiEntity>(API_ENTITY_SCHEMA, response.api);
        }),
      );
  }
}
