import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ClientTestUserEntity } from "@core/entities/api/client-test-user.entity";
import { ClientEntity } from "@core/entities/client/client.entity";
import { ModalService } from "@core/services/modal/modal.service";
import { FfConfirmModalDataInterface } from "@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal-data.interface";
import { FfConfirmModalComponent } from "@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal.component";

@Component({
  selector: "app-test-user-list",
  templateUrl: "./test-user-list.component.html",
  styleUrls: ["./test-user-list.component.scss"],
})
export class TestUserListComponent implements OnChanges {
  @Input({ required: false })
  isAppAdmin: boolean = false;

  @Input({ required: false })
  isUserAdmin: boolean = false;

  @Input({ required: false })
  processing: boolean = false;

  @Input({ required: false })
  removeLabel: string = "Fjern tilgang";

  @Output()
  removed: EventEmitter<ClientTestUserEntity> = new EventEmitter<ClientTestUserEntity>();

  @Input({ required: true })
  users!: ClientTestUserEntity[];

  constructor(private _modalService: ModalService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["processing"] && !changes["processing"].isFirstChange()) {
      this.processing = !!changes["processing"].currentValue;
    }
  }

  confirmRemoval(usr: ClientTestUserEntity) {
    const confirmContent: FfConfirmModalDataInterface = {
      component: FfConfirmModalComponent,
      title: "Er du sikker?",
      body:
        "Vil du faktisk fjerne testbrukertilgangen for «" +
        usr.firstName +
        " " +
        usr.lastName +
        "»?",
      confirmCallback: () => {
        this.removed.emit(usr);
      },
    };

    this._modalService.openModal(confirmContent);
  }
}
