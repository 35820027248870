@if (showForm) {
  <form
    (keydown.enter)="$event.preventDefault()"
    [formGroup]="reactiveForm"
    class="form assign-collection-item well"
    ngNoForm
    >
    <h3>{{ textTitle }}</h3>
    <div class="subtitle">{{ textSubtitle }}</div>
    <ng-template #defaultTemplate let-active="active" let-index="index" let-item="item">
      <div
        [attr.data-index]="index"
        [class.active]="active"
        class="typeahead-result-item"
        title="{{ item.name }} ({{ item.description }})"
        >
        <div class="result-wrapper">
          <div class="name">{{ item.name }}</div>
          <div class="desc">{{ item.description }}</div>
        </div>
      </div>
    </ng-template>
    <app-ff-typeahead
      (filterChanged)="filterUpdate($event)"
      [focusInputOnLoad]="true"
      [formCtrl]="ctrl"
      [items$]="items$"
      [itemsSource]="'external'"
      [processing]="processing"
      [resultTemplate]="resultTemplate ? resultTemplate : defaultTemplate"
      class="collection-items"
    ></app-ff-typeahead>
    <div class="buttons">
      <app-ff-button
        (clicked)="showForm = false"
        class="inline padded"
        color="gray"
        label="{{ textCancel }}"
      ></app-ff-button>
    </div>
  </form>
}

<div class="buttons">
  @if (!showForm) {
    <app-ff-button
      (clicked)="showForm = true"
      class="add inline padded"
      label="{{ textAssign }}"
      [title]="textTitle"
    ></app-ff-button>
  }
</div>
