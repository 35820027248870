import { ProcessableInterface } from '@core/interfaces/processable.interface';
import { alias, identifier, serializable } from 'serializr';

export class ClientTestUserEntity implements ProcessableInterface {
  processing: boolean;

  @serializable(alias('firstName'))
  private _firstName: string;

  @serializable(alias('email'))
  private _email: string;

  @serializable(alias('lastName'))
  private _lastName: string;

  @serializable(alias('name'))
  private _name: string;

  @serializable(alias('phone'))
  private _phone: string;

  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  get firstName(): string {
    return this._firstName;
  }

  get email(): string {
    return this._email;
  }

  get lastName(): string {
    return this._lastName;
  }

  get name(): string {
    return this._name;
  }

  get phone(): string {
    return this._phone;
  }

  get uuid(): string {
    return this._uuid;
  }
}
