import { alias, date, identifier, serializable } from 'serializr';

export class ContactInformationEntity {
  @serializable(alias('createdAt', date()))
  private _createdAt: Date;

  @serializable(alias('email'))
  private _email: string;

  @serializable(alias('name'))
  private _name: string;

  @serializable(alias('phone'))
  private _phone: string;

  @serializable(alias('uuid', identifier()))
  private _id: string;

  get createdAt(): Date {
    return this._createdAt;
  }

  get id(): string {
    return this._id;
  }

  get email(): string {
    return this._email;
  }

  get name(): string {
    return this._name;
  }

  get phone(): string {
    return this._phone;
  }
}
