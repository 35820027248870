<label
  (keyup.enter)="toggle()"
  (keyup.space)="toggle()"
  [class.disabled]="disabled || formCtrl.disabled"
  [class.error]="validated && formCtrl.invalid"
  class="custom radio"
  tabindex="0"
  >
  @if (formCtrl) {
    <input [formControl]="formCtrl" [value]="value" tabindex="-1" type="radio" />
  }

  <svg-icon
    [applyClass]="true"
    [class.active]="formCtrl.value === value"
    [class.disabled]="disabled || formCtrl.disabled"
    [class.colored]="validated && formCtrl.invalid"
    class="icon radio"
    src="assets/icons/form_radio.svg"
    role="presentation"
  ></svg-icon>

  @if (labelText) {
    <div>
      {{ labelText }}
      @if (descriptionText) {
        <div class="description">{{ descriptionText }}</div>
      }
    </div>
  }
</label>

@if (helpText) {
  <app-ff-tooltip [text]="helpText" class="help">
    <svg-icon
      [applyClass]="true"
      class="icon"
      role="presentation"
      src="assets/icons/circled_question_mark_inverted_24.svg"
    ></svg-icon>
  </app-ff-tooltip>
}
