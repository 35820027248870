import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { ApiEntity } from "@core/entities/api/api.entity";
import { ModalService } from "@core/services/modal/modal.service";
import { FfConfirmModalDataInterface } from "@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal-data.interface";
import { FfConfirmModalComponent } from "@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal.component";

@Component({
  selector: "app-api-list",
  templateUrl: "./api-list.component.html",
  styleUrls: ["./api-list.component.scss"],
})
export class ApiListComponent implements OnChanges {
  @Input({ required: true })
  apis!: ApiEntity[];

  @Output()
  removed: EventEmitter<ApiEntity> = new EventEmitter<ApiEntity>();

  @Input({ required: false })
  isAppAdmin: boolean = false;

  @Input({ required: false })
  processing: boolean = false;

  @Input({ required: false })
  removeLabel: string = "Fjern";

  constructor(private _modalService: ModalService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["processing"] && !changes["processing"].isFirstChange()) {
      this.processing = !!changes["processing"].currentValue;
    }
  }

  confirmRemoval(api: ApiEntity) {
    const confirmContent: FfConfirmModalDataInterface = {
      component: FfConfirmModalComponent,
      title: "Er du sikker?",
      body: "Vil du faktisk fjerne API-et «" + api.name + "»?",
      confirmCallback: () => {
        this.removed.emit(api);
      },
    };

    this._modalService.openModal(confirmContent);
  }
}
