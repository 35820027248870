<div class="well--form-group">
  <label for="inputRef">E-post</label>
  <input
    #inputRef
    [class.submitted]="showErrors"
    (change)="fieldChanged()"
    (keydown.enter)="isExisting ? updateInvalidEmail(index) : createEmail(index)"
    (keydown.escape)="removeEmail()"
    [formControl]="emailCtrl"
    [readOnly]="processing ? true : null"
    autocomplete="email"
    data-cy="email-form-input"
    inputmode="email"
    placeholder="din@e-post.her"
    type="email"
    />

  @if (showErrors && emailCtrl.errors) {
    <div class="well__footer footer">
      @if (!processing) {
        <div aria-live="polite" class="footer__text">
          <span aria-live="assertive" class="error" role="alert">
            Feil:
            @if (emailCtrl.hasError('unsaved_changes') && (emailCtrl.errors | objToKeys).length === 1) {
              <span>
                E-postadresse må lagres.
              </span>
            }
            @if (emailCtrl.hasError('createFailed')) {
              <span> Lagring feilet. </span>
            }
            @if (emailCtrl.hasError('duplicate_email')) {
              <span> E-postadressen er allerede lagt til din konto. </span>
            }
            @if (emailCtrl.hasError('invalid')) {
              <span> Ikke en gyldig e-postadresse. </span>
            }
            @if (emailCtrl.hasError('minlength')) {
              <span> E-postadressen er for kort. </span>
            }
            @if (emailCtrl.hasError('required')) {
              <span> E-postadresse må fylles ut. </span>
            }
            @if (emailCtrl.hasError('update_failed')) {
              <span> Lagring av endringen feilet. </span>
            }
          </span>
        </div>
      }
    </div>
  }
  <div class="buttons">
    <app-ff-button (click)="isExisting ? updateInvalidEmail(index) : createEmail(index)" [processing]="processing">
      Lagre
    </app-ff-button>
    @if (!!user.emails.length || !!index) {
      <app-ff-button (click)="removeEmail()" class="medium" [color]="'secondary'">
        Avbryt
      </app-ff-button>
    }
  </div>
</div>
