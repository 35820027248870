import { Component, HostBinding, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-ff-radio',
  templateUrl: './ff-radio.component.html',
  styleUrls: ['./ff-radio.component.scss'],
})
export class FfRadioComponent {
  /**
   * Displays as text under label
   */
  @Input()
  descriptionText?: string;

  @Input()
  disabled?: boolean = false;

  @Input()
  formCtrl: UntypedFormControl;

  /**
   * Displays in a tooltip on question mark icon placed at end of label
   */
  @Input()
  helpText?: string;

  @Input()
  labelText?: string;

  @HostBinding('attr.role')
  role: string = 'radio';

  @Input()
  validated: boolean = false;

  @Input()
  value: string;

  form: UntypedFormGroup;

  /**
   * @param {UntypedFormBuilder} _fb
   */
  constructor(private _fb: UntypedFormBuilder) {}


  toggle() {
    if (!this.disabled) {
      this.formCtrl.setValue(this.value);
    }
  }
}
