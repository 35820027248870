<div class="ff-modal-backdrop">
  <div class="{{ hostClass }}" role="dialog">
    <div class="modal-host__content">
      @if (data.showCloseButton) {
        <app-ff-button
          (clicked)="close()"
          aria-label="Lukk"
          class="btn--close-modal"
          color="transparent"
          iconPath="icons/x.svg"
        ></app-ff-button>
      }

      @if (data.title) {
        <h1 class="modal-host__title">{{ data.title }}</h1>
      }

      <div id="modal-host__body">
        <ng-container id="modal-host__body__modal-ref" #modalBodyRef></ng-container>
      </div>
    </div>
  </div>
</div>
