import { alias, date, identifier, serializable } from 'serializr';

export class LoginProviderEntity {
  @serializable(alias('createdAt', date()))
  private _createdAt: Date;

  @serializable(alias('icon'))
  private _icon: string;

  @serializable(alias('name'))
  private _name: string;

  @serializable(alias('order'))
  private _order: number;

  @serializable(alias('type'))
  private _type: string;

  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get order(): number {
    return this._order;
  }

  set order(value: number) {
    this._order = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }
}
