import { UserVerificationSourceEntity } from '@core/entities/user/user-verification-source.entity';
import { UserVerificationEntity } from '@core/entities/user/user-verification.entity';

export class UserVerificationHelper {
  static readonly CODE_LOGIN_PROVIDER = 'LOGIN_PROVIDER';

  static getSourceString(verification: UserVerificationEntity | UserVerificationSourceEntity): string {
    return VerificationSourcesEnum[verification.type];
  }
}

export enum VerificationSourcesEnum {
  'BANKID' = 'BankID',
  'DRIVER_LICENSE' = 'Førerkort',
  'LOGIN_PROVIDER' = 'Innlogging',
  'NATIONAL_ID' = 'Nasjonal ID',
  'PASSPORT' = 'Pass',
}
