<header>
  <ng-content select=".header-prefix"></ng-content>

  <a class="brand" routerLink="/" tabindex="0" title="Fagforbundet ID">
    <app-svg [heightPx]="32" role="presentation" svgPath="brand/logo_id.svg" [widthPx]="32"></app-svg>
    <span class="name">ID</span>
  </a>

  <div class="search-container">
    <app-global-search class="global-search" [menuContext]="mainMenuContext"></app-global-search>
  </div>

  <div class="menu" role="menu">
    <div
      (click)="menuOpen = !menuOpen"
      (keydown.escape)="menuOpen = false"
      (keyup.enter)="menuOpen = !menuOpen"
      (mouseenter)="menuHover = true"
      (mouseleave)="menuHover = false"
      class="trigger"
      tabindex="0"
      >
      <app-svg
        [class.active]="menuHover"
        [svgPath]="menuOpen ? 'icons/x.svg' : 'icons/ellipsis.svg'"
        role="presentation"
      ></app-svg>
      <div>{{ menuOpen ? 'Lukk' : 'Meny' }}</div>
    </div>

    @if (menuOpen) {
      <app-main-menu (clicked)="menuClicked($event)" [menuContext]="mainMenuContext"></app-main-menu>
    }
  </div>

  <div class="user-container">
    <div class="user">
      <app-ff-menu-button
        [arrowPosition]="arrowPos"
        class="header-user-menu"
        triggerButton="icons/caret_down.svg"
        triggerButtonIconClass="inline padless"
        triggerButtonIconColor="transparent"
        [triggerButtonIconSize]="12"
        triggerIcon="icons/circled_user.svg"
        [triggerIconSize]="28"
        triggerText="{{ user.name }}"
        >
        <app-ff-menu-item
          iconPath="icons/person.svg"
          label="Brukerprofil"
          routerLink="/user/profile"
        ></app-ff-menu-item>

        @if (impersonating) {
          <app-ff-menu-item
            (clicked)="stopImpersonating()"
            iconPath="icons/person_disallow.svg"
            label="Logg ut av bruker"
          ></app-ff-menu-item>
        }

        <app-ff-menu-item iconPath="icons/power.svg" label="Logg ut" routerLink="/logout"></app-ff-menu-item>
      </app-ff-menu-button>
    </div>
  </div>
</header>
