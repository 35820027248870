import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PendingInformationUpdates } from '@core/entities/login/pending-information-updates.entity';
import { UserFeedbackEntity } from '@core/entities/user/user-feedback.entity';
import { User } from '@core/entities/user/user.entity';
import { SessionService } from '@core/services/user/session.service';
import { FfLoadingScreenService } from '@shared/components/ff-loading-screen/ff-loading-screen.service';

@Component({
  selector: 'app-set-user-password',
  templateUrl: './set-user-password.component.html',
  styleUrls: ['./set-user-password.component.scss'],
})
export class SetUserPasswordComponent implements OnInit {
  @Input()
  pendingInfoUpdates?: PendingInformationUpdates;

  /**
   * Callback to let user proceed to where they were originally headed
   */
  @Output()
  proceedCallback: EventEmitter<boolean> = new EventEmitter();

  @Input()
  user: User;

  feedbackMessages: Array<UserFeedbackEntity> = [];
  updatingPassword: boolean = false;

  constructor(private _loadingScreenService: FfLoadingScreenService, private _sessionService: SessionService) {}

  ngOnInit(): void {
    this.updatingPassword = !!(
      this.pendingInfoUpdates &&
      this.pendingInfoUpdates.password.required &&
      this.user.tfaActivated
    );
  }
}
