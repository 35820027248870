export class StringHelper {
  /**
   * Returns whether the first character of the given string is an upper cased character or not
   *
   * @param {string} str
   * @returns {boolean}
   */
  static firstCharIsUpper(str: string): boolean {
    if (!str) {
      return null;
    }

    const firstChar = str.charAt(0);
    return firstChar === firstChar.toLocaleUpperCase() && firstChar !== firstChar.toLocaleLowerCase();
  }

  /**
   * From MDN Web Docs, to better comply with RFC 3986.
   * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
   */
  static fixedEncodeURIComponent(str): string {
    return encodeURIComponent(str).replace(/[!'()*]/g, (c) => {
      return '%' + c.charCodeAt(0).toString(16).toUpperCase();
    });
  }

  static kebabCase(str: string): string {
    let result = str;

    // Convert camelCase capitals to kebab-case.
    result = result.replace(/([a-z][A-Z])/g, (match) => {
      return match.substr(0, 1) + '-' + match.substr(1, 1).toLowerCase();
    });

    // Convert non-camelCase capitals to lowercase.
    result = result.toLowerCase();

    // Convert non-alphanumeric characters to hyphens
    result = result.replace(/[^-a-z0-9]+/g, '-');

    // Remove hyphens from both ends
    result = result.replace(/^-+/, '').replace(/-$/, '');

    return result;
  }

  /**
   * Newlines as html line breaks
   *
   * @param {string} str
   * @returns {string}
   */
  static nl2br(str: string): string {
    return str.replace(/\r\n|\r|\n/g, '<br />');
  }

  /**
   * @param {string} str
   * @param {RegExp} remove Default: \w equals to [a-zA-Z0-9_], so strip anything not alphanumeric or underscore
   * @returns {string}
   */
  static sanitizeString(str: string, remove: RegExp = /[^\w]*/g): string {
    return str.replace(remove, '');
  }

  /**
   * @param {string} str
   * @returns {boolean}
   */
  static verifyOnlyIntegers(str: string): boolean {
    return RegExp(/^[0-9]+$/).test(str);
  }

  /**
   * Returns string with only first letter capitalized
   * @param string
   * @returns {string}
   */
  static ucFirst(string) {
    return !string ? '' : string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
}
