import { isPlatformBrowser } from "@angular/common";
import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Output,
  PLATFORM_ID,
} from "@angular/core";
import { WindowRefHelper } from "@core/helpers/window-ref.helper";
import { FfTooltipArrowPositions } from "@shared/components/ff-tooltip/ff-tooltip-arrow-positions.enum";
import { IFfTooltipOptions } from "@shared/components/ff-tooltip/ff-tooltip-options.interface";

/**
 * Shows an input field which has an icon with a tooltip
 */
@Component({
  selector: "app-ff-icon-input",
  templateUrl: "./ff-icon-input.component.html",
  styleUrls: ["./ff-icon-input.component.scss"],
})
export class FfIconInputComponent implements OnInit {
  @Output()
  clicked?: EventEmitter<any> = new EventEmitter<any>();

  @Input({ required: false })
  disabled: boolean = false;

  @Input({ required: false })
  iconClassList?: string = "";

  @Input({ required: false })
  iconPath?: string = "assets/icons/circled_question_mark.svg";

  @Input({ required: false })
  iconPosition?: "left" | "right" = "right";

  @HostBinding("class.processing")
  @Input({ required: false })
  processing: boolean = false;

  @HostBinding("tabindex")
  tabindex = "-1";

  @Input({ required: false })
  tooltipOptions?: IFfTooltipOptions = {
    arrowPosition: FfTooltipArrowPositions.bottomRight,
  } as IFfTooltipOptions;

  @Input({ required: false })
  tooltipText?: string;

  @Input()
  tooltipShow?: boolean = false;

  constructor(
    @Inject(PLATFORM_ID)
    private _platformId,
    @Inject(WindowRefHelper.WINDOW)
    private _window: Window
  ) {}

  @HostBinding("class.left")
  get left(): boolean {
    return this.iconPosition && this.iconPosition === "left";
  }

  @HostBinding("class.right")
  get right(): boolean {
    return !this.iconPosition || this.iconPosition === "right";
  }

  ngOnInit(): void {
    // Override on small screens to avoid tooltip bleeding outside visible area
    if (
      this.tooltipText &&
      this.tooltipOptions &&
      isPlatformBrowser(this._platformId)
    ) {
      if (
        this._window.innerWidth < 768 &&
        (!this.tooltipOptions.arrowPosition ||
          [
            FfTooltipArrowPositions.bottom,
            FfTooltipArrowPositions.left,
            FfTooltipArrowPositions.top,
          ].indexOf(this.tooltipOptions.arrowPosition))
      ) {
        this.tooltipOptions.arrowPosition = FfTooltipArrowPositions.bottomRight;
      }
    }
  }

  clickAction(): void {
    if (!this.disabled) {
      this.clicked.emit();
    }
  }

  setIconClasses(): { [key: string]: boolean } {
    return {
      icon: true,
      clickable: this.clicked.observers.length && !this.disabled,
      disabled: this.disabled,
      [this.iconPosition]: true,
      [this.iconClassList]: true,
    };
  }

  toggle(): void {
    this.tooltipShow = !this.tooltipShow;
    this.clickAction();
  }
}
