import { Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { PostalCodeLookupResponse } from '@core/entities/response/postal-lookup-response.entity';
import { StringHelper } from '@core/helpers/string.helper';
import { AddressLookupService } from '@core/services/address/address-lookup.service';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, switchMap, takeUntil, tap } from 'rxjs/operators';

@Directive({
  selector: '[appPostalLookup]',
})
export class PostalLookupDirective implements OnInit, OnDestroy {
  @Input()
  postalLookupCode?: string;

  @Input()
  postalLookupCountryCode?: string;

  @Output()
  postalLookupFinished: EventEmitter<PostalCodeLookupResponse> = new EventEmitter();

  private readonly _onDestroy$ = new Subject<void>();

  private _postalCodeChange$ = new Subject();
  private _postalLookupCityInput: ElementRef<HTMLInputElement>;

  constructor(
    private _addressLookupService: AddressLookupService,
    private _postalCodeInput: ElementRef<HTMLInputElement>,
    private _renderer: Renderer2,
  ) {}

  @Input()
  set postalLookupCityInput(value: HTMLInputElement) {
    this._postalLookupCityInput = new ElementRef(value);
  }

  ngOnInit(): void {
    this._addEventListeners();
  }

  ngOnDestroy(): void {
    this._postalCodeChange$.complete();
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  private _addEventListeners() {
    this._postalCodeInput.nativeElement.addEventListener('keyup', (data) => {
      if (data instanceof KeyboardEvent) {
        const key = data.key;

        // Early return if pressed key was arrow or letter, aka. search only if valid postal code
        if (!StringHelper.verifyOnlyIntegers(key.toString()) && key !== 'Backspace' && key !== 'Delete') {
          return;
        }
      }

      this._postalCodeChange$.next(this._postalCodeInput.nativeElement.value);
    });

    this._postalCodeChange$
      .pipe(
        debounceTime(300),
        switchMap((value: string) => {
          return this._getPostalArea(value);
        }),
        tap((postalCode: PostalCodeLookupResponse) => this._lookupSuccess(postalCode)),
        takeUntil(this._onDestroy$),
        catchError((e) => {
          this._lookupError();
          return throwError(() => e);
        }),
      )
      .subscribe();
  }

  private _createResponse(valid: boolean): PostalCodeLookupResponse {
    const lookupResponse = new PostalCodeLookupResponse();
    lookupResponse.valid = valid;

    if (this.postalLookupCode) {
      lookupResponse.postalCodeLookupId = this.postalLookupCode;
    }

    return lookupResponse;
  }

  /**
   * Fetches postalArea name for given postalCode code
   */
  private _getPostalArea(postalCode: string): Observable<PostalCodeLookupResponse> {
    this._updateCityField();

    const domestic = !this.postalLookupCountryCode || this.postalLookupCountryCode.toUpperCase() === 'NO';

    // Only run lookup if domestic and at least 4 integers
    if (domestic && postalCode?.length > 3 && StringHelper.verifyOnlyIntegers(postalCode)) {
      return this._addressLookupService.getPostalInfoByPostalCode(postalCode);
    } else {
      this._updateCityField(false);
      return of(this._createResponse(false));
    }
  }

  private _lookupError() {
    this._updateCityField(false);
    this.postalLookupFinished.emit(this._createResponse(false));
  }

  /**
   * Callback for success result of this.getPostalArea()
   */
  private _lookupSuccess(postalCode: PostalCodeLookupResponse) {
    if (postalCode.valid) {
      this._updateCityField(true, postalCode.postalArea);
    } else {
      this._updateCityField(false);
    }

    if (this.postalLookupCode) {
      postalCode.postalCodeLookupId = this.postalLookupCode;
    }

    this.postalLookupFinished.emit(postalCode);
  }

  private _updateCityField(readOnly: boolean = true, cityValue?: string): void {
    if (readOnly && cityValue) {
      if (this._postalLookupCityInput && this._postalLookupCityInput.nativeElement) {
        this._renderer.setProperty(this._postalLookupCityInput.nativeElement, 'value', cityValue);
        this._renderer.setProperty(this._postalLookupCityInput.nativeElement, 'readOnly', true);
      }
    } else {
      if (this._postalLookupCityInput && this._postalLookupCityInput.nativeElement) {
        this._renderer.setProperty(this._postalLookupCityInput.nativeElement, 'value', '');
        this._renderer.setProperty(this._postalLookupCityInput.nativeElement, 'readOnly', null);
      }
    }
  }
}
