import { Injectable } from '@angular/core';
import { Country } from '@core/entities/user/country.entity';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AddressesStoreService {
  private readonly _countries$: BehaviorSubject<ReadonlyArray<Country>> = new BehaviorSubject<ReadonlyArray<Country>>(
    null,
  );

  countries$ = this._countries$.asObservable();

  /**
   * @returns {ReadonlyArray<Country> | null}
   */
  get countries(): ReadonlyArray<Country | null> {
    return this._countries$.value;
  }

  /**
   * @param {ReadonlyArray<Country | null>} countries
   */
  set countries(countries: ReadonlyArray<Country | null>) {
    this._countries$.next(countries);
  }
}
