import { User } from '@core/entities/user/user.entity';
import { RolesEnum } from '@core/enums/roles.enum';
import { UserInterface } from '@core/entity-interfaces/user/user.interface';

/**
 *
 */
export class RoleHelper {
  /**
   * @param {UserInterface} user
   * @returns {boolean}
   */
  static isAnyAdmin(user: UserInterface): boolean {
    return (
      RoleHelper.hasRole(user, RolesEnum.ROLE_APP_ADMIN) ||
      RoleHelper.hasRole(user, RolesEnum.ROLE_SYSTEM_ADMIN) ||
      RoleHelper.hasRole(user, RolesEnum.ROLE_USER_ADMIN)
    );
  }

  /**
   * @param {User | UserInterface} user
   * @returns {boolean}
   */
  static isAppAdmin(user: User | UserInterface): boolean {
    return RoleHelper.hasRole(user, RolesEnum.ROLE_APP_ADMIN);
  }

  /**
   * @param {User | UserInterface} user
   * @returns {boolean}
   */
  static isSysAdmin(user: User | UserInterface): boolean {
    return RoleHelper.hasRole(user, RolesEnum.ROLE_SYSTEM_ADMIN);
  }

  /**
   * @param {User | UserInterface} user
   * @returns {boolean}
   */
  static isUserAdmin(user: User | UserInterface): boolean {
    return RoleHelper.hasRole(user, RolesEnum.ROLE_USER_ADMIN);
  }

  /**
   * @param user
   * @param role
   */
  static hasRole(user: User | UserInterface, role: RolesEnum): boolean {
    for (const r of user.roles) {
      if (r.role === role) {
        return true;
      }
    }

    return false;
  }
}
