import { ProcessableInterface } from '@core/interfaces/processable.interface';
import { alias, date, identifier, serializable } from 'serializr';

export class UserVerificationEntity implements ProcessableInterface {
  @serializable(alias('createdAt', date()))
  private readonly _createdAt: Date;

  @serializable(alias('lastVerifiedAt', date()))
  private readonly _lastVerifiedAt: Date;

  @serializable(alias('manual'))
  private _manual: boolean;

  @serializable(alias('type'))
  private _type: string;

  @serializable(alias('uuid', identifier()))
  private readonly _uuid: string;

  processing: boolean;

  get createdAt(): Date {
    return this._createdAt;
  }

  get lastVerifiedAt(): Date {
    return this._lastVerifiedAt;
  }

  get type(): string {
    return this._type;
  }

  get manual(): boolean {
    return this._manual;
  }

  get uuid(): string {
    return this._uuid;
  }
}
