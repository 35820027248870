@if (!!index) {
  <h2>Addresse {{ index + 1 }}</h2>
}

<div class="well--form-group" role="group">
  <div class="form-group">
    <div class="form-control form-control--address">
      <label for="line1_{{ index }}">Gatenavn og nummer</label>
      <input
        [formControl]="controls.line1"
        [readOnly]="processing ? true : null"
        autocomplete="address-line1"
        id="line1_{{ index }}"
        type="text"
        />
    </div>

    <div class="form-control form-control--address">
      <label for="line2_{{ index }}">Adresselinje 2 (valgfri)</label>
      <input
        (input)="line3Check()"
        [formControl]="controls.line2"
        [readOnly]="processing ? true : null"
        autocomplete="address-line2"
        id="line2_{{ index }}"
        type="text"
        />
    </div>

    @if (controls.line2.value && controls.line2.value.length) {
      <div class="form-control form-control--address">
        <label for="line3_{{ index }}">Adresselinje 3 (valgfri)</label>
        <input
          [formControl]="controls.line3"
          [readOnly]="processing ? true : null"
          autocomplete="address-line3"
          id="line3_{{ index }}"
          type="text"
          />
      </div>
    }
  </div>

  <div class="form-group form-group--postal" role="group">
    <div class="form-group form-group--dual form-group--zip">
      <div class="form-control form-control--postal">
        <label for="zip_{{ index }}">Postnr</label>
        <input
          (postalLookupFinished)="postalLookupCallback($event)"
          [formControl]="controls.postalCode"
          [postalLookupCountryCode]="controls.countryCode?.value"
          [postalLookupCode]="controls.uuid.value"
          [postalLookupCityInput]="cityRef"
          [readOnly]="processing ? true : null"
          appPostalLookup
          autocomplete="postal-code address-level2"
          class="zip"
          id="zip_{{ index }}"
          inputmode="tel"
          type="text"
          />
      </div>
    </div>

    <div class="form-group form-group--dual form-group--area">
      <div class="form-control">
        <label for="postal-area_{{ index }}"> Poststed </label>
        <input
          #cityRef
          [formControl]="controls.postalArea"
          [readOnly]="processing ? true : null"
          autocomplete="address-level2"
          class="area"
          id="postal-area_{{ index }}"
          type="text"
          />
      </div>
    </div>
  </div>

  <ng-template #countryResultTmpl let-active="active" let-index="index" let-item="item">
    <div
      [attr.data-index]="index"
      [class.active]="active"
      class="typeahead-result-item"
      title="{{ item.name }} ({{ item.code }})"
      >
      <div class="result-wrapper">
        <div class="name">{{ item.name }}</div>
        <div class="code">{{ item.code }}</div>
      </div>
    </div>
  </ng-template>

  <div class="form-group">
    <div class="form-control">
      <label [for]="'country_' + index">Land</label>
      <app-ff-typeahead
        [formCtrl]="controls.countryObject"
        [inputHtmlId]="'country_' + index"
        [items$]="countries$"
        [itemsSource]="'local'"
        [resultTemplate]="countryResultTmpl"
      ></app-ff-typeahead>
    </div>
  </div>
</div>
