import { alias, serializable } from 'serializr';

export class Country {
  @serializable(alias('name'))
  private _name: string;
  @serializable(alias('code'))
  private _code: string;

  get name(): string {
    return this._name;
  }

  get code(): string {
    return this._code;
  }
}
