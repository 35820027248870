<ng-content class="input" select="input"></ng-content>

<div class="buttons">
  @if (exclude !== 'accept') {
    <app-ff-button
      (clicked)="accept()"
      [iconSize]="20"
      [processing]="processing"
      [title]="labelAccept"
      class="accept"
      color="sand"
      data-cy="input-accept"
      iconPath="icons/check_mark.svg"
      tabindex="0"
    ></app-ff-button>
  }

  @if (exclude !== 'cancel') {
    <app-ff-button
      (clicked)="cancel()"
      [iconSize]="20"
      [processing]="processing"
      [title]="labelCancel"
      class="cancel"
      color="beige"
      data-cy="input-cancel"
      iconPath="icons/x.svg"
      tabindex="0"
    ></app-ff-button>
  }
</div>
