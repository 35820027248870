<div [class.done]="done" [class.error]="error" class="loading-indicator-component {{ size ? size : '' }}">
  <div class="container">
    <svg
      class="spinner-wrapper"
      shape-rendering="geometricPrecision"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:svg="http://www.w3.org/2000/svg"
    >
      <svg:g class="circle-container">
        <circle cx="20" cy="20" r="20" class="circle-path" [attr.stroke]="stroke"></circle>
      </svg:g>

      <svg:g class="icon-container">
        <svg:path d="M11 20 l5.68 5.76 13.36-13.44" class="check-mark" fill="none" />
        <svg:path d="M10 10 L30 30 M10 30 L30 10" class="error-mark" fill="none" />
      </svg:g>
    </svg>
  </div>
</div>
