import { HttpParams } from '@angular/common/http';
import { ClientEntity } from '@core/entities/client/client.entity';
import { PaginationQueryParamsEntity } from '@core/entities/query-params/pagination-query-params.entity';
import { ClientTypesEnum } from '@core/enums/client-types.enum';
import { alias, list, object, serializable } from 'serializr';

export class PageableClientsEntity extends PaginationQueryParamsEntity {
  @serializable(alias('clients', list(object(ClientEntity))))
  private _clients: ClientEntity[] = [];

  /**
   * Whether to constrain getClients to the ones the user has been given explicit access to.
   * For an admin this may narrow results.
   * For others: Not supplying this as false can throw a forbidden exception.
   * @type {boolean}
   * @private
   */
  private _onlyAccessible: boolean;

  /**
   * Will constrain getClients search to this client type
   * @type {ClientTypesEnum}
   * @private
   */
  @serializable(alias('type'))
  private _type?: ClientTypesEnum;

  /**
   * Extend super to append the onlyAccessible property
   * @returns {HttpParams}
   */
  override apiRequestParams(): HttpParams {
    let params = super.apiRequestParams();

    if (this.onlyAccessible !== undefined) {
      params = params.set('only-accessible', this.onlyAccessible.toString());
    }

    if (this.type !== undefined) {
      params = params.set('type', this.type.toString());
    }

    return params;
  }

  get clients(): ClientEntity[] {
    return this._clients;
  }

  set clients(value: ClientEntity[]) {
    this._clients = value;
  }

  get onlyAccessible(): boolean {
    return this._onlyAccessible;
  }

  set onlyAccessible(value: boolean) {
    this._onlyAccessible = value;
  }

  get type(): ClientTypesEnum {
    return this._type;
  }

  set type(value: ClientTypesEnum) {
    this._type = value;
  }
}
