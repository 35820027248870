import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { SaveUserRequestEntity } from '@core/entities/request/save-user-request.entity';
import { Address } from '@core/entities/user/address.entity';
import { UserFeedbackEntity } from '@core/entities/user/user-feedback.entity';
import { User } from '@core/entities/user/user.entity';
import { UserFeedbackTypesEnum } from '@core/enums/user-feedback-types.enum';
import { ElementRefHelper } from '@core/helpers/element-ref.helper';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';
import { ProcessableInterface } from '@core/interfaces/processable.interface';
import { LogService } from '@core/services/log/log.service';
import { AddressService } from '@core/services/user/address.service';
import { UserService } from '@core/services/user/user.service';
import { UserFormControlsEnum } from '@shared/components/user-form/user-form-controls.enum';
import { UserFormDisabledSectionsInterface } from '@shared/components/user-form/user-form-disabled-sections.interface';
import { UserFormOptionalSectionsInterface } from '@shared/components/user-form/user-form-optional-sections.interface';
import { forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { deserialize, serialize } from 'serializr';
import { UserFormBuilder } from './user-form.factory';
import { UserForm } from './types/user-form.type';

/**
 * Result class emitted as result
 */
export class UserFormResultEntity {
  created: boolean;
  success: boolean;
  user: User;

  constructor(success: boolean, created = false, user: User = null) {
    this.created = created;
    this.success = success;
    this.user = user;
  }
}

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnChanges, OnDestroy, OnInit, ProcessableInterface {
  @Input()
  disabledSections?: UserFormDisabledSectionsInterface;

  @Input()
  feedbackMessages?: Array<UserFeedbackEntity> = [];

  @ViewChild('formRef', { static: false })
  formRef;

  @Input()
  includeEmployment = false;

  @Input()
  isAdminContext = false;

  @Input()
  includeMasterSubmitButton = true;

  @Input()
  optionalSections?: UserFormOptionalSectionsInterface;

  @Input()
  saveButtonLabel?: string;

  @Input()
  selfUser?: User | undefined; // The user who is currently logged in

  @Output()
  submissionCallback: EventEmitter<UserFormResultEntity> = new EventEmitter();

  @Input()
  user: User;

  @Output()
  validationCallback: EventEmitter<UserFormResultEntity> = new EventEmitter();

  @Input()
  validationTrigger?: Observable<boolean> = new Observable<boolean>();

  private readonly _onDestroy$ = new Subject<void>();

  processing = false;
  submitted = false;
  userForm: FormGroup<UserForm>;
  userMessages: Array<UserFeedbackEntity> = [];

  private _messagesRef: ElementRef;

  constructor(
    private _addressService: AddressService,
    private _logService: LogService,
    private _userService: UserService,
    private _ufb: UserFormBuilder,
  ) {}

  @ViewChild('feedbackMessagesRef', { read: ElementRef, static: false })
  set messagesRef(elRef: ElementRef) {
    this._messagesRef = elRef;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['feedbackMessages']) {
      this.userMessages = Object.assign(Array.from(this.userMessages), changes['feedbackMessages'].currentValue);
    }

    if (changes['user'] && !changes['user'].firstChange) {
      this.user = changes['user'].currentValue;
      this._resetUserForm();
    }
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit(): void {
    if (!this.saveButtonLabel) {
      if (!this.user || !this.user.uuid) {
        this.saveButtonLabel = 'Opprett ny bruker';
      } else {
        this.saveButtonLabel = 'Oppdater informasjon';
      }
    }

    this._resetUserForm();

    // Subscribe to validation trigger
    this.validationTrigger.pipe(takeUntil(this._onDestroy$)).subscribe((validate: boolean) => {
      if (validate === true) {
        this.submitted = true;
        this.validateForm(true);
      }
    });
  }

  /**
   * Validate and go to next step
   */
  save(): void {
    if (this.processing) {
      return;
    }

    this.userMessages = [];
    this.submitted = true;

    if (this.userForm.invalid) {
      this.validateForm();
      return;
    }

    this.processing = true;

    forkJoin(this._getUserSaveRequests())
      .pipe(
        takeUntil(this._onDestroy$),
        map(([user]: [User]) => {
          return user;
        }),
        tap((savedUser: User | null) => {
          this.submitted = false;

          const userFormResult = new UserFormResultEntity(true);
          if (!this.user.uuid) {
            userFormResult.created = true;
          }

          if (savedUser !== null) {
            this.user = savedUser;
          }

          userFormResult.user = this.user;

          this.userMessages = [
            new UserFeedbackEntity(
              !this.user.uuid ? 'Bruker opprettet' : 'Informasjonen er oppdatert',
              UserFeedbackTypesEnum.INFORMATIVE,
            ),
          ];

          if (this.userMessages.length > 0) {
            ElementRefHelper.scrollTo(this._messagesRef);
          }

          this._userService.syncUser(this.user).subscribe();
          this.submissionCallback.emit(userFormResult);
        }),
        catchError((error) => {
          this.submissionCallback.emit(new UserFormResultEntity(false));
          return throwError(() => error);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  /**
   * Gather any error messages
   */
  validateForm(triggeredExternally: boolean = false, scrollToErrors = true): void {
    if (!this.submitted) {
      return;
    }

    this.userMessages = ReactiveFormsHelper.getErrorStrings(this.userForm, UserFormErrorsEnum, this._logService);

    const result = this.userMessages.length === 0;

    if (triggeredExternally) {
      const response = new UserFormResultEntity(result);
      this.validationCallback.emit(response);
    }

    if (scrollToErrors && this.userMessages.length > 0) {
      ElementRefHelper.scrollTo(this._messagesRef);
    }
  }

  private _resetUserForm() {
    this.userForm = this._ufb.UserForm(this.user, {
      includeEmployment: this.includeEmployment,
      requireAddress: !this.optionalSections?.postalAdresses,
      requireName: !this.optionalSections?.names,
    });

    if (this.disabledSections) {
      Object.keys(this.disabledSections).forEach((key) => {
        if (key === 'names' || key === 'postalAddresses') {
          this.userForm.controls.nameAndAdresses.get(UserFormControlsEnum[key]).disable();
        } else if (this.disabledSections[key]) {
          this.userForm.get(UserFormControlsEnum[key]).disable();
        }
      });
    }
  }

  private _getEnabledRoles(enabledRolesUuids: Array<string>, rolesForm: FormArray) {
    rolesForm.controls.forEach((role: FormControl) => {
      if (role.get('isChecked').value) {
        enabledRolesUuids.push(role.get('uuid').value);
      } else {
        const children = role.get('children') as FormArray;
        this._getEnabledRoles(enabledRolesUuids, children);
      }
    });
  }

  private _getPutUserRequests(): Array<Observable<any>> {
    const requests: Array<Observable<any>> = [];

    const adrForm = this.userForm.controls.nameAndAdresses.controls.postalAddresses;
    const rolesForm = this.userForm.controls.roles;

    if (adrForm) {
      adrForm.controls.forEach((adrGroup, idx) => {
        // Enough address details to make a save request?
        if (adrGroup.controls.line1.value && adrGroup.controls.postalArea.value && adrGroup.controls.postalCode.value) {
          const adrData = deserialize(Address, adrGroup.value);
          if (this.user.postalAddresses.length) {
            requests.push(
              this._addressService.putAddress(this.user.uuid, this.user.postalAddresses[idx].uuid, adrData),
            );
          } else if (adrForm.dirty) {
            requests.push(this._addressService.postAddress(this.user.uuid, adrData));
          }
        }
      });
    }

    if (rolesForm && rolesForm.value.length > 0) {
      const enabledRolesUuids: Array<string> = [];
      this._getEnabledRoles(enabledRolesUuids, rolesForm);
      requests.push(this._userService.putRoles(this.user.uuid, enabledRolesUuids));
    }

    return requests;
  }

  private _getUserSaveRequests(): Array<Observable<any>> {
    const requests: Array<Observable<any>> = [];

    let testUserVal: boolean = !!this.user.isTestUser;
    if (typeof this.userForm.value.isTestUser === 'boolean') {
      testUserVal = this.userForm.value.isTestUser;
    }

    const usrReq = deserialize(
      User,
      Object.assign(
        serialize(this.user),
        this.userForm.controls.nameAndAdresses.controls.names.value,
        { [UserFormControlsEnum.nin]: this.userForm.controls.nin.value || this.user.nin },
        { [UserFormControlsEnum.testUser]: testUserVal },
        this.userForm.controls.employment.value,
      ),
    );

    // If existing user, also add sub collections
    if (this.user.uuid) {
      if (this.isAdminContext) {
        requests.push(this._userService.putUser(this.user.uuid, usrReq, true));
      } else {
        requests.push(this._userService.putSelf(usrReq));
      }
      requests.push(...this._getPutUserRequests());
    } else {
      requests.push(this._userService.postUser(usrReq));
    }

    return requests;
  }
}

export enum UserFormErrorsEnum {
  ACCOUNTSTATUS_REASON_REQUIRED = 'Begrunnelse for statusendringen må angis',
  ADDRESSES_COUNTRY_REQUIRED = 'Land må velges',
  ADDRESSES_LINE1_REQUIRED = 'Adresse må angis',
  ADDRESSES_POSTALAREA_REQUIRED = 'Poststed må angis',
  ADDRESSES_POSTALCODE_MINLENGTH = 'Postnummeret er for kort',
  ADDRESSES_POSTALCODE_REQUIRED = 'Postnummer må angis',
  ADDRESSES_CITY_REQUIRED = 'Poststed må angis',
  EMAILS_EMAIL_UNSAVED_CHANGES = 'E-post må lagres først',
  EMAILS_EMAIL_REQUIRED = 'E-post må angis',
  NAMES_FIRSTNAME_REQUIRED = 'Fornavn må angis',
  NAMES_LASTNAME_REQUIRED = 'Etternavn må angis',
  PHONES_NUMBER_UNSAVED_CHANGES = 'Telefonnummeret må lagres',
  SSN_API_INVALID_NIN = 'Det angitte nummeret er ikke et gyldig norsk 11-sifret fødselsnummer',
  SSN_REQUIRED = 'Fødselsnummer må angis',
  SSN_SYNTAX_INVALID = 'Det angitte nummeret er ikke et gyldig norsk 11-sifret fødselsnummer',
  SSN_TAKEN = 'Fødselsnummeret er i bruk av en annen bruker',
  EMPLOYMENT_BANKACCOUNT_INVALIDBANKACCOUNT = 'Ugyldig kontonummer',
}
