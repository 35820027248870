<h2>Type</h2>

<div class="type-form" role="group">
  <label
    (keyup.enter)="isTestUserFormCtrl.setValue(false)"
    [class.selected]="!isTestUserFormCtrl.value"
    class="form-control custom radio has-icon"
    tabindex="0"
  >
    <svg-icon
      [applyClass]="true"
      [class.active]="!isTestUserFormCtrl.value"
      [class.badge]="!isTestUserFormCtrl.value"
      class="icon user"
      role="presentation"
      src="assets/icons/circled_user_40_check.svg"
    ></svg-icon>
    <input [formControl]="isTestUserFormCtrl" [value]="false" name="isTestUser" tabindex="-1" type="radio" />
    <span class="name">Ordinær bruker</span>
    <span class="desc">Vanlig brukerkonto</span>
  </label>

  <label
    (keyup.enter)="isTestUserFormCtrl.setValue(true)"
    [class.selected]="isTestUserFormCtrl.value"
    class="form-control custom radio has-icon"
    tabindex="0"
  >
    <svg-icon
      [applyClass]="true"
      [class.active]="isTestUserFormCtrl.value"
      [class.badge]="isTestUserFormCtrl.value"
      class="icon cog"
      role="presentation"
      src="assets/icons/cog_40_check.svg"
    ></svg-icon>
    <input [formControl]="isTestUserFormCtrl" [value]="true" name="isTestUser" tabindex="-1" type="radio" />
    <span class="name">Testbruker</span>
    <span class="desc">Brukerkonto for testing</span>
  </label>
</div>
