import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { EnvironmentType } from '@environments/assets/environment-type';
import { AvailableApiName } from '@environments/assets/available-api-name.type';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentHelper {
  static getLogoutUrl(): string {
    return environment.logoutUrl;
  }

  static isAPIRequest(url: string): boolean {
    let validatedUrl;

    try {
      validatedUrl = new URL(url).origin;
    } catch (e) {
      return false;
    }

    // TODO: Have fetchAPIBase not have a trailing slash
    // TODO: Updating means we have to update all service methods
    return EnvironmentHelper.fetchAPIBase('') === validatedUrl + '/';
  }

  static isDevelopment(): boolean {
    return environment.type === EnvironmentType.DEVELOPMENT;
  }

  static isBuild(): boolean {
    return environment.type === EnvironmentType.BUILD;
  }

  /**
   * Generates URL for a given path to the API (based on config)
   */
  static fetchAPIBase(path: string, api: AvailableApiName = 'id'): string {
    let url = environment.apis[api].baseUrl;

    url += `/${path}`;

    return url;
  }

  /**
   * 1. Remove API base
   * 2. Prefix local folder base path (assets/mocks/api/)
   * 3. Postfix method name (get/post/etc.)
   * 4. Postfix file extension (.json)
   * 5. Return string. Ex: assets/mocks/api/users/self.get.json
   */
  static replaceWithMockUrl(url: string, method: string): string {
    return (
      'assets/mocks/api/' + url.replace(EnvironmentHelper.fetchAPIBase(''), '') + '.' + method.toLowerCase() + '.json'
    );
  }

  static fetchFrontEndBase(): string {
    let url = environment.frontEnd.protocol + '://' + environment.frontEnd.host;

    if (null !== environment.frontEnd.port) {
      url += ':' + environment.frontEnd.port;
    }

    return url;
  }

  /**
   * Generates URL for a given path to the front end (based on config)
   *
   */
  static fetchFrontEndURL(path: string): string {
    let url = this.fetchFrontEndBase();
    url += path;

    return url;
  }

  static useMocks(): boolean {
    return environment.useMocks;
  }
}
