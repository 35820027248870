import { Pipe, PipeTransform } from '@angular/core';
import { StringHelper } from '@core/helpers/string.helper';

@Pipe({
  name: 'nl2br',
})
export class Nl2brPipe implements PipeTransform {
  transform(nlString: string): string {
    return StringHelper.nl2br(nlString);
  }
}
