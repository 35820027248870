import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-ff-accept-cancel-input',
  templateUrl: './ff-accept-cancel-input.component.html',
  styleUrls: ['./ff-accept-cancel-input.component.scss'],
})
export class FfAcceptCancelInputComponent implements OnChanges {
  @Input()
  exclude?: 'accept' | 'cancel' = null;

  @Input()
  labelAccept = 'Lagre';

  @Input()
  labelCancel = 'Avbryt';

  @Input()
  processing?: boolean = false;

  @Output()
  accepted: EventEmitter<boolean> = new EventEmitter();

  @Output()
  cancelled: EventEmitter<boolean> = new EventEmitter();

  accept() {
    this.accepted.emit(true);
  }

  cancel() {
    this.cancelled.emit(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['processing']) {
      this.processing = changes['processing'].currentValue;
    }
  }
}
