import { Phone } from '@core/entities/user/phone.entity';
import { ArrayHelper } from '@core/helpers/array.helper';

/**
 *
 */
export class PhoneHelper {
  /**
   *
   * @param phones
   * @param deletedUuid
   */
  static removePhone(phones: Phone[], deletedUuid: string): Array<Phone> {
    phones.splice(
      phones.findIndex((phone: Phone) => phone.uuid === deletedUuid),
      1,
    );
    return phones;
  }

  /**
   *
   * @param {Phone[]} phones
   * @returns {Phone[]}
   */
  static sortPhones(phones: Phone[]): Phone[] {
    return phones.sort(ArrayHelper.fieldSorter(['-!createdAt', '-primary', '-verified', 'number']));
  }
}
