import { HttpErrorResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ApiEntity } from '@core/entities/api/api.entity';
import { ClientEntity } from '@core/entities/client/client.entity';
import { ConsentStatusEntity } from '@core/entities/oauth/consent-status.entity';
import { ScopeEntity } from '@core/entities/oauth/scope.entity';
import { UserFeedbackEntity } from '@core/entities/user/user-feedback.entity';
import { UserFeedbackTypesEnum } from '@core/enums/user-feedback-types.enum';
import { DateHelper } from '@core/helpers/date.helper';
import { ElementRefHelper } from '@core/helpers/element-ref.helper';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';
import { FieldErrorMessageInterface } from '@core/interfaces/field-error-message.interface';
import { ProcessableInterface } from '@core/interfaces/processable.interface';
import { ModalService } from '@core/services/modal/modal.service';
import { ScopeService } from '@core/services/scope/scope.service';
import { FfConfirmModalDataInterface } from '@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal-data.interface';
import { FfConfirmModalComponent } from '@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal.component';
import { DateValidator } from '@shared/validators/date.validator';
import { ScopeValidator } from '@shared/validators/scope.validator';
import { Subject, throwError } from 'rxjs';
import { catchError, finalize, take, takeUntil, tap } from 'rxjs/operators';
import { deserialize } from 'serializr';

@Component({
  selector: 'app-ff-scope-form',
  templateUrl: './ff-scope-form.component.html',
  styleUrls: ['./ff-scope-form.component.scss'],
})
export class FfScopeFormComponent implements OnDestroy, OnInit, ProcessableInterface {
  readonly ERROR_API = 'api';
  readonly ERROR_PATTERN = 'pattern';
  readonly ERROR_REQUIRED = 'required';
  readonly ERROR_SUNSET = 'sunset';

  @Input()
  api?: ApiEntity;

  @Output()
  deleted: EventEmitter<any> = new EventEmitter();

  @Input()
  scope: ScopeEntity;

  @Output()
  updated: EventEmitter<ScopeEntity> = new EventEmitter<ScopeEntity>();

  exampleClient: ClientEntity;
  exampleConsentStatus: ConsentStatusEntity;
  expirationGroup: UntypedFormGroup | AbstractControl;
  feedbackMessages: UserFeedbackEntity[] = [];
  formHelper = ReactiveFormsHelper;
  processing: boolean;
  scopeForm: UntypedFormGroup;
  submitted: boolean = false;

  private readonly _onDestroy$ = new Subject<void>();

  private _messagesRef: ElementRef;

  constructor(
    private _fb: UntypedFormBuilder,
    private _modalService: ModalService,
    private _scopeService: ScopeService
  ) {}

  @ViewChild('feedbackMessagesRef', { read: ElementRef, static: false })
  set messagesRef(elRef: ElementRef) {
    this._messagesRef = elRef;
  }

  ngOnDestroy() {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  ngOnInit() {
    if (!this.scope.uuid && !this.api) {
      console.error('When creating a new scope its parent api is required, supply the [api] param');
      return;
    }

    this._buildForm();
    this.exampleClient = new ClientEntity('Test');
    this.exampleConsentStatus = new ConsentStatusEntity();
    this.exampleConsentStatus.scopes.push(this.scope);

    // When set as deprecated, add validation to deprecation fields
    this.scopeForm
      .get('deprecated')
      .valueChanges.pipe(
        tap((deprecated) => {
          const sinceCtrl = ReactiveFormsHelper.getCtrl(this.scopeForm, 'scopeExpirationDate.deprecatedSince');
          const notesCtrl = ReactiveFormsHelper.getCtrl(this.scopeForm, 'scopeExpirationDate.notes');

          sinceCtrl.clearValidators();
          notesCtrl.clearValidators();

          if (deprecated) {
            sinceCtrl.setValidators([Validators.required, DateValidator.syntax]);
            notesCtrl.setValidators([Validators.required]);
          }
        }),
        takeUntil(this._onDestroy$)
      )
      .subscribe();
  }

  canBeDeleted(): boolean {
    return this.scope.uuid && !(this.scope.global || this.scope.inUse);
  }

  confirmDelete() {
    const confirmContent: FfConfirmModalDataInterface = {
      component: FfConfirmModalComponent,
      title: 'Er du sikker?',
      body: 'Vil du faktisk slette scopet «' + this.scope.name + '»?',
      confirmCallback: () => {
        this._deleteScope();
      },
    };

    this._modalService.openModal(confirmContent);
  }

  showErrorsIfSubmitted(): void {
    if (!this.submitted) {
      return;
    }

    this.feedbackMessages = [];

    const nameCtrl = this.scopeForm.get('name');

    const fields: FieldErrorMessageInterface[] = [
      { ctrl: this.scopeForm, err: this.ERROR_API, msg: ScopeFormErrors.API_GENERIC },
      { ctrl: nameCtrl, err: ScopeValidator.ERROR_INVALID, msg: ScopeFormErrors.NAME_INVALID },
      { ctrl: nameCtrl, err: this.ERROR_REQUIRED, msg: ScopeFormErrors.NAME_REQUIRED },
      { ctrl: nameCtrl, err: ScopeValidator.ERROR_TAKEN, msg: ScopeFormErrors.NAME_TAKEN },
      { ctrl: this.scopeForm.get('description'), err: this.ERROR_REQUIRED, msg: ScopeFormErrors.DESCRIPTION_REQUIRED },
      {
        ctrl: this.expirationGroup.get('deprecatedSince'),
        err: this.ERROR_REQUIRED,
        msg: ScopeFormErrors.DATE_REQUIRED,
      },
      { ctrl: this.expirationGroup.get('deprecatedSince'), err: this.ERROR_PATTERN, msg: ScopeFormErrors.PATTERN_DATE },
      { ctrl: this.expirationGroup.get('sunset'), err: this.ERROR_PATTERN, msg: ScopeFormErrors.PATTERN_DATE },
      { ctrl: this.expirationGroup.get('sunset'), err: this.ERROR_SUNSET, msg: ScopeFormErrors.SUNSET_INVALID },
      {
        ctrl: this.expirationGroup.get('sunset'),
        err: DateValidator.ERROR_NEWER_THAN,
        msg: ScopeFormErrors.SUNSET_INVALID,
      },
      { ctrl: this.expirationGroup.get('notes'), err: this.ERROR_REQUIRED, msg: ScopeFormErrors.NOTES_REQUIRED },
    ];

    const errors = ReactiveFormsHelper.composeErrorMessages(fields);
    this.feedbackMessages = errors.errorMessages;

    if (this.feedbackMessages.length > 0) {
      ElementRefHelper.scrollTo(this._messagesRef);
    }
  }

  submitForm(): void {
    this.submitted = true;

    // Require deprecatedSince if including a deprecation
    if (this.scopeForm.get('deprecated').value) {
      this.expirationGroup
        .get('deprecatedSince')
        .setValidators([Validators.required, Validators.pattern(DateHelper.REGEX_DATE_NOR_SHORT)]);
      this.expirationGroup.get('deprecatedSince').updateValueAndValidity({ onlySelf: false });
      this.expirationGroup.markAllAsTouched();
    }

    if (this.scopeForm.invalid) {
      this.showErrorsIfSubmitted();
      return;
    }

    this.processing = true;
    if (this.scope.uuid) {
      this._updateScope();
    } else {
      this._newScope();
    }
  }

  updateExampleScope(): void {
    if (this.scopeForm.get('description').value.length) {
      const idx = this.exampleConsentStatus.scopes.findIndex((s) => s.name === this.scope.name);
      this.exampleConsentStatus.scopes[idx].description = this.scopeForm.get('description').value;
    }
    this.showErrorsIfSubmitted();
  }

  private _buildForm(): void {
    this.scopeForm = this._fb.group({
      deprecated: [false],
      description: [this.scope.description, [Validators.required]],
      name: [
        this.scope.uuid ? this.scope.name : this.api.namespace + '.',
        [Validators.required, ScopeValidator.syntax],
      ],
      scopeExpirationDate: this._fb.group({
        deprecatedSince: [
          this.scope.scopeExpirationDate && this.scope.scopeExpirationDate.deprecatedSince
            ? DateHelper.toShortNorString(this.scope.scopeExpirationDate.deprecatedSince)
            : null,
          [],
        ],
        notes: [(this.scope.scopeExpirationDate && this.scope.scopeExpirationDate.notes) || null, []],
        sunset: [
          this.scope.scopeExpirationDate && this.scope.scopeExpirationDate.sunset
            ? DateHelper.toShortNorString(this.scope.scopeExpirationDate.sunset)
            : null,
        ],
      }),
    });

    this.expirationGroup = this.scopeForm.get('scopeExpirationDate');

    if (this.scope.uuid) {
      this.scopeForm.get('name').disable(); // Cannot change name of existing scope

      // Add the validation dependent on deprecatedSince
      this.expirationGroup
        .get('sunset')
        .setValidators([DateValidator.syntax, DateValidator.newerThan(this.expirationGroup.get('deprecatedSince'))]);

      if (this.scope.scopeExpirationDate) {
        this.scopeForm.get('deprecated').setValue(true); // Show the deprecation fields
        this.expirationGroup.get('deprecatedSince').setValidators([Validators.required, DateValidator.syntax]);

        if (this.scope.scopeExpirationDate.uuid) {
          this.scopeForm.get('scopeExpirationDate').disable(); // there is no PUT/PATCH
        }
      }
    }
  }

  private _deleteScope() {
    if (this.scope.locked) {
      return;
    }

    this.processing = true;
    // Deleting the .scopeExpirationDate is handled by API
    this._scopeService
      .deleteScope(this.scope.uuid)
      .pipe(take(1))
      .subscribe(
        () => {
          this.api.scopes.splice(
            this.api.scopes.findIndex((sc: ScopeEntity) => sc.uuid === this.scope.uuid),
            1
          );
          this.processing = false;
          this.deleted.emit();
        },
        () => {
          this.processing = false;
        }
      );
  }

  private _newScope() {
    const newScope: ScopeEntity = deserialize(ScopeEntity, this.scopeForm.value);

    // Check if given scope exists
    this._scopeService
      .existsByName(newScope.name)
      .pipe(take(1))
      .subscribe(
        (exists: boolean) => {
          // Early return if exists
          if (exists) {
            this.processing = false;
            this.scopeForm.get('name').setAsyncValidators(ScopeValidator.unique(this._scopeService));
            this.scopeForm.get('name').updateValueAndValidity({ onlySelf: true });
            this.feedbackMessages.push(new UserFeedbackEntity(ScopeFormErrors.NAME_TAKEN, UserFeedbackTypesEnum.ERROR));
            return;
          }

          // Scope available, try to post
          this._scopeService
            .postScope(this.api.uuid, newScope)
            .pipe(take(1))
            .subscribe(
              (scope: ScopeEntity) => {
                this.api.scopes.push(scope);
                this.updateExampleScope();
                this.processing = false;
                this.scope = scope;
                this.updated.emit(this.scope);
              },
              () => {
                // Posting failed
                this.processing = false;
                this.feedbackMessages.push(
                  new UserFeedbackEntity('Lagringen mislyktes, vennligst prøv igjen', UserFeedbackTypesEnum.ERROR)
                );
              }
            );
        },
        () => {
          // Unique check failed
          this.processing = false;
          this.scopeForm.get('name').setAsyncValidators(ScopeValidator.unique(this._scopeService));
          this.feedbackMessages.push(
            new UserFeedbackEntity('Lagringen mislyktes, vennligst prøv igjen', UserFeedbackTypesEnum.ERROR)
          );
        }
      );
  }

  private _updateScope() {
    let errorCount = 0;

    // Delete scope expiration?
    if (
      !this.scopeForm.get('deprecated').value &&
      this.scope.scopeExpirationDate &&
      this.scope.scopeExpirationDate.uuid
    ) {
      this._scopeService.deleteScopeExpiration(this.scope.scopeExpirationDate.uuid).pipe(take(1)).subscribe();
      this.expirationGroup.get('deprecatedSince').setValidators([]);
      this.expirationGroup.get('deprecatedSince').updateValueAndValidity({ onlySelf: false });
    }

    // Create scope expiration?
    if (this.scopeForm.get('deprecated').value && !this.scope.scopeExpirationDate) {
      let sunset;
      const deprecatedSince = DateHelper.toPhpAtomFormat(
        DateHelper.parseDateStringShortNor(this.expirationGroup.get('deprecatedSince').value)
      );

      if (this.expirationGroup.get('sunset').value) {
        sunset = DateHelper.toPhpAtomFormat(
          DateHelper.parseDateStringShortNor(this.expirationGroup.get('sunset').value)
        );
      }

      this._scopeService
        .postScopeExpiration(this.scope.uuid, this.expirationGroup.get('notes').value, deprecatedSince, sunset)
        .pipe(
          take(1),
          catchError((e: HttpErrorResponse) => {
            errorCount++;
            this.processing = false;
            this.expirationGroup.get('sunset').setErrors({ [this.ERROR_SUNSET]: true });
            return throwError(() => e);
          })
        )
        .subscribe();
    }

    if (errorCount) {
      this.showErrorsIfSubmitted();
      return;
    }

    // Patch scope
    this._scopeService
      .patchScope(this.scope.uuid, 'description', this.scopeForm.get('description').value)
      .pipe(
        tap((scope: ScopeEntity) => {
          this.updateExampleScope();
          this.processing = false;
          this.scope = scope;
        }),
        catchError((e: HttpErrorResponse) => {
          errorCount++;
          this.processing = false;
          this.scopeForm.setErrors({ [this.ERROR_API]: true });
          return throwError(() => e);
        }),
        finalize(() => {
          this.processing = false;
          if (!errorCount) {
            this.updated.emit(this.scope);
          }
        }),
        take(1)
      )
      .subscribe();
  }
}

enum ScopeFormErrors {
  API_GENERIC = 'Noe gikk galt',
  DATE_REQUIRED = 'Feltet "Utfases fra dato" må angis',
  DESCRIPTION_REQUIRED = 'Beskrivelse må angis. Det er denne som forklarer brukere hva scopet brukes til',
  NAME_INVALID = 'Ugyldig scopenavn. Kun NQCHAR er gyldig. Se https://tools.ietf.org/html/rfc6749#appendix-A for detaljer',
  NAME_REQUIRED = 'Scopenavn må angis',
  NAME_TAKEN = 'Scopenavnet er opptatt',
  NOTES_REQUIRED = 'Forklaring må angis',
  PATTERN_DATE = 'Dato må ha formatet DD.MM.ÅÅÅÅ, f.eks. 17.05.2021 for 17. mai',
  SUNSET_INVALID = 'Ugyldig verdi for "siste bruksdag". Må være en gyldig dato som er nyere enn "utfases fra dato"',
}
