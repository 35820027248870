import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { User } from '@core/entities/user/user.entity';

@Component({
  selector: 'app-user-type-form',
  templateUrl: './user-type-form.component.html',
  styleUrls: ['./user-type-form.component.scss'],
})
export class UserTypeFormComponent implements OnChanges, OnInit {
  @Input()
  isTestUserFormCtrl: FormControl<boolean>;

  @Input()
  userToBeEdited: User;

  /**
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userToBeEdited'] && !changes['userToBeEdited'].isFirstChange()) {
      this._buildForm();
    }
  }

  ngOnInit() {
    this._buildForm();
  }

  /**
   * @private
   */
  private _buildForm() {
    this.isTestUserFormCtrl.setValue(!!this.userToBeEdited.isTestUser);
    this.isTestUserFormCtrl.enable({ onlySelf: true });
  }
}
