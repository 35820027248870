import { alias, primitive, serializable } from 'serializr';

export class ValidationViolationResponseEntity {
  @serializable(alias('errorName', primitive()))
  private _errorName?: string;

  @serializable(alias('invalidValue', primitive()))
  private _invalidValue?: string;

  @serializable(alias('message', primitive()))
  private _message: string;

  get errorName(): string {
    return this._errorName;
  }

  set errorName(value: string) {
    this._errorName = value;
  }

  get invalidValue(): string {
    return this._invalidValue;
  }

  set invalidValue(value: string) {
    this._invalidValue = value;
  }

  get message(): string {
    return this._message;
  }

  set message(value: string) {
    this._message = value;
  }
}
