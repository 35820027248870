<div class="text">
  @if (title?.length > 0) {
    <h1>
      {{ title }}
    </h1>
  }

  <ng-content select="h1, h2, h3, h4, h5, h6, .subtitle"></ng-content>
</div>

<ng-content select=".title__button"></ng-content>

@for (b of buttons; track b) {
  @if (b.iconPath || b.label) {
    <app-ff-button
      (clicked)="fireCallback(b)"
      [color]="b.color"
      [iconPath]="b.iconPath"
      [iconSize]="b.iconSize"
      [label]="b.label ? b.label : null"
      [processing]="b.processing"
      [routerLink]="b.routerLink"
      [title]="b.title"
      class="title__button inline {{ b.class }}"
      tabindex="0"
    ></app-ff-button>
  }
}
