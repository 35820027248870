<ul class="developers">
  @if (!developers || developers.length < 1) {
    <li class="developer well">
      <span>Ingen utviklere</span>
    </li>
  }

  @for (dev of developers; track dev) {
    <li class="developer well">
      <div class="developer__info">
        <div class="info name">
          @if (!isUserAdmin) {
            <span>{{ dev.firstName + ' ' + dev.lastName }}</span>
          }
          @if (isUserAdmin) {
            <a [routerLink]="['/users/' + dev.uuid]">{{ dev.firstName + ' ' + dev.lastName }}</a>
          }
        </div>
        @if (dev.email) {
          <div class="info email">
            <a [title]="'Mailto: ' + dev.email" href="mailto:{{ dev.email }}">
              <svg-icon [applyClass]="true" src="assets/icons/envelope.svg" role="presentation"></svg-icon>
            </a>
            <div class="data">{{ dev.email }}</div>
          </div>
        }
        @if (dev.phone) {
          <div class="info phone">
            <a [title]="'Call: ' + dev.phone" href="tel:{{ dev.phone }}">
              <svg-icon [applyClass]="true" src="assets/icons/handset.svg" role="presentation"></svg-icon>
            </a>
            <div class="data">{{ dev.phone }}</div>
          </div>
        }
      </div>
      <app-ff-delete-button
        (clicked)="confirmRemoval(dev)"
        [disabled]="!isAppAdmin"
        [processing]="dev.processing"
        class="developer__remove"
        titleText="Fjern tilgang"
      ></app-ff-delete-button>
    </li>
  }
</ul>

@if (processing) {
  <div class="processing">
    <app-ff-loading-indicator class="stroke--mine-shaft" size="s20"></app-ff-loading-indicator>
  </div>
}
