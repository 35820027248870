<div class="container">
  <div class="content">
    <h1 class="title">{{ updatingPassword ? 'Bytt passord' : 'Sett et passord' }}</h1>
    <p class="subtitle">
      {{
        updatingPassword
          ? 'Systemet krever at du bytter passord nå'
          : 'Vi har ikke noe passord knyttet til din bruker. Lag deg et nytt et nå'
      }}.
    </p>

    <app-user-password-form
      (saved)="proceedCallback.emit($event)"
      [pendingInfoUpdates]="pendingInfoUpdates"
      [submitIcon]="'icons/arrow_right.svg'"
      [user]="user"
    ></app-user-password-form>

    <app-ff-button class="standalone padded" color="transparent" label="Avbryt" routerLink="/logout"></app-ff-button>
  </div>
</div>
