import { alias, date, identifier, serializable } from 'serializr';

export class ScopeExpirationDateEntity {
  @serializable(alias('createdAt', date()))
  private _createdAt: Date;

  @serializable(alias('deprecatedSince', date()))
  private _deprecatedSince?: Date;

  @serializable(alias('notes'))
  private _notes?: string;

  @serializable(alias('sunset', date()))
  private _sunset?: Date;

  @serializable(alias('uuid', identifier()))
  private _uuid: string;

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get deprecatedSince(): Date {
    return this._deprecatedSince;
  }

  set deprecatedSince(value: Date) {
    this._deprecatedSince = value;
  }

  get notes(): string {
    return this._notes;
  }

  set notes(value: string) {
    this._notes = value;
  }

  get sunset(): Date {
    return this._sunset;
  }

  set sunset(value: Date) {
    this._sunset = value;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }
}
