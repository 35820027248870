<h1>Bekreft tilgang</h1>
<div class="subtitle">
  Applikasjonen du er på vei til trenger tilgang til noe av din informasjon, her velger du hva du vil tillate.
</div>

<div class="well">
  <div class="intro">«{{ clientInfo.name }}» ber om informasjon fra Fagforbundet:</div>

  <div class="scopes">
    @for (scope of scopesOrdered; track scope) {
      <div class="scope">
        @if (scope.required) {
          <div class="icon padded">
            <svg-icon [applyClass]="true" class="s18" role="presentation" src="assets/icons/check_mark.svg"></svg-icon>
          </div>
        }
        @if (!scope.required) {
          <app-ff-checkbox
            [formCtrl]="getScopeCtrl(scope.name)"
            class="icon"
            title="Valgfritt"
          ></app-ff-checkbox>
        }
        <div class="info">
          <div class="desc" title="{{ scope.name }}" [innerHTML]="formatScopeDescription(scope)"></div>
          @if (!example) {
            <div class="required">
              {{ scope.required ? 'Påkrevd' : 'Valgfritt' }}&nbsp;
              <app-ff-tooltip
            text="{{
              scope.required
                ? 'Applikasjonen må ha denne tilgangen for å fungere'
                : 'Applikasjonen kan klare seg uten denne informasjonen, men du kan oppleve redusert funksjonalitet'
            }}"
                >
                <svg-icon
                  [applyClass]="true"
                  class="help-icon"
                  role="presentation"
                  src="assets/icons/circled_question_mark_inverted_24.svg"
                ></svg-icon>
              </app-ff-tooltip>
            </div>
          }
        </div>
      </div>
    }
  </div>
</div>

@if (!example) {
  <div class="buttons">
    <app-ff-button (clicked)="authorize()" [processing]="processing" color="red" label="Tillat"></app-ff-button>
    <app-ff-button (clicked)="cancel()" [processing]="processing" color="transparent" label="Avbryt"></app-ff-button>
  </div>
}
