<ng-content select="input" class="input"></ng-content>

@if (tooltipText && !processing) {
  <app-ff-tooltip
    (clicked)="toggle()"
    [ngClass]="setIconClasses()"
    [options]="tooltipOptions"
    [show]="tooltipShow"
    [text]="tooltipText"
    >
    <svg-icon [ngClass]="setIconClasses()" [src]="iconPath"></svg-icon>
  </app-ff-tooltip>
}

@if (!tooltipText && !processing) {
  <svg-icon
    (click)="clickAction()"
    (keydown.enter)="clickAction()"
    [ngClass]="setIconClasses()"
    [src]="iconPath"
    [tabIndex]="clicked.observers.length && !disabled ? '0' : '-1'"
    role="button"
  ></svg-icon>
}

@if (processing) {
  <app-ff-loading-indicator class="icon" size="s20" stroke="#979797"></app-ff-loading-indicator>
}
