<h3>Verifisering</h3>

<div class="well flex flex--between">
  @if (!user.isVerified) {
    <span class="pad-left">Ikke verifisert</span>
    @if (user.uuid) {
      <a class="button inline red padded verification" routerLink="/users/{{ user.uuid }}/verify">
        Verifisering
      </a>
    }
  }

  @if (user.isVerified) {
    <span class="pad-left">
      Verifisert med
      @for (v of user.verifications; track v; let idx = $index) {
        <span class="verification-detail">
          <app-ff-tooltip text="Verifisering gjennomført {{ v.lastVerifiedAt | date: 'dd.MM.yyyy HH:mm' }}">
            <span>{{ verificationHelper.getSourceString(v) }}</span>
          </app-ff-tooltip>
          @if (idx + 1 < user.verifications.length) {
            <span>, </span>
          }
        </span>
      }
    </span>
    <a class="button inline red padded verification" routerLink="/users/{{ user.uuid }}/verify"> Verifisering </a>
  }
</div>
