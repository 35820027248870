import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-inform-and-proceed',
  templateUrl: './inform-and-proceed.component.html',
  styleUrls: ['./inform-and-proceed.component.scss'],
})

export class InformAndProceedComponent {
  @Input()
  clientName?: string;

  @Input()
  proceedUrl: string;
}
