<form [formGroup]="passwordForm" class="form" [class.submitted]="submitted" (keyup.enter)="savePassword()">
  @if (showExisting) {
    <div class="form-group">
      <div class="form-control">
        <label for="existing"> Eksisterende (gammelt) passord </label>
        <app-ff-toggle-reveal-input [showToggleButton]="true">
          <input
            (input)="showErrorsIfSubmitted()"
            [formControl]="formHelper.getCtrl(passwordForm, 'existing')"
            [readOnly]="processing ? true : null"
            autocomplete="current-password"
            id="existing"
            placeholder="Eksisterende passord"
            data-cy="input-password"
            type="password"
            />
        </app-ff-toggle-reveal-input>
      </div>
    </div>
  }

  <div class="form-group">
    <div class="form-control">
      <label for="password">
        @if (inputLabel) {
          <span>{{ inputLabel }}</span>
        }
        @if (!inputLabel) {
          <span> Nytt passord </span>
        }
      </label>

      <app-ff-toggle-reveal-input [showToggleButton]="true">
        <input
          (input)="onPasswordValueChange()"
          (keydown)="passCtrl.markAsTouched()"
          [formControl]="passCtrl"
          [readOnly]="processing ? true : null"
          autocomplete="new-password"
          id="password"
          placeholder="Personlig passord"
          data-cy="input-password"
          type="password"
          />
      </app-ff-toggle-reveal-input>
    </div>
  </div>

  <app-ff-button
    (clicked)="savePassword()"
    [iconPath]="submitIcon"
    [label]="submitIcon ? '' : 'Lagre'"
    [processing]="processing"
    class="standalone"
    data-cy="submit-save-password"
  ></app-ff-button>
</form>

<app-feedback-messages
  #feedbackMessagesRef
  data-cy="password-feedback"
  [messages]="feedbackMessages"
></app-feedback-messages>
