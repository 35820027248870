import { alias, date, identifier, serializable } from 'serializr';

export class Email {
  @serializable(alias('createdAt', date()))
  private readonly _createdAt: Date;

  @serializable(alias('emailAddress'))
  private _emailAddress: string;

  @serializable(alias('primary'))
  private _primary: boolean = false;

  @serializable(alias('uuid', identifier()))
  private readonly _uuid: string;

  @serializable(alias('verifiedAt', date()))
  private readonly _verifiedAt: Date | null;

  verificationSent: boolean = false;

  public get createdAt(): Date {
    return this._createdAt;
  }

  public get emailAddress(): string {
    return this._emailAddress;
  }

  public set emailAddress(email: string) {
    this._emailAddress = email;
  }

  public get primary(): boolean {
    return this._primary;
  }

  public set primary(primary: boolean) {
    this._primary = primary;
  }

  public get uuid(): string {
    return this._uuid;
  }

  public get verified(): boolean {
    return !!this._verifiedAt;
  }

  public get verifiedAt(): Date {
    return this._verifiedAt;
  }
}
