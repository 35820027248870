@if (!processing) {
  <svg-icon
    [applyClass]="true"
    [svgStyle]="{ stroke: stroke }"
    class="delete"
    role="presentation"
    src="assets/icons/trash_24.svg"
  ></svg-icon>
}

@if (processing) {
  <app-ff-loading-indicator [size]="size" class="stroke--mine-shaft"></app-ff-loading-indicator>
}

@if (labelText) {
  <div>{{ labelText }}</div>
}
