import { ApiEntity } from "@core/entities/api/api.entity";
import { ClientTestUserEntity } from "@core/entities/api/client-test-user.entity";
import { ContactInformationEntity } from "@core/entities/api/contact-information.entity";
import { DeveloperEntity } from "@core/entities/api/developer.entity";
import { RevokeUrlEntity } from "@core/entities/api/revoke-url.entity";
import { ClientEntity } from "@core/entities/client/client.entity";
import { GrantEntity } from "@core/entities/oauth/grant.entity";
import { PostLogoutRedirectUriEntity } from "@core/entities/oauth/post-logout-redirect-uri.entity";
import { RedirectUriEntity } from "@core/entities/oauth/redirect-uri.entity";
import { ScopeEntity } from "@core/entities/oauth/scope.entity";
import { CorsUriEntity } from "@shared/components/cors-uris/cors-uri.entity";
import {
  alias,
  date,
  identifier,
  list,
  ModelSchema,
  object,
  primitive,
  setDefaultModelSchema,
} from "serializr";
import { LoginProviderEntity } from "../login/login-provider.entity";

export const CLIENT_ENTITY_SCHEMA: ModelSchema<ClientEntity> = {
  factory: () => new ClientEntity(),
  props: {
    active: primitive(),
    allowedGrants: alias("allowedGrants", list(object(GrantEntity))),
    apis: alias("apis", list(object(ApiEntity))),
    bypassAuthorization: primitive(),
    canOmitScopes: primitive(),
    contactInformation: alias(
      "contactInformation",
      object(ContactInformationEntity)
    ),
    createdAt: date(),
    corsUris: alias("corsUrls", list(object(CorsUriEntity))),
    description: primitive(),
    developers: list(object(DeveloperEntity)),
    devMode: primitive(),
    id: alias("uuid", identifier()),
    keyOption: primitive(),
    loginProviders: list(object(LoginProviderEntity)),
    name: primitive(),
    postLogoutRedirectUris: list(object(PostLogoutRedirectUriEntity)),
    processing: primitive(),
    public: primitive(),
    redirectUris: list(object(RedirectUriEntity)),
    requireInformationUpdate: primitive(),
    scopes: list(object(ScopeEntity)),
    testUsers: list(object(ClientTestUserEntity)),
    type: primitive(),
    lastUsedAt: date(),
  },
};

export const API_ENTITY_SCHEMA: ModelSchema<ApiEntity> = {
  factory: () => new ApiEntity(),
  props: {
    addDeveloper: primitive(),
    clients: list(object(ClientEntity)),
    createdAt: date(),
    description: primitive(),
    developers: list(object(DeveloperEntity)),
    name: primitive(),
    namespace: primitive(),
    processing: primitive(),
    revokeUrls: list(object(RevokeUrlEntity)),
    scopes: list(object(ScopeEntity)),
    uuid: identifier(),
  },
};

setDefaultModelSchema(ClientEntity, CLIENT_ENTITY_SCHEMA);
setDefaultModelSchema(ApiEntity, API_ENTITY_SCHEMA);
