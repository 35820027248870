import { HttpParams } from '@angular/common/http';
import { ApiEntity } from '@core/entities/api/api.entity';
import { PaginationQueryParamsEntity } from '@core/entities/query-params/pagination-query-params.entity';
import { alias, list, object, serializable } from 'serializr';

export class PageableApisEntity extends PaginationQueryParamsEntity {
  @serializable(alias('apis', list(object(ApiEntity))))
  private _apis: ApiEntity[] = [];

  /**
   * Whether to constrain getApis to the ones the user has been given explicit access to.
   * For an admin this may narrow results.
   * For others: Not supplying this as false can throw a forbidden exception.
   * @type {boolean}
   * @private
   */
  private _onlyAccessible: boolean;

  /**
   * Extend super to append the onlyAccessible property
   * @returns {HttpParams}
   */
  override apiRequestParams(): HttpParams {
    let params = super.apiRequestParams();

    if (this.onlyAccessible !== undefined) {
      params = params.set('only-accessible', this.onlyAccessible.toString());
    }

    return params;
  }

  get apis(): ApiEntity[] {
    return this._apis;
  }

  set apis(value: ApiEntity[]) {
    this._apis = value;
  }

  get onlyAccessible(): boolean {
    return this._onlyAccessible;
  }

  set onlyAccessible(value: boolean) {
    this._onlyAccessible = value;
  }
}
