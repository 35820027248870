import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { WindowRefHelper } from "@core/helpers/window-ref.helper";
import { FfTooltipArrowPositions } from "@shared/components/ff-tooltip/ff-tooltip-arrow-positions.enum";
import { IFfTooltipOptions } from "@shared/components/ff-tooltip/ff-tooltip-options.interface";

let tooltipIndex: number = 0;

@Component({
  selector: "app-ff-tooltip",
  templateUrl: "./ff-tooltip.component.html",
  styleUrls: ["./ff-tooltip.component.scss"],
  providers: [{ provide: WindowRefHelper.WINDOW, useValue: window }],
})
/**
 * Displays a tooltip for whatever it receives as content (projected with ng-content).
 *
 * @param {IFfTooltipOptions} options
 *
 * @see IFfTooltipOptions
 *
 * @example <app-ff-tooltip><img /></app-ff-tooltip>
 *
 * @example Lorem ipsum <app-ff-tooltip [options]="{tooltipText: 'et cetera'">etc</app-ff-tooltip>.
 *
 */
export class FfTooltipComponent implements OnInit {
  private readonly HTML_ID_PREFIX: string = "tooltip_";

  @Output()
  clicked: EventEmitter<any> = new EventEmitter<any>();

  @Input({ required: false })
  id?: string = this.HTML_ID_PREFIX + tooltipIndex++;

  @Input({ required: false })
  options?: IFfTooltipOptions;

  @Input({ required: false })
  show?: boolean = false;

  @Input({ required: true })
  text: string;

  @ViewChild("tooltipRef", { static: true })
  tooltipRef: ElementRef;

  /**
   * The default config which will be used unless overriden in [options] param
   *
   * @type {IFfTooltipOptions}
   */
  config: IFfTooltipOptions & {
    currentArrowPosition: FfTooltipArrowPositions;
  } = {
    arrowPosition: FfTooltipArrowPositions.bottom,
    currentArrowPosition: null,
    showOnHover: true,
    showOnLoad: false,
  };

  constructor(
    @Inject(WindowRefHelper.WINDOW)
    private _window: Window
  ) {
    if (tooltipIndex === 0) {
      tooltipIndex =
        this._window.document.querySelectorAll(
          '[id^="' + this.HTML_ID_PREFIX + '"]'
        ).length + 1;
    } else {
      ++tooltipIndex;
    }
  }

  ngOnInit() {
    if (this.options) {
      this.config = { ...this.config, ...this.options };
    }

    this.config.currentArrowPosition = this.config.arrowPosition;

    if (this.config.showOnLoad) {
      this.open();
    }
  }

  click() {
    this.open();
    this.clicked.emit();
  }

  close() {
    this.show = false;
  }

  mouseEnter() {
    if (this.config.showOnHover) {
      this.open();
    }
  }

  mouseLeave() {
    if (this.config.showOnHover) {
      this.close();
    }
  }

  open() {
    this.#positionWithinBounds();
    this.show = true;
  }

  toggle() {
    if (this.show) {
      this.close();
    } else {
      this.open();
    }

    this.clicked.emit();
  }

  /**
   * Perform checks for whether a tooltip is out of bounds. Clockwise order
   */
  #positionWithinBounds() {
    const overflows = this.#getOverflows();

    switch (this.config.currentArrowPosition) {
      case FfTooltipArrowPositions.top:
        if (overflows.bottom) {
          this.config.currentArrowPosition = FfTooltipArrowPositions.bottom;
        }
        break;

      case FfTooltipArrowPositions.topRight:
        if (overflows.bottom && overflows.left) {
          this.config.currentArrowPosition = FfTooltipArrowPositions.bottomLeft;
        } else if (overflows.left) {
          this.config.currentArrowPosition = FfTooltipArrowPositions.topLeft;
        } else if (overflows.bottom) {
          this.config.currentArrowPosition =
            FfTooltipArrowPositions.bottomRight;
        }
        break;

      case FfTooltipArrowPositions.right:
        if (overflows.left) {
          this.config.currentArrowPosition = FfTooltipArrowPositions.left;
        }
        break;

      case FfTooltipArrowPositions.bottomRight:
        if (overflows.top && overflows.left) {
          this.config.currentArrowPosition = FfTooltipArrowPositions.topLeft;
        } else if (overflows.left) {
          this.config.currentArrowPosition = FfTooltipArrowPositions.bottomLeft;
        } else if (overflows.top) {
          this.config.currentArrowPosition = FfTooltipArrowPositions.topRight;
        }
        break;

      case FfTooltipArrowPositions.bottom:
        if (overflows.top) {
          this.config.currentArrowPosition = FfTooltipArrowPositions.top;
        }
        break;

      case FfTooltipArrowPositions.bottomLeft:
        if (overflows.top && overflows.right) {
          this.config.currentArrowPosition = FfTooltipArrowPositions.topRight;
        } else if (overflows.right) {
          this.config.currentArrowPosition =
            FfTooltipArrowPositions.bottomRight;
        } else if (overflows.top) {
          this.config.currentArrowPosition =
            FfTooltipArrowPositions.bottomRight;
        }
        break;

      case FfTooltipArrowPositions.left:
        if (overflows.right) {
          this.config.currentArrowPosition = FfTooltipArrowPositions.right;
        }
        break;

      case FfTooltipArrowPositions.topLeft:
        if (overflows.bottom && overflows.right) {
          this.config.currentArrowPosition =
            FfTooltipArrowPositions.bottomRight;
        } else if (overflows.right) {
          this.config.currentArrowPosition = FfTooltipArrowPositions.topRight;
        } else if (overflows.bottom) {
          this.config.currentArrowPosition = FfTooltipArrowPositions.bottomLeft;
        }
        break;
    }
  }

  #getOverflows() {
    const tipSize: DOMRect =
      this.tooltipRef.nativeElement.getBoundingClientRect();
    const vh = this._window.innerHeight || 0;
    const vw = this._window.innerWidth || 0;

    return {
      top: tipSize.top < 0,
      right: tipSize.right > 0 && tipSize.right > vw,
      bottom: tipSize.bottom > 0 && tipSize.bottom > vh,
      left: tipSize.left > 0 && tipSize.left < 0,
    };
  }
}
