import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhoneCountryCodeEntity } from '@core/entities/country-codes/phone-country-code.entity';
import { PhoneValidationResponseEntity } from '@core/entities/response/phone-validation-response.entity';
import { Phone } from '@core/entities/user/phone.entity';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { PhoneHelper } from '@core/helpers/phone.helper';
import { PhonesStoreService } from '@core/services/stores/phones-store.service';
import { UserService } from '@core/services/user/user.service';
import { Observable } from 'rxjs';
import { concatMap, first, map, shareReplay, tap } from 'rxjs/operators';
import { deserialize, serialize } from 'serializr';
import { UserStoreService } from '../stores/user-store.service';

@Injectable({ providedIn: 'root' })
export class PhoneService {
  /**
   * @param _http
   * @param _phonesStoreService
   * @param _userService
   * @param _userStore
   */
  constructor(
    private _http: HttpClient,
    private _phonesStoreService: PhonesStoreService,
    private _userService: UserService,
    private _userStore: UserStoreService,
  ) {}

  /**
   * Converts old Phone class signature to new
   */
  private _convertToNew(oldPhone: object) {
    const phone = deserialize(Phone, oldPhone);
    phone.phoneNumber = '+' + oldPhone['countryCode'] + oldPhone['number'];
    return phone;
  }

  /**
   * @param {string} number
   * @returns {Observable<boolean>}
   */
  checkValidPhone(number: string): Observable<PhoneValidationResponseEntity> {
    return this._http.get(EnvironmentHelper.fetchAPIBase('v1/validate/phone/' + number)).pipe(
      map((response: object) => {
        return deserialize(PhoneValidationResponseEntity, response);
      }),
    );
  }

  /**
   * @param {string} userUuid
   * @param {string} phoneUuid
   * @returns {Observable<Phone>}
   */
  deletePhone(userUuid: string, phoneUuid: string): Observable<Phone> {
    const deletePhone$ = this._http.delete(EnvironmentHelper.fetchAPIBase('v1/phones/' + phoneUuid)).pipe(
      map((response: { phone: object }) => {
        return this._convertToNew(response.phone);
      }),
    );

    return this._userService.isSelf(userUuid).pipe(
      concatMap((isSelf: boolean) => {
        return deletePhone$.pipe(
          tap((deleted: Phone) => {
            if (isSelf) {
              const user = this._userStore.user;
              const phones = [...user.phones];
              user.phones = PhoneHelper.removePhone(phones, deleted.uuid);
              this._userStore.user = user;
            }
          }),
        );
      }),
    );
  }

  getPhoneCountryCodes(locale = 'no', invalidateCache: boolean = false): Observable<PhoneCountryCodeEntity[]> {
    if (
      this._phonesStoreService.phoneCountryCodes &&
      this._phonesStoreService.phoneCountryCodes.length &&
      !invalidateCache
    ) {
      // return from store
      return this._phonesStoreService.phoneCountryCodes$.pipe(
        map((codesInStore: PhoneCountryCodeEntity[]) => {
          return codesInStore;
        }),
        first(),
      );
    } else {
      // fetch from API
      return this._http
        .get(EnvironmentHelper.fetchAPIBase('v1/countries/' + locale + '/phone-codes'), {
          observe: 'response',
        })
        .pipe(
          shareReplay(1),
          map((response: HttpResponse<{ phoneCountryCodes: object[] }>) => {
            if (response.status === 204) {
              return [];
            } else {
              const fetchedCountryCodes = deserialize(PhoneCountryCodeEntity, response.body.phoneCountryCodes);
              this._phonesStoreService.phoneCountryCodes = fetchedCountryCodes;
              return fetchedCountryCodes;
            }
          }),
          first(),
        );
    }
  }

  /**
   * @param {string} userId
   * @param {string} phoneUuid
   * @param {Phone} newPhone
   * @returns {Observable<Phone>}
   */
  putPhone(userId: string, phoneUuid: string, newPhone: Phone): Observable<Phone> {
    const phone$ = this._http
      .put(EnvironmentHelper.fetchAPIBase('v1/phones/' + phoneUuid), {
        phone: serialize(newPhone),
      })
      .pipe(
        map((response: { phone: object }) => {
          return this._convertToNew(response.phone);
        }),
      );

    return this._userService.isSelf(userId).pipe(
      concatMap((isSelf: boolean) => {
        return phone$.pipe(
          tap((updatedPhone: Phone) => {
            if (isSelf) {
              const user = this._userStore.user;
              const existingPhones = [...user.phones];
              existingPhones[existingPhones.findIndex((phone: Phone) => phone.uuid === updatedPhone.uuid)] =
                updatedPhone;
              user.phones = existingPhones;
              this._userStore.user = user;
            }
          }),
        );
      }),
    );
  }

  /**
   * @param {string} userId
   * @param {string} number Phone number optionally with country code prefix
   * @returns {Observable<Phone>}
   */
  postPhone(userId: string, number: string): Observable<Phone> {
    const phone$ = this._http
      .post(EnvironmentHelper.fetchAPIBase('v1/phones'), {
        phone: { number },
        userUuid: userId,
      })
      .pipe(
        map((response: { phone: object }) => {
          return this._convertToNew(response.phone);
        }),
      );

    return this._userService.isSelf(userId).pipe(
      concatMap((isSelf: boolean) => {
        return phone$.pipe(
          tap((phone: Phone) => {
            if (isSelf) {
              const user = this._userStore.user;
              const existingPhones = [...user.phones];
              existingPhones.push(phone);
              user.phones = existingPhones;
              this._userStore.user = user;
            }
          }),
        );
      }),
    );
  }

  /**
   * @param {string} phoneUuid
   * @returns {Observable<Phone>}
   */
  sendVerificationSms(phoneUuid: string): Observable<Phone> {
    return this._http
      .post(EnvironmentHelper.fetchAPIBase('v1/phones/' + phoneUuid + '/send-verification-sms'), {})
      .pipe(
        map((response: { phone: object }) => {
          return this._convertToNew(response.phone);
        }),
      );
  }

  /**
   * @param {string} phoneUuid
   * @param userUuid
   * @returns {Observable<Phone>}
   */
  setPrimaryPhone(phoneUuid: string, userUuid: string): Observable<Phone> {
    const primaryPhone$ = this._http
      .post(EnvironmentHelper.fetchAPIBase('v1/phones/' + phoneUuid + '/set-primary'), {})
      .pipe(
        map((response: { phone: object }) => {
          return this._convertToNew(response.phone);
        }),
      );

    return this._userService.isSelf(userUuid).pipe(
      concatMap((isSelf: boolean) => {
        return primaryPhone$.pipe(
          tap((primary: Phone) => {
            if (isSelf) {
              const user = this._userStore.user;

              const existingPhones = [...user.phones];

              existingPhones.forEach((existingPhone: Phone) => {
                existingPhone.primary = existingPhone.uuid === primary.uuid;
              });
              user.phones = existingPhones;
              this._userStore.user = user;
            }
          }),
        );
      }),
    );
  }

  /**
   * @param {string} phoneUuid
   * @param {string} code
   * @param userUuid
   * @returns {Observable<Phone>}
   */
  verifyPhone(phoneUuid: string, code: string, userUuid: string): Observable<Phone> {
    const verifiedPhone$ = this._http
      .post(EnvironmentHelper.fetchAPIBase('v1/phones/' + phoneUuid + '/verify/' + code), undefined)
      .pipe(
        map((response: { phone: object }) => {
          return this._convertToNew(response.phone);
        }),
      );

    return this._userService.isSelf(userUuid).pipe(
      concatMap((isSelf: boolean) => {
        return verifiedPhone$.pipe(
          tap((verified: Phone) => {
            if (isSelf) {
              const user = this._userStore.user;
              const existingPhones = [...user.phones];
              existingPhones.forEach((e: Phone) => {
                // BLL dictates that a newly verified number becomes the primary
                e.primary = e.uuid === verified.uuid;
              });
              verified.verificationSent = true;
              existingPhones[existingPhones.findIndex((e: Phone) => e.uuid === verified.uuid)] = verified;
              user.phones = existingPhones;
              this._userStore.user = user;
            }
          }),
        );
      }),
    );
  }
}
