import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { PostLogoutRedirectUriEntity } from "@core/entities/oauth/post-logout-redirect-uri.entity";
import { ReactiveFormsHelper } from "@core/helpers/reactive-forms.helper";
import { ClientService } from "@core/services/client/client.service";
import { ModalService } from "@core/services/modal/modal.service";
import { FfConfirmModalDataInterface } from "@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal-data.interface";
import { FfConfirmModalComponent } from "@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal.component";
import { deserialize } from "serializr";

@Component({
  selector: "app-post-logout-redirect-uris",
  templateUrl: "./post-logout-redirect-uris.component.html",
  styleUrls: ["./post-logout-redirect-uris.component.scss"],
})
export class PostLogoutRedirectUrisComponent {
  @Input({ required: true })
  clientId!: string;

  @Output()
  deleteUri: EventEmitter<PostLogoutRedirectUriEntity> = new EventEmitter<PostLogoutRedirectUriEntity>();

  @Output()
  saveUri: EventEmitter<PostLogoutRedirectUriEntity> = new EventEmitter<PostLogoutRedirectUriEntity>();

  @Input({ required: true })
  uris!: Array<PostLogoutRedirectUriEntity>;

  formHelper = ReactiveFormsHelper;
  processing: boolean = false;
  showAddForm: boolean;
  uriForm: UntypedFormGroup;

  constructor(
    private _clientService: ClientService,
    private _fb: UntypedFormBuilder,
    private _modalService: ModalService
  ) {}

  confirmRemoval(uri: PostLogoutRedirectUriEntity) {
    const confirmContent: FfConfirmModalDataInterface = {
      component: FfConfirmModalComponent,
      title: "Er du sikker?",
      body:
        "Vil du faktisk slette videresendingsadressen med URI «" +
        uri.uri +
        "»?",
      confirmCallback: () => {
        this.delete(uri);
      },
    };

    this._modalService.openModal(confirmContent);
  }

  delete(uri: PostLogoutRedirectUriEntity) {
    uri.processing = true;

    this._clientService.deleteLogoutRedirect(uri.id).subscribe((redir) => {
      uri.processing = false;
      this.deleteUri.emit(redir);
    });
  }

  save() {
    if (this.uriForm.invalid) {
      return;
    }

    this.processing = true;

    this._clientService
      .postLogoutRedirect(
        this.clientId,
        deserialize(PostLogoutRedirectUriEntity, this.uriForm.value)
      )
      .subscribe(
        (uri) => {
          this.processing = false;
          this.saveUri.emit(uri);
          this.showAddForm = false;
        },
        () => {
          this.processing = false;
        }
      );
  }

  toggleAddForm() {
    this.uriForm = this.#buildUriGroup();
    this.processing = false;
    this.showAddForm = !this.showAddForm;
  }

  toggleDevUri(uri: PostLogoutRedirectUriEntity) {
    uri.isDevUri = !uri.isDevUri;
    this.#update(uri);
  }

  #buildUriGroup(): UntypedFormGroup {
    return this._fb.group({
      isDevUri: [true],
      uri: [null, [Validators.required]],
      processing: [false],
    });
  }

  #update(uri: PostLogoutRedirectUriEntity) {
    this._clientService
      .putLogoutRedirect(uri)
      .subscribe(() => {
        uri.processing = false;
      });
  }
}
