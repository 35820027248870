import { Component, Input } from '@angular/core';
import { FfActionTitleButtonEntity } from '@shared/components/ff-action-title/ff-action-title-button.entity';

@Component({
  selector: 'app-ff-action-title',
  templateUrl: './ff-action-title.component.html',
  styleUrls: ['./ff-action-title.component.scss'],
})
export class FfActionTitleComponent {
  @Input()
  buttons?: FfActionTitleButtonEntity[];

  @Input()
  title?: string;

  /**
   * If a callback was given, call it and apply bindings + args
   *
   * @param {FfActionTitleButtonEntity} b
   */
  fireCallback(b: FfActionTitleButtonEntity) {
    if (!b.callback || !b.callback.fn) {
      return;
    }

    b.callback.fn.apply(b.callback.bind, b.callback.args);
  }
}
