import { ProcessableInterface } from '@core/interfaces/processable.interface';
import { alias, identifier, serializable } from 'serializr';

export class RedirectUriEntity implements ProcessableInterface {
  processing: boolean;

  @serializable(alias('uuid', identifier()))
  private _id: string;

  @serializable(alias('isDevUri'))
  private _isDevUri: boolean;

  @serializable(alias('uri'))
  private _uri: string;

  /**
   * @returns {string}
   */
  get id(): string {
    return this._id;
  }

  /**
   * @returns {boolean}
   */
  get isDevUri(): boolean {
    return this._isDevUri;
  }

  /**
   * @param {boolean} value
   */
  set isDevUri(value: boolean) {
    this._isDevUri = value;
  }

  /**
   * @returns {string}
   */
  get uri(): string {
    return this._uri;
  }

  /**
   * @param {string} value
   */
  set uri(value: string) {
    this._uri = value;
  }
}
