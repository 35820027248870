/**
 * The available positions for the tail of the tooltip box
 *
 * @tutorial Usage:
 * <app-ff-tooltip [options]="{ arrowPosition: FfTooltipArrowPositions.bottomRight}" />
 * will yield:
 * <app-ff-tooltip><div class="tooltip tooltip--bottom-right" /></app-ff-tooltip>
 *
 * @see ff-tooltip.component.scss for the styles each position gets
 */
export enum FfTooltipArrowPositions {
  bottom = 'bottom',
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right',
  left = 'left',
  right = 'right',
  topLeft = 'top-left',
  top = 'top',
  topRight = 'top-right',
}
