/**
 *
 */
export class MainMenuItem {
  active: boolean = false;
  children?: Array<MainMenuItem> = [];
  iconPath?: string;
  label: string;
  method?: () => void;
  path: string;
  parent?: MainMenuItem;
  route?: string;

  constructor(label: string, path: string, iconPath?: string, route?: string, method?: () => void) {
    this.iconPath = iconPath;
    this.label = label;
    this.method = method;
    this.path = path;
    this.route = route;
  }

  addChild(item: MainMenuItem) {
    item.parent = this;
    this.children.push(item);
  }

  hasChildren(): boolean {
    return this.children && this.children.length > 0;
  }
}
