import { UserFeedbackTypesEnum } from '@core/enums/user-feedback-types.enum';

export class UserFeedbackEntity {
  private _message: string;
  private _type: UserFeedbackTypesEnum;

  /**
   * @param {string} message
   * @param {UserFeedbackTypesEnum} type
   */
  constructor(message: string, type: UserFeedbackTypesEnum = UserFeedbackTypesEnum.ERROR) {
    this.message = message;
    this.type = type;
  }

  get message(): string {
    return this._message;
  }

  /**
   * @param message
   */
  set message(message: string) {
    this._message = message;
  }

  get type(): UserFeedbackTypesEnum {
    return this._type;
  }

  set type(value: UserFeedbackTypesEnum) {
    this._type = value;
  }
}
