import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
} from '@angular/forms';
import { UserFeedbackEntity } from '@core/entities/user/user-feedback.entity';
import { UserFeedbackTypesEnum } from '@core/enums/user-feedback-types.enum';
import { FieldErrorMessageInterface } from '@core/interfaces/field-error-message.interface';
import { LogService } from '@core/services/log/log.service';

export class ReactiveFormsHelper {
  /**
   * @param form
   * @param formArrayName
   * @param formGroup
   */
  static addFormGroupToFormArray(form: UntypedFormGroup, formArrayName: string, formGroup = null) {
    ReactiveFormsHelper.getArray(form, formArrayName).push(formGroup);
  }

  /**
   * Read error constants on fields and compose error message for each, return as array
   *
   * @param {FieldErrorMessageInterface[]} fields
   * @returns {{errorCount: number, messages: UserFeedbackEntity[]}}
   */
  static composeErrorMessages(fields: FieldErrorMessageInterface[] = []): {
    errorCount: number;
    errorMessages: UserFeedbackEntity[];
  } {
    const errorMessages = [];
    let errorCount = 0;

    fields.forEach((field: FieldErrorMessageInterface) => {
      if (field.err && field.ctrl.hasError(field.err)) {
        errorCount++;
        errorMessages.push(new UserFeedbackEntity(field.msg, UserFeedbackTypesEnum.ERROR));
      } else if (field.ctrl.parent && field.ctrl.parent.invalid) {
        errorCount++;
      }
    });

    return { errorCount, errorMessages };
  }

  /**
   * Retrieve the given controls name as proper FormArray
   *
   * @param {UntypedFormGroup} formGroup
   * @param {Array<string> | string} path A dot-delimited string or array of strings that define the
   *  path to the array relative to given formGroup.
   * @example (userForm, 'names.firstName')
   * @returns {UntypedFormArray}
   */
  static getArray(formGroup: UntypedFormGroup, path: Array<string> | string): UntypedFormArray {
    return formGroup.get(path) as UntypedFormArray;
  }

  /**
   * Return given ctrl as FormArray
   *
   * @param {AbstractControl} ctrl
   * @returns {UntypedFormArray}
   */
  static getAsArray(ctrl: AbstractControl): UntypedFormArray {
    return ctrl as UntypedFormArray;
  }

  /**
   * Return given ctrl as FormControl
   *
   * @param {AbstractControl} ctrl
   * @returns {UntypedFormControl}
   */
  static getAsCtrl(ctrl: AbstractControl): UntypedFormControl {
    return ctrl as UntypedFormControl;
  }

  /**
   * Return given ctrl as FormGroup
   *
   * @param {AbstractControl} ctrl
   * @returns {UntypedFormGroup}
   */
  static getAsGroup(ctrl: AbstractControl): UntypedFormGroup {
    return ctrl as UntypedFormGroup;
  }

  /**
   * Retrieve the given controls name as proper FormArray
   *
   * @param {UntypedFormGroup} formGroup
   * @param {Array<string> | string} path A dot-delimited string or array of strings that define the
   *  path to the array relative to given formGroup.
   * @example (userForm, 'names.firstName')
   * @returns {UntypedFormControl}
   */
  static getCtrl(formGroup: UntypedFormGroup, path: Array<string> | string): UntypedFormControl {
    return formGroup.get(path) as UntypedFormControl;
  }

  /**
   * Retrieve the given controls name as proper FormArray
   *
   * @param {UntypedFormGroup} formGroup
   * @param {Array<string> | string} path A dot-delimited string or array of strings that define the
   *  path to the array relative to given formGroup.
   * @example (userForm, 'names.firstName')
   * @returns {UntypedFormGroup}
   */
  static getGroup(formGroup: UntypedFormGroup, path: Array<string> | string): UntypedFormGroup {
    return formGroup.get(path) as UntypedFormGroup;
  }

  /**
   * Iterates over each FormControl in given FormGroup, returns matching string for every found error
   * If also supplied logService, will log any missing keys.
   *
   * @param {UntypedFormGroup} reactiveForm
   * @param {object} stringsObject - Syntax for string name: FIELDNAME_ERRORKEY - i.e: PHONE_REQUIRED
   * @param {LogService} logService
   * @returns {UserFeedbackEntity[]}
   */
  static getErrorStrings(
    reactiveForm: UntypedFormGroup,
    stringsObject: object,
    logService?: LogService,
  ): UserFeedbackEntity[] {
    const errorStrings = [];
    ReactiveFormsHelper.getFormErrors(reactiveForm, [], '_').forEach((error, name) => {
      Object.keys(error).forEach((errorKey) => {
        const keyStr = name.toUpperCase() + '_' + errorKey.toUpperCase();

        if (stringsObject[keyStr]) {
          errorStrings.push(new UserFeedbackEntity(stringsObject[keyStr], UserFeedbackTypesEnum.ERROR));
        } else if (logService) {
          logService.logException(
            new Error('Key ' + keyStr + ' not found in "stringsObject" passed to getErrorStrings()'),
          );
        }
      });
    });

    return errorStrings;
  }

  /**
   * Recursively walk through given formGroup and retrieve all FormControls that have an error
   */
  static getFormErrors(
    formGroup: UntypedFormGroup,
    prefixes: Array<string> = [],
    fieldSeparator = '_',
  ): Map<string, ValidationErrors> {
    let errors: Map<string, ValidationErrors> = new Map();
    const prefix = prefixes.length > 0 ? prefixes.join(fieldSeparator) + fieldSeparator : '';

    Object.keys(formGroup.controls).forEach((ctrlName) => {
      const control = formGroup.get(ctrlName);

      if (control instanceof UntypedFormControl) {
        if (control.errors) {
          errors.set(prefix + ctrlName, control.errors);
        }
      } else if (control instanceof UntypedFormArray) {
        control.controls.forEach((group: UntypedFormGroup) => {
          errors = new Map([
            ...Array.from(errors.entries()),
            ...Array.from(this.getFormErrors(group, [...prefixes, ctrlName], fieldSeparator).entries()),
          ]);
        });
      } else if (control instanceof UntypedFormGroup) {
        errors = new Map([
          ...Array.from(errors.entries()),
          ...Array.from(this.getFormErrors(control, [...prefixes, ctrlName], fieldSeparator).entries()),
        ]);
      }
    });

    return errors;
  }

  /**
   * Recursively walk through given formGroup and mark all FormControls as touched
   * @param formGroup
   * TODO: Check if obsolete in Angular 8, ref https://github.com/angular/angular/pull/26812
   */
  static markAllFormControlsTouched(formGroup: UntypedFormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormArray) {
        control.controls.forEach((row) => this.markAllFormControlsTouched(row as UntypedFormGroup));
      } else if (control instanceof UntypedFormGroup) {
        this.markAllFormControlsTouched(control);
      }
    });
  }
}
