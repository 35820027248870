<section class="card">
  <h2>Navn og adresse</h2>

  @if (nameAddrForm.disabled) {
    <p>
      <strong> {{ nameAddrForm.value.names.firstName }} {{ nameAddrForm.value.names.lastName }} </strong>
    </p>
    @for (address of nameAddrForm.value.postalAddresses; track address) {
      <div class="address-label">
        @if (address.line1) {
          <p>{{ address.line1 }}</p>
        }
        @if (address.line2) {
          <p>{{ address.line2 }}</p>
        }
        @if (address.line3) {
          <p>{{ address.line3 }}</p>
        }
        @if (address.postalCode || address.postalArea) {
          <p>{{ address.postalCode }} {{ address.postalArea }}</p>
        }
        @if (address.country) {
          <p>{{ address.country }}</p>
        }
      </div>
    }
    <app-ff-button class="inline padded small" (clicked)="nameAddrForm.enable()" color="sand">Rediger</app-ff-button>
  }

  @if (nameAddrForm.enabled) {
    <form class="form" [class.submitted]="submitted" [formGroup]="nameAddrForm">
      <app-user-name-form [nameForm]="nameAddrForm.controls.names"></app-user-name-form>
      <div formArrayName="postalAddresses" class="form-array form-array--addresses">
        @for (addressForm of nameAddrForm.controls.postalAddresses.controls; track addressForm; let idx = $index) {
          <div
            class="form-group form-group--address"
            formGroupName="{{ idx }}"
            >
            <div class="form-control">
              <app-address-form [formGroup]="addressForm" [index]="idx" [user]="user"></app-address-form>
            </div>
          </div>
        }
      </div>
      <app-ff-button (clicked)="submitForm()" class="inline large padded" color="primary" [processing]="processing"
        >Lagre</app-ff-button
        >
        <app-ff-button (clicked)="cancel()" class="inline large padded" color="secondary">Avbryt</app-ff-button>
      </form>
    }
  </section>

  <app-feedback-messages #feedbackMessagesRef [messages]="userMessages$ | async"></app-feedback-messages>
