<!-- existing / none -->
<ul class="existing">
  @if (urls.length < 1) {
    <li class="redirect well">Ingen ugyldiggjøringsadresser angitt</li>
  }

  @for (url of urls; track url; let idx = $index) {
    <li class="redirect well">
      <app-ff-delete-button
        (clicked)="confirmRemoval(url)"
        [processing]="url.processing"
        class="delete"
        titleText="Slett denne ugyldiggjøringsadressen"
      ></app-ff-delete-button>
      <div class="uri">{{ url.url }}</div>
    </li>
  }
</ul>

<!-- Add new -->
@if (showAddForm) {
  <form [formGroup]="uriForm" class="form well new-redirect">
    <h3>Ny ugyldiggjøringsadresse</h3>
    <div class="subtitle">Oppgi URL for ugyldiggjøringsadresse</div>
    <div class="form-group">
      <div class="form-control redirect-uri">
        <input
          (keyup.enter)="save()"
          [formControl]="formHelper.getCtrl(uriForm, 'url')"
          class="uri"
          id="new_redir_uri"
          inputmode="url"
          placeholder="URL"
          type="text"
          />
      </div>
    </div>
    <div class="buttons">
      <app-ff-button
        (clicked)="save()"
        [processing]="processing"
        class="inline padded"
        label="Legg til ugyldiggjøringsadresse"
      ></app-ff-button>
      <app-ff-button
        (clicked)="toggleAddForm()"
        [processing]="processing"
        class="inline padded"
        color="gray"
        label="Avbryt"
      ></app-ff-button>
    </div>
    <app-feedback-messages #feedbackMessagesRef [messages]="feedbackMessages"></app-feedback-messages>
  </form>
}

<!-- New trigger toggle -->
@if (!showAddForm) {
  <div class="buttons">
    <app-ff-button
      (clicked)="toggleAddForm()"
      (keyup.enter)="toggleAddForm()"
      class="inline padded"
      label="Legg til ugyldiggjøringsadresse"
    ></app-ff-button>
  </div>
}
