import { AbstractControl, ValidatorFn } from '@angular/forms';

function isEmptyInputValue(value: any): boolean {
  return value === null || value.length === 0;
}

export class UniqueValidator {
  static readonly ERROR_DUPLICATE = 'duplicate';

  /**
   * @param {any[]} arr
   * @returns {ValidatorFn}
   */
  static uniqueInArray(arr: any[]): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value || isEmptyInputValue(control.value)) {
        return null;
      }

      return arr.some((r) => r === control.value) ? { [UniqueValidator.ERROR_DUPLICATE]: true } : null;
    };
  }
}
