<div class="primary-label">{{ email.primary ? 'Hovedadresse' : 'Reserveadresse' }}.</div>
<div class="email-label">
  {{ email.emailAddress }}
  @if (processing) {
    <app-ff-loading-indicator
      class="well__header__indicator stroke--mine-shaft"
      size="small"
    ></app-ff-loading-indicator>
  }
</div>
<div class="verified-label">
  <svg-icon
    [applyClass]="true"
    [src]="'assets/icons/circled_' + (email.verified ? 'check_mark' : 'x') + '_24.svg'"
    class="footer__icon s18 monochrome stroke-text-100 no-fill"
  ></svg-icon>
  <span>{{ email.verified ? 'Bekreftet' : 'Ubekreftet' }}.</span>
</div>

<div class="actions">
  <app-ff-menu-button triggerIcon="icons/ellipsis.svg" [triggerIconSize]="18" triggerText="Valg">
    @if (email.verified && !email.primary) {
      <app-ff-menu-item
        (clicked)="setPrimary()"
        iconPath="icons/medal.svg"
        label="Sett som primær e-post"
      ></app-ff-menu-item>
    }

    @if (!isAdminContext && !email.verified) {
      <app-ff-menu-item
        (clicked)="sendVerification()"
        iconPath="icons/arrow_circular.svg"
        label="Send ny e-post for bekreftelse"
      ></app-ff-menu-item>
    }

    @if (!formDisabled && (isAdminContext || (!isLastRemaining && !email.primary))) {
      <app-ff-menu-item
        (clicked)="deleteEmail(index)"
        iconPath="icons/trash.svg"
        label="Fjern denne"
      ></app-ff-menu-item>
    }

    @if (!isAdminContext && (isLastRemaining || email.primary)) {
      <app-ff-menu-item
        [disabled]="true"
        iconPath="icons/trash.svg"
        label="Kan ikke slette hovedadresse"
      ></app-ff-menu-item>
    }
  </app-ff-menu-button>
</div>

@if (email.verificationSent) {
  <div aria-live="assertive" class="well well--notice verify">
    <app-svg
      [heightPx]="48"
      [widthPx]="48"
      class="icon"
      role="presentation"
      svgPath="icons/envelope_open_check.svg"
    ></app-svg>
    <div>
      Vi har sendt en e-post til <strong>{{ email.emailAddress || 'din adresse' }}</strong
      >. Følg lenken i e-posten for å bekrefte e-postadressen.
    </div>
  </div>
}
