import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { Email } from '@core/entities/user/email.entity';
import { User } from '@core/entities/user/user.entity';
import { ProcessableInterface } from '@core/interfaces/processable.interface';
import { EmailService } from '@core/services/user/email.service';
import { finalize, tap } from 'rxjs';

@Component({
  selector: 'app-existing-email',
  templateUrl: './existing-email.component.html',
  styleUrls: ['./existing-email.component.scss'],
})
export class ExistingEmailComponent implements ProcessableInterface {
  @Input()
  email: Email;

  @Input()
  formDisabled?: boolean;

  @Input()
  index: number;

  @Input()
  isAdminContext = false;

  @Input()
  isOptional?: boolean = false;

  @Output()
  primaryUpdated = new EventEmitter<Email>();

  @Output()
  emailDeletedAt = new EventEmitter<number>();

  @Input()
  selfUser: User;

  @Input()
  user: User;

  processing: boolean = false;

  constructor(private readonly _emailService: EmailService, private _cdr: ChangeDetectorRef) {}

  get isLastRemaining(): boolean {
    return this.user.emails.length < 2;
  }

  deleteEmail(index: number) {
    if (this.processing) {
      return;
    }

    this.processing = true;

    this._emailService
      .deleteEmail(this.user.uuid, this.email.uuid)
      .pipe(
        tap(() => {
          this.emailDeletedAt.emit(index);
        }),
        finalize(() => {
          this.processing = false;
          this._cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  sendVerification() {
    this.processing = true;
    this._emailService
      .sendVerificationEmail(this.email.uuid)
      .pipe(
        tap(() => {
          this.email.verificationSent = true;
        }),
        finalize(() => {
          this.processing = false;
          this._cdr.detectChanges();
        }),
      )
      .subscribe();
  }

  setPrimary() {
    this.processing = true;
    this._emailService
      .setPrimaryEmail(this.email.uuid, this.user.uuid)
      .pipe(
        tap(() => {
          this.primaryUpdated.emit(this.email);
          this.processing = false;
        }),
        finalize(() => {
          this.processing = false;
          this._cdr.detectChanges();
        }),
      )
      .subscribe();
  }
}
