import {
  ClientErrorResponse,
  ClientErrorResponseSchema,
} from '@core/entities/http-responses/client-error-response.entity';

export class ForbiddenResponse extends ClientErrorResponse {}

export const ForbiddenResponseSchema = {
  factory: () => new ForbiddenResponse(),
  extends: ClientErrorResponseSchema,
  props: {},
};
