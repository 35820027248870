import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { RedirectUriEntity } from "@core/entities/oauth/redirect-uri.entity";
import { ReactiveFormsHelper } from "@core/helpers/reactive-forms.helper";
import { ClientService } from "@core/services/client/client.service";
import { ModalService } from "@core/services/modal/modal.service";
import { FfConfirmModalDataInterface } from "@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal-data.interface";
import { FfConfirmModalComponent } from "@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal.component";
import { deserialize } from "serializr";

@Component({
  selector: "app-redirect-uris",
  templateUrl: "./redirect-uris.component.html",
  styleUrls: ["./redirect-uris.component.scss"],
})
export class RedirectUrisComponent {
  @Input({ required: true })
  clientId!: string;

  @Output()
  deleteUri: EventEmitter<RedirectUriEntity> = new EventEmitter<RedirectUriEntity>();

  @Output()
  saveUri: EventEmitter<RedirectUriEntity> = new EventEmitter<RedirectUriEntity>();

  @Input({ required: true })
  uris!: Array<RedirectUriEntity>;

  formHelper = ReactiveFormsHelper;
  processing: boolean = false;
  showAddForm: boolean;
  uriForm: UntypedFormGroup;

  constructor(
    private _clientService: ClientService,
    private _fb: UntypedFormBuilder,
    private _modalService: ModalService
  ) {}

  confirmRemoval(uri: RedirectUriEntity) {
    const confirmContent: FfConfirmModalDataInterface = {
      component: FfConfirmModalComponent,
      title: "Er du sikker?",
      body:
        "Vil du faktisk slette videresendingsadressen med URI «" +
        uri.uri +
        "»?",
      confirmCallback: () => {
        this.delete(uri);
      },
    };

    this._modalService.openModal(confirmContent);
  }

  delete(uri: RedirectUriEntity) {
    uri.processing = true;

    this._clientService.deleteLoginRedirect(uri.id).subscribe(
      (redir) => {
        uri.processing = false;
        this.deleteUri.emit(redir);
      },
      () => {
        this.processing = false;
      }
    );
  }

  save() {
    if (this.uriForm.invalid) {
      return;
    }

    this.processing = true;

    this._clientService
      .postLoginRedirect(
        this.clientId,
        deserialize(RedirectUriEntity, this.uriForm.value)
      )
      .subscribe(
        (uri) => {
          this.processing = false;
          this.saveUri.emit(uri);
          this.showAddForm = false;
        },
        () => {
          this.processing = false;
        }
      );
  }

  toggleAddForm() {
    this.uriForm = this.#buildUriGroup();
    this.processing = false;
    this.showAddForm = !this.showAddForm;
  }

  toggleDevUri(uri: RedirectUriEntity) {
    uri.isDevUri = !uri.isDevUri;
    this.#update(uri);
  }

  #buildUriGroup(): UntypedFormGroup {
    return this._fb.group({
      isDevUri: [true],
      uri: [null, [Validators.required]],
      processing: [false],
    });
  }

  #update(uri: RedirectUriEntity) {
    this._clientService
      .putLoginRedirect(uri)
      .subscribe(() => {
        uri.processing = false;
      });
  }
}
