<div class="form-control">
  <label for="ssn">Fødselsnummer</label>
  <input
    (input)="changed.emit()"
    [formControl]="ssnFormCtrl"
    id="ssn"
    inputmode="numeric"
    placeholder="Fødselsnr (11 siffer)"
    type="text"
  />
</div>
