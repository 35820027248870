<ul class="clients">
  @if (!clients || clients.length < 1) {
    <li class="client well">
      <span>Ingen klienter</span>
    </li>
  }

  @for (client of clients; track client) {
    <li class="client well">
      <div class="client__info">
        <div class="client__name">
          @if (linkedTitle) {
            <a [routerLink]="'/clients/' + client.type + '/' + client.id">{{ client.name }}</a>
          }
          @if (!linkedTitle) {
            <span>{{ client.name }}</span>
          }
        </div>
        <div class="client__details">
          <div class="detail detail--description">
            <svg-icon [applyClass]="true" src="assets/icons/document_a_24.svg" role="presentation"></svg-icon>
            <span>{{ client.description }}</span>
          </div>
          <div class="detail detail--client-type">
            @if (client.type === 'sso') {
              <svg-icon
                [applyClass]="true"
                src="assets/icons/key.svg"
                title="Type SSO"
                role="presentation"
              ></svg-icon>
            }
            @if (client.type === 'oauth') {
              <svg-icon
                [applyClass]="true"
                src="assets/icons/openid.svg"
                title="Type OAuth"
                role="presentation"
              ></svg-icon>
            }
            <span>{{ client.type === 'oauth' ? 'OAuth' : 'SSO' }}</span>
          </div>
          <div class="detail detail--client-active">
            <svg-icon
              [applyClass]="true"
              [src]="'assets/icons/' + (client.active ? 'circled_check_mark_24' : 'circled_x_24') + '.svg'"
              role="presentation"
            ></svg-icon>
            <span>{{ client.active ? 'Aktiv' : 'Inaktiv' }}</span>
          </div>
          <div class="detail detail--client-devmode">
            @if (client.devMode) {
              <svg-icon
                [applyClass]="true"
                [src]="'assets/icons/person_hardhat.svg'"
                role="presentation"
              ></svg-icon>
            }
            @if (client.devMode) {
              <span>DevMode</span>
            }
          </div>
        </div>
      </div>
      @if (!hideDeleteBtn) {
        <app-ff-delete-button
          (clicked)="confirmRemoval(client)"
          [disabled]="!linkedTitle"
          [processing]="client.processing"
          class="client__remove"
          titleText="Fjern"
        ></app-ff-delete-button>
      }
    </li>
  }
</ul>

@if (processing) {
  <div class="processing">
    <app-ff-loading-indicator class="stroke--mine-shaft" size="s20"></app-ff-loading-indicator>
  </div>
}
