<app-svg class="logo" svgPath="brand/logo_ff.svg" [heightPx]="32" role="presentation" [widthPx]="174"></app-svg>

<a
  (mouseenter)="hover = true"
  (mouseleave)="hover = false"
  class="weblink"
  href="https://www.fagforbundet.no"
  title="Til Fagforbundets startside"
>
  <app-svg
    [class.active]="hover"
    class="icon"
    [heightPx]="20"
    role="presentation"
    svgPath="icons/home.svg"
    [widthPx]="20"
  ></app-svg>

  <span>Til fagforbundet.no</span>
</a>
