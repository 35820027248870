import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Email } from '@core/entities/user/email.entity';
import { User } from '@core/entities/user/user.entity';
import { EmailHelper } from '@core/helpers/email.helper';
import { EmailValidator } from '@shared/validators/email.validator';
import { UserFormBuilder } from '../user-form.factory';
import { EmailForm } from '../types/emails-form.type';
import { UserForm } from '../types/user-form.type';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmailsComponent implements OnInit {
  @Input()
  emailsFormArray: FormArray<FormGroup<EmailForm>>;

  @Input()
  isAdminContext = false;

  @Input()
  isOptional = false;

  @Input()
  selfUser?: User | undefined; // If editing other user, the user who's editing goes here

  @Input()
  user: User;

  @Output()
  validateFormEvent = new EventEmitter<boolean>();

  constructor(private _cdr: ChangeDetectorRef, private _ufb: UserFormBuilder) {}

  get parentGroup(): FormGroup<UserForm> {
    return this.emailsFormArray.parent as FormGroup<UserForm>;
  }

  ngOnInit() {
    /**
     * https://stackoverflow.com/a/38937802
     * Use timeout to delay one tick to avoid
     * ExpressionChangeAfterItWasCheckedError
     * on [formGroup]="emailsFormArray.parent"
     */
    setTimeout((_) => {
      this._buildEmails();
    });
  }

  /**
   * For use when removing an email that wasn't saved yet (aka. missing uuid)
   *
   * @param {number} emailIdx
   */
  cancelledEmail(emailIdx: number): void {
    this.emailsFormArray.controls.splice(emailIdx, 1);
    this.emailsFormArray.updateValueAndValidity();
    this.validateForm();
  }

  newEmail(): void {
    this.emailsFormArray.push(this._ufb.Email(null, !this.isOptional, this.user.emails, true));
  }

  removeEmailAt(index: number): void {
    this.user.emails.splice(index, 1);
  }

  primaryUpdated(updated: Email): void {
    this.user.emails.forEach((ex) => (ex.primary = ex.uuid === updated.uuid));
    this.user.emails = [...this.user.emails];
  }

  validateForm(): void {
    this.validateFormEvent.emit(true);
  }

  /**
   * May be removed after Fane2 no longer in use
   */
  private _addExistingUserEmails(): void {
    if (this.emailsFormArray.enabled) {
      this.user.emails.forEach((email, index) => {
        if (!EmailValidator.isValidEmailSyntax(email.emailAddress)) {
          this.user.emails.splice(index, 1);
          this.emailsFormArray.push(this._ufb.Email(email));
        }
      });
    }
  }

  private _buildEmails(): void {
    this._addExistingUserEmails();

    if (this.user.emails.length === 0 && this.emailsFormArray.length === 0 && this.emailsFormArray.enabled) {
      this.emailsFormArray.push(this._ufb.Email(null, !this.isOptional));
    }
    EmailHelper.sortEmails(this.user.emails);
    this._cdr.detectChanges();
  }
}
