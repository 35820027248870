<form class="form" [class.submitted]="submitted" [formGroup]="nameForm">
  <div class="form-group" [formGroup]="nameForm">
    <div class="form-control form-control--dual">
      <label for="first-name">Fornavn</label>
      <input
        [formControl]="nameForm.controls.firstName"
        [readOnly]="readonly ? true : null"
        autocomplete="given-name"
        data-cy="input-first-name"
        id="first-name"
        name="fname"
        type="text"
      />
    </div>

    <div class="form-control form-control--dual">
      <label for="last-name">Etternavn</label>
      <input
        [formControl]="nameForm.controls.lastName"
        [readOnly]="readonly ? true : null"
        autocomplete="family-name"
        data-cy="input-last-name"
        id="last-name"
        name="lname"
        type="text"
      />
    </div>
  </div>
</form>
