import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { SsnValidationResponseEntity } from '@core/entities/response/ssn-validation-response.entity';
import { ValidationViolationResponseEntity } from '@core/entities/response/validation-violation-response.entity';
import { UserService } from '@core/services/user/user.service';
import { validateNin } from '@halloverden/number-utilities-ts';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

export class NinValidator {
  static readonly ERROR_REQUIRED = 'required';
  static readonly ERROR_INVALID = 'invalid';
  static readonly ERROR_SYNTAX_INVALID = 'syntax_invalid';
  static readonly ERROR_TAKEN = 'taken';

  /**
   * @param {AbstractControl} control
   * @returns {ValidationErrors | null}
   */
  static ninSyntaxInvalid(control: AbstractControl): ValidationErrors | null {
    return !validateNin(control.value) ? { [NinValidator.ERROR_SYNTAX_INVALID]: true } : null;
  }

  /**
   * @param {UserService} userService
   * @param selfNin
   * @returns {AsyncValidatorFn}
   */
  static ninTaken(userService: UserService, selfNin: string = null): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.value.length < 1) {
        return of({ [NinValidator.ERROR_REQUIRED]: true });
      }

      // No problem as long as the value equals current user nin
      if (selfNin && control.value === selfNin) {
        return of(null);
      }

      // Else check if API has user with given nin
      return userService.existsBySsn(control.value).pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map((exists: boolean) => {
          return exists ? { [NinValidator.ERROR_TAKEN]: true } : null;
        }),
      );
    };
  }

  static ninApiValidate(userService: UserService, selfNin: string): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      if (!control.value || control.value.length < 1) {
        return of({ [NinValidator.ERROR_REQUIRED]: true });
      }

      // No problem as long as the value equals current user nin
      if (selfNin && control.value === selfNin) {
        return of(null);
      }

      // Else check if API allows nin
      return userService.checkValidSsn(control.value).pipe(
        debounceTime(300),
        distinctUntilChanged(),
        map((response: SsnValidationResponseEntity) => {
          if (!response.valid) {
            const errors: ValidationErrors = { [ApiErrors.INVALID_SSN]: true };
            response.violations.forEach((v: ValidationViolationResponseEntity) => {
              const errKey = v.errorName.toUpperCase();
              if (ApiErrors[errKey]) {
                errors[ApiErrors[errKey]] = true;
              } else {
                throw new Error('Unhandled API response: NinValidator.ApiErrors is missing key ' + errKey);
              }
            });
            return errors;
          } else {
            return null;
          }
        }),
      );
    };
  }
}

enum ApiErrors {
  INVALID_SSN = 'api_invalid_nin',
}
