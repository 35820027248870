import { Component, HostBinding, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import {
  FfLoadingScreenService,
  LoadingScreenDependencies,
} from '@shared/components/ff-loading-screen/ff-loading-screen.service';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-ff-loading-screen',
  templateUrl: './ff-loading-screen.component.html',
  styleUrls: ['./ff-loading-screen.component.scss'],
})
export class FfLoadingScreenComponent implements OnInit {
  @HostBinding('attr.role')
  role: string = 'dialog';

  hidden: boolean = true;

  /**
   * @param loadingScreenService
   * @param router
   */
  constructor(private loadingScreenService: FfLoadingScreenService, private router: Router) {}

  ngOnInit() {
    this._listenToStateChange();
    this._handleRoutingEvents();
  }

  private _handleRoutingEvents() {
    this.router.events
      .pipe(
        tap((event) => {
          switch (event.constructor.name) {
            case NavigationCancel.name:
              this.loadingScreenService.removeDependant(LoadingScreenDependencies.NAVIGATION);
              break;
            case NavigationEnd.name:
              this.loadingScreenService.removeDependant(LoadingScreenDependencies.NAVIGATION);
              break;
            case NavigationError.name:
              this.loadingScreenService.removeDependant(LoadingScreenDependencies.NAVIGATION);
              break;
            case NavigationStart.name:
              this.loadingScreenService.addDependant(LoadingScreenDependencies.NAVIGATION);
              break;
          }
        }),
      )
      .subscribe();
  }

  private _listenToStateChange() {
    this.loadingScreenService
      .getSubject()
      .pipe(
        tap((state: boolean) => {
          this.hidden = state;

          if (this.hidden) {
            document.body.classList.remove('no-scroll');
          } else {
            document.body.classList.add('no-scroll');
          }
        }),
      )
      .subscribe();
  }
}
