import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-ff-well',
  templateUrl: './ff-well.component.html',
  styleUrls: ['./ff-well.component.scss'],
})
export class FfWellComponent {
  @Input()
  iconPath?: string;

  @Input()
  iconSize?: number = 48;

  @Input()
  msg: string;

  @HostBinding()
  class: string = 'well well--info';
}
