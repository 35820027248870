<h2 data-cy="emails-title">E-post</h2>

@if (emailsFormArray.parent) {
  <div [formGroup]="parentGroup" class="all-emails-list">
    <!-- User emails -->
    @if (user.emails.length) {
      <div class="form-group form-group--email existing">
        @for (email of user.emails; track email; let idx = $index) {
          <app-existing-email
            (primaryUpdated)="primaryUpdated($event)"
            (emailDeletedAt)="removeEmailAt($event)"
            [email]="email"
            [formDisabled]="emailsFormArray.disabled"
            [index]="idx"
            [isAdminContext]="isAdminContext"
            [isOptional]="isOptional"
            [selfUser]="selfUser"
            [user]="user"
          ></app-existing-email>
        }
      </div>
    }
    <!-- Form emails -->
    @if (emailsFormArray?.controls?.length > 0) {
      <div class="form-array form-array--emails" formArrayName="emails">
        @for (email of emailsFormArray.controls; track email; let idx = $index) {
          <div
            class="form-group form-group--email"
            formGroupName="{{ idx }}"
            >
            <div class="form-control">
              <app-email-form
                (changed)="validateForm()"
                (removed)="cancelledEmail($event)"
                [formGroupCtrl]="email"
                [index]="idx"
                [isAdminContext]="isAdminContext"
                [isOptional]="isOptional"
                [user]="user"
              ></app-email-form>
            </div>
          </div>
        }
      </div>
    }
    @if (!emailsFormArray.length) {
      <div class="buttons">
        <!-- New email -->
        @if (emailsFormArray.enabled) {
          <app-ff-button
            (clicked)="newEmail()"
            color="sand"
            class="inline padded"
            label="Legg til e-postadresse"
          ></app-ff-button>
        }
      </div>
    }
  </div>
}
