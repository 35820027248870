<ul class="ff-menu" #menuRef>
  @for (item of items; track item) {
    <li class="menu__item path-{{ item.path }}" [class.menu__item--heading]="!item.parent">
      <a
        (click)="itemClicked(item)"
        (keyup.enter)="itemClicked(item)"
        [routerLink]="getRoute(item)"
        [routerLinkActive]="item.route ? 'menu__item__trigger--active' : ''"
        class="menu__item__trigger"
        [class.menu__item__trigger--active]="item.active"
        [class.menu__item__trigger--routeless]="!getRoute(item)"
        tabindex="0"
        >
        <label>{{ item.label }}</label>
        @if (item.hasChildren() && !isExpanded && !item.active) {
          <span>&nbsp;›</span>
        }
        @if (item.iconPath) {
          <app-svg
            [class.active]="item.active"
            [heightPx]="iconSize"
            [svgPath]="item.iconPath"
            [widthPx]="iconSize"
            class="icon"
            role="presentation"
          ></app-svg>
        }
      </a>
      @if (item.hasChildren()) {
        <ul class="menu__level2 menu menu--sub">
          @for (sub of item.children; track sub) {
            <li class="menu__item">
              <a
                (click)="itemClicked(sub)"
                (keyup.enter)="itemClicked(sub)"
                [routerLink]="[sub.route]"
                [routerLinkActive]="'menu__item__trigger--active'"
                class="menu__item__trigger"
                tabindex="0"
                >
                <label>{{ sub.label }}</label>
              </a>
            </li>
          }
        </ul>
      }
    </li>
  }
</ul>
