import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FfModalBaseClassDirective } from '@shared/components/ff-modals/ff-modal-base.class';
import { FfModalDataInterface } from '@shared/components/ff-modals/ff-modal-data.interface';

@Component({
  selector: 'app-ff-modal-host',
  templateUrl: './ff-modal-host.component.html',
  styleUrls: ['./ff-modal-host.component.scss'],
})
export class FfModalHostComponent implements OnDestroy, OnInit {
  @Output()
  closeModal: EventEmitter<void> = new EventEmitter();

  @Input()
  data: FfModalDataInterface;

  @ViewChild('modalBodyRef', { static: true, read: ViewContainerRef })
  modalBodyElement: ViewContainerRef;

  hostClass = 'ff-modal-host';

  constructor(private _factoryResolver: ComponentFactoryResolver) {}

  ngOnDestroy(): void {
    this.close();
  }

  ngOnInit(): void {
    this._setDefaultOptions();

    const factory: ComponentFactory<FfModalBaseClassDirective> =
      this._factoryResolver.resolveComponentFactory<FfModalBaseClassDirective>(this.data.component);
    const modal: ComponentRef<FfModalBaseClassDirective> =
      this.modalBodyElement.createComponent<FfModalBaseClassDirective>(factory);
    modal.instance.data = this.data;

    modal.instance.closeModal.subscribe(() => {
      this.close();
    });
  }

  close(): void {
    this.closeModal.emit();
  }

  @HostListener('click', ['$event'])
  outsideClick(event: MouseEvent): void {
    if ((event.target as HTMLElement).classList.contains(this.hostClass) && this.data.closeOnClickOutside) {
      this.close();
    }
  }

  private _setDefaultOptions(): void {
    this.data = {
      ...({
        component: null,
        closeOnClickOutside: true,
        showCloseButton: true,
      } as FfModalDataInterface), // Set defaults
      ...this.data, // Override with actual
    };
  }
}
