import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-ff-delete-button',
  templateUrl: './ff-delete-button.component.html',
  styleUrls: ['./ff-delete-button.component.scss'],
})
export class FfDeleteButtonComponent implements OnChanges {
  /**
   * This callback is fired when host element was clicked using mouse or Keypress Enter
   */
  @Output()
  clicked: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('attr.disabled')
  disabledAttr: null | true = null;

  /**
   * For text next to icon
   */
  @Input()
  labelText?: string;

  @HostBinding('class.processing')
  @Input()
  processing = false;

  @HostBinding('attr.role')
  @Input()
  role = 'button';

  /**
   * Which icon size to display
   */
  @HostBinding('attr.data-size')
  @Input()
  size: 's16' | 's20' | 's24' = 's20';

  /**
   * Color of SVG stroke, in Hex
   */
  @Input()
  stroke?: string = '#3c3c3b';

  @HostBinding('tabindex')
  @Input()
  tabindex = '0';

  /**
   * Title text to add to host element. Default: "Slett"
   */
  @HostBinding('attr.title')
  @Input()
  titleText?: string = 'Slett';

  disabledProp: boolean = false;

  /**
   * Quirk: html disabled=false still ends up disables,
   * but disabled=null omits the attrib like we want.
   * Separate the property and the attribute binding to
   * provide a clean component api to outside.
   */
  @Input()
  set disabled(val: boolean) {
    this.disabledProp = !!val;
    this.disabledAttr = this.disabledProp ? true : null;
  }

  /**
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disabled'] && !changes['disabled'].isFirstChange()) {
      this.disabled = !!changes['disabled'].currentValue;
    }

    if (changes['processing'] && !changes['processing'].isFirstChange()) {
      this.processing = !!changes['processing'].currentValue;
    }
  }

  @HostListener('click', ['$event'])
  @HostListener('keyup.enter', ['$event'])
  onClick() {
    if (!this.processing && !this.disabled) {
      this.clicked.emit();
    }
  }
}
