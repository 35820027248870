import { Component, Input } from "@angular/core";

@Component({
  selector: "app-ff-parent-link",
  templateUrl: "./ff-parent-link.component.html",
  styleUrls: ["./ff-parent-link.component.scss"],
})
export class FfParentLinkComponent {
  @Input({ required: false })
  iconPath: string = "assets/icons/arrow_left.svg";

  @Input({ required: false })
  iconSize: number = 20;

  @Input({ required: false })
  label?: string;

  @Input({ required: true })
  route!: string; // TODO: Default to current route's parent and make route input optional
}
