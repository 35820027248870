import { Email } from '@core/entities/user/email.entity';
import { ArrayHelper } from '@core/helpers/array.helper';

/**
 *
 */
export class EmailHelper {
  /**
   *
   * @param emails
   * @param deletedUuid
   */
  static removeEmail(emails: Email[], deletedUuid: string): Array<Email> {
    emails.splice(
      emails.findIndex((email: Email) => email.uuid === deletedUuid),
      1,
    );
    return emails;
  }

  /**
   * @param {Email[]} emails
   * @returns {Email[]}
   * @private
   */
  static sortEmails(emails: Email[]): Email[] {
    return emails.sort(ArrayHelper.fieldSorter(['-!createdAt', '-primary', '-verified', 'email']));
  }
}
