import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientEntity } from '@core/entities/client/client.entity';
import { ScopeEntity } from '@core/entities/oauth/scope.entity';
import { EnvironmentHelper as EnvHelper, EnvironmentHelper } from '@core/helpers/environment.helper';
import { StringHelper } from '@core/helpers/string.helper';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { deserialize, serialize } from 'serializr';

@Injectable({ providedIn: 'root' })
export class ScopeService {
  /**
   * @param _http
   */
  constructor(private _http: HttpClient) {}

  /**
   * @param query
   * @param limit
   * @param clientUuid
   */
  getApiScopes(query: string, limit: number, clientUuid: string): Observable<ScopeEntity[]> {
    if (!query || query.length < 1) {
      return of([]);
    }

    let params = new HttpParams();
    params = params.set('query', query).set('limit', limit.toString()).set('clientUuid', clientUuid);

    return this._http
      .get(EnvironmentHelper.fetchAPIBase('v1/scopes'), {
        observe: 'response',
        params,
      })
      .pipe(
        map((response: HttpResponse<{ scopes: Array<ScopeEntity> }>) => {
          if (response.status === 204) {
            return [];
          } else {
            return deserialize(ScopeEntity, response.body.scopes);
          }
        }),
      );
  }

  /**
   * @param {string} scopeUuid
   * @returns {Observable<ClientEntity[]>}
   */
  getClientsUsingScope(scopeUuid: string): Observable<ClientEntity[]> {
    return this._http.get(EnvironmentHelper.fetchAPIBase('v1/scopes/' + scopeUuid + '/clients')).pipe(
      map((response: { clients: [] }) => {
        if (!response) {
          return [];
        }

        return deserialize(ClientEntity, response.clients);
      }),
    );
  }

  /**
   * @returns {Observable<ScopeEntity[]>}
   */
  getGlobalScopes(): Observable<ScopeEntity[]> {
    return this._http
      .get(EnvironmentHelper.fetchAPIBase('v1/scopes/global'), {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<{ scopes: object[] }>) => {
          if (response.status === 204) {
            return [];
          } else {
            return deserialize(ScopeEntity, response.body.scopes);
          }
        }),
      );
  }

  /**
   * @param {string} scopeId
   * @returns {Observable<ScopeEntity>}
   */
  getScope(scopeId: string): Observable<ScopeEntity> {
    return this._http.get(EnvironmentHelper.fetchAPIBase('v1/scopes/' + scopeId)).pipe(
      map((response: { scope: object }) => {
        return deserialize(ScopeEntity, response.scope);
      }),
    );
  }

  /**
   * @param scopeUuid
   */
  deleteScope(scopeUuid: string): Observable<{}> {
    return this._http.delete(EnvironmentHelper.fetchAPIBase('v1/scopes/' + scopeUuid));
  }

  /**
   * @param {string} scopeExpirationUuid
   */
  deleteScopeExpiration(scopeExpirationUuid: string): Observable<{}> {
    return this._http.delete(EnvironmentHelper.fetchAPIBase('v1/scope-expiration-dates/' + scopeExpirationUuid));
  }

  /**
   * @param {string} scope
   * @returns {Observable<boolean>}
   */
  existsByName(scope: string): Observable<boolean> {
    return this._http
      .get(EnvHelper.fetchAPIBase('v2/scopes/exists/by-name?name=' + StringHelper.fixedEncodeURIComponent(scope)))
      .pipe(
        map((response: { exists: boolean }) => {
          return response.exists;
        }),
      );
  }

  /**
   * @param {string} scopeId
   * @param {string} property
   * @param value
   * @returns {Observable<ScopeEntity>}
   */
  patchScope(scopeId: string, property: string, value: any): Observable<ScopeEntity> {
    return this._http
      .patch(EnvironmentHelper.fetchAPIBase('v1/scopes/') + scopeId, {
        attribute: property,
        value,
      })
      .pipe(
        map((response: { scope: object }) => {
          return deserialize(ScopeEntity, response.scope);
        }),
      );
  }

  /**
   * @param serviceId
   * @param scope
   */
  postScope(serviceId: string, scope: ScopeEntity): Observable<ScopeEntity> {
    return this._http
      .post(EnvironmentHelper.fetchAPIBase('v1/scopes'), {
        scope: serialize(scope),
        apiUuid: serviceId,
      })
      .pipe(
        map((response: { scope: object }) => {
          return deserialize(ScopeEntity, response.scope);
        }),
      );
  }

  /**
   * @param {string} scopeUuid
   * @param {Date} deprecatedSince
   * @param {Date} sunset
   * @param {string} notes
   * @returns {Observable<object>}
   */
  postScopeExpiration(scopeUuid: string, notes: string, deprecatedSince: string, sunset?: string): Observable<object> {
    return this._http
      .post(EnvironmentHelper.fetchAPIBase('v1/scope-expiration-dates'), {
        scopeExpirationDate: {
          notes,
          deprecatedSince,
          sunset,
        },
        scopeUuid,
      })
      .pipe(
        map((response: { scopeExpiration: object }) => {
          return response.scopeExpiration;
        }),
      );
  }
}
