import { ElementRef } from '@angular/core';

export class ElementRefHelper {
  /**
   * (Smooth) scroll to the nativeElement of the passed ElementRef.
   *
   * @param {ElementRef} elRef
   * @param {ScrollOptions} scrollOpt
   */
  static scrollTo(elRef: ElementRef, scrollOpt: ScrollIntoViewOptions = { behavior: 'smooth', block: 'center' }): void {
    if (!elRef) {
      return;
    }

    setTimeout(() => {
      elRef.nativeElement.scrollIntoView(scrollOpt);
    }, 10);
  }

  /**
   *
   * @param element
   * @param scrollOpt
   */
  static scrollToNativeElement(
    element: HTMLElement,
    scrollOpt: ScrollIntoViewOptions = { behavior: 'smooth', block: 'start' },
  ): void {
    if (!element) {
      return;
    }

    setTimeout(() => {
      element.scrollIntoView(scrollOpt);
    }, 10);
  }
}
