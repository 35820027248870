<div class="ff-menu-button-container" [attr.disabled]="disabled" [attr.role]="role" [tabindex]="tabindex"
  [attr.aria-expanded]="menuOpen" [class.visible]="hasMenuItems">
  <div (mouseenter)="active = true" (mouseleave)="active = false" class="trigger-wrap">
    <div class="trigger">
      @if (triggerIcon) {
        <app-svg
          [class.active]="active || menuOpen"
          [heightPx]="triggerIconSize"
          [svgPath]="triggerIcon"
          [widthPx]="triggerIconSize"
          class="trigger__icon icon"
        ></app-svg>
      }

      @if (triggerText) {
        <div class="trigger__text">
          {{ triggerText }}
        </div>
      }

      @if (triggerButton) {
        <app-ff-button
          (clicked)="toggleMenu()"
          [color]="triggerButtonIconColor"
          [iconPath]="triggerButton"
          [iconSize]="triggerButtonIconSize"
          class="trigger__button {{ buttonClassList }}"
          tabindex="-1"
        ></app-ff-button>
      }
    </div>
  </div>

  <div #menu aria-live="polite" class="menu {{ arrowPosition }}" [class.menu--visible]="menuOpen" role="list">
    <div class="menu__arrow">
      <ng-content select="app-ff-menu-item"></ng-content>
    </div>
  </div>
</div>
