import {
  Component,
  DoCheck,
  HostBinding,
  Input,
  IterableDiffer,
  IterableDiffers,
} from "@angular/core";
import { UserFeedbackEntity } from "@core/entities/user/user-feedback.entity";
import { UserFeedbackTypesEnum } from "@core/enums/user-feedback-types.enum";

@Component({
  selector: "app-feedback-messages",
  templateUrl: "./feedback-messages.component.html",
  styleUrls: ["./feedback-messages.component.scss"],
})
export class FeedbackMessagesComponent implements DoCheck {
  #iterableDiffer: IterableDiffer<UserFeedbackEntity>;

  @Input({ required: true })
  messages: Array<UserFeedbackEntity> = [];

  @HostBinding("style.display")
  display: string = "none";

  constructor(private _iterableDiffers: IterableDiffers) {
    this.#iterableDiffer = this._iterableDiffers.find([]).create(null);
  }

  get errors(): UserFeedbackEntity[] {
    return this.messages.filter(
      (uf) => uf.type === UserFeedbackTypesEnum.ERROR
    );
  }

  get informatives(): UserFeedbackEntity[] {
    return this.messages.filter(
      (uf) => uf.type === UserFeedbackTypesEnum.INFORMATIVE
    );
  }

  ngDoCheck(): void {
    const changes = this.#iterableDiffer.diff(this.messages);

    if (changes) {
      this.display = this.messages.length > 0 ? "block" : "none";
    }
  }
}
