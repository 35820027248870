<!-- Main user form -->
<form [class.submitted]="submitted" [formGroup]="userForm" class="user-form form" data-cy="form-user">
  @if (user.uuid) {
    <app-user-name-and-address
      [user]="user"
      [validateAddress]="!optionalSections?.postalAdresses"
      [validateNames]="!optionalSections?.names"
      [enabled]="!user.postalAddresses[0]?.line1"
      [nameAddrForm]="userForm.controls.nameAndAdresses"
      [isAdminContext]="isAdminContext"
    ></app-user-name-and-address>
  }

  @if (!user.uuid) {
    <div class="card">
      <app-user-name-form [nameForm]="userForm.controls.nameAndAdresses.controls.names"></app-user-name-form>
    </div>
  }

  <ng-content select="app-user-ssn-form"></ng-content>

  <ng-content select="app-user-account-status-form"></ng-content>

  <ng-content select="app-user-type-form"></ng-content>

  @if (user.uuid?.length) {
    <ng-content select="app-user-permission-form"></ng-content>
  }

  <ng-content select="app-user-member-id-form"></ng-content>

  @if (user.uuid) {
    <app-phones
      (validateFormEvent)="validateForm()"
      [isAdminContext]="isAdminContext"
      [submitted]="submitted"
      [isOptional]="optionalSections?.phoneNumbers"
      [phonesFormArray]="userForm.controls.phones"
      [selfUser]="selfUser"
      [user]="user"
    ></app-phones>
  }

  @if (user.uuid) {
    <app-emails
      (validateFormEvent)="validateForm()"
      [emailsFormArray]="userForm.controls.emails"
      [isAdminContext]="isAdminContext"
      [isOptional]="optionalSections?.emailAddresses"
      [selfUser]="selfUser"
      [user]="user"
    ></app-emails>
  }

  @if (includeEmployment) {
    <div [formGroup]="userForm.controls.employment" class="form-group">
      <div class="form-control">
        <label for="bank-account">Bankkontonummer</label>
        <app-ff-icon-input tooltipText="Ditt bankkontonummer">
          <input
            (input)="validateForm()"
            [formControl]="userForm.controls.employment.controls.bankAccount"
            [readOnly]="processing ? true : null"
            id="bank-account"
            inputmode="decimal"
            placeholder="11 siffer"
            type="text"
            />
        </app-ff-icon-input>
      </div>
    </div>
  }

  @if (includeMasterSubmitButton) {
    <div class="buttons">
      <app-ff-button
        (clicked)="save()"
        [disabled]="userForm.disabled"
        [label]="saveButtonLabel"
        [processing]="processing"
        id="save-user-form"
      ></app-ff-button>
    </div>
  }
</form>

<!-- Show any user messages  -->
<app-feedback-messages #feedbackMessagesRef [messages]="userMessages"></app-feedback-messages>
