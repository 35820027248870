import { PendingInformationUpdate } from '@core/entities/login/pending-information-update.entity';
import { alias, object, serializable } from 'serializr';

export class PendingInformationUpdates {
  @serializable(alias('address', object(PendingInformationUpdate)))
  private _address: PendingInformationUpdate;

  @serializable(alias('email', object(PendingInformationUpdate)))
  private _email: PendingInformationUpdate;

  @serializable(alias('employmentInfo', object(PendingInformationUpdate)))
  private _employmentInfo?: PendingInformationUpdate;

  @serializable(alias('password', object(PendingInformationUpdate)))
  private _password: PendingInformationUpdate;

  @serializable(alias('phone', object(PendingInformationUpdate)))
  private _phone: PendingInformationUpdate;

  @serializable(alias('user', object(PendingInformationUpdate)))
  private _user: PendingInformationUpdate;

  @serializable(alias('verificationRequired'))
  private _verificationRequired: boolean;

  get address(): PendingInformationUpdate {
    return this._address;
  }

  get email(): PendingInformationUpdate {
    return this._email;
  }

  get employmentInfo(): PendingInformationUpdate | null {
    return this._employmentInfo;
  }

  get password(): PendingInformationUpdate {
    return this._password;
  }

  get phone(): PendingInformationUpdate {
    return this._phone;
  }

  get user(): PendingInformationUpdate {
    return this._user;
  }

  get verificationRequired(): boolean {
    return this._verificationRequired;
  }

  hasPendingUpdates(): boolean {
    return (
      this._address.required ||
      this._email.required ||
      this._password.required ||
      this._phone.required ||
      this._user.required ||
      (this._employmentInfo && this._employmentInfo.required)
    );
  }
}
