import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ff-pagination',
  templateUrl: './ff-pagination.component.html',
  styleUrls: ['./ff-pagination.component.scss'],
})
export class FfPaginationComponent {
  @Input()
  id?: string;

  @Output()
  pageBoundsCorrection: EventEmitter<number>;

  @Output()
  pageChange: EventEmitter<number> = new EventEmitter<number>();

  autoHide: boolean = false;
  directionLinks: boolean = true;
  maxSize: number = 10;
  nextLabel = 'Neste';
  previousLabel = 'Forrige';
  responsive: boolean = true;
  screenReaderPaginationLabel = 'Sider';
  screenReaderPageLabel = 'Side';
  screenReaderCurrentLabel = 'Gjeldende side';
}
