<h2>Mobilnummer</h2>

@if (phonesFormArray.parent) {
  <div [formGroup]="parentGroup" class="all-phones-list">
    <!-- User phones -->
    @if (user.phones.length) {
      <div class="form-group form-group--phone existing">
        @for (phone of user.phones; track phone; let idx = $index) {
          <app-existing-phone
            (deletedPhone)="removePhone($event)"
            (updatedPhone)="phoneUpdated($event)"
            [phone]="phone"
            [formDisabled]="phonesFormArray.disabled"
            [isAdminContext]="isAdminContext"
            [isOptional]="isOptional"
            [selfUser]="selfUser"
            [user]="user"
          ></app-existing-phone>
        }
      </div>
    }
    @if (phonesFormArray.length) {
      <div class="form-group">
        @for (phone of phonesFormArray.controls; track phone; let idx = $index) {
          <div class="form-control">
            <app-phone-form
              [showErrors]="submitted"
              [countryCodes$]="countryCodes$"
              [phoneFormGroup]="phone"
            ></app-phone-form>
            <div class="phone-input-buttons">
              <app-ff-button [processing]="processing" color="primary" (clicked)="submitPhone(idx)"> Lagre </app-ff-button>
              @if (!!user.phones.length) {
                <app-ff-button color="secondary" (clicked)="cancelPhone(idx)">
                  Avbryt
                </app-ff-button>
              }
            </div>
          </div>
        }
      </div>
    }
    @if (!phonesFormArray.length) {
      <div class="buttons">
        <!-- New phone -->
        @if (phonesFormArray.enabled) {
          <app-ff-button
            (clicked)="newPhone()"
            color="sand"
            class="inline padded"
            label="Legg til nummer"
          ></app-ff-button>
        }
      </div>
    }
  </div>
}
