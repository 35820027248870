import { ApiEntity } from "@core/entities/api/api.entity";
import { ClientTestUserEntity } from "@core/entities/api/client-test-user.entity";
import { ContactInformationEntity } from "@core/entities/api/contact-information.entity";
import { DeveloperEntity } from "@core/entities/api/developer.entity";
import { GrantEntity } from "@core/entities/oauth/grant.entity";
import { PostLogoutRedirectUriEntity } from "@core/entities/oauth/post-logout-redirect-uri.entity";
import { RedirectUriEntity } from "@core/entities/oauth/redirect-uri.entity";
import { ScopeEntity } from "@core/entities/oauth/scope.entity";
import { ClientTypesEnum } from "@core/enums/client-types.enum";
import { ProcessableInterface } from "@core/interfaces/processable.interface";
import { CorsUriEntity } from "@shared/components/cors-uris/cors-uri.entity";
import { LoginProviderEntity } from "../login/login-provider.entity";

/**
 * @see CLIENT_ENTITY_SCHEMA
 */
export class ClientEntity implements ProcessableInterface {
  processing: boolean;

  private _active: boolean;
  private _allowedGrants: GrantEntity[] = [];
  private _apis: ApiEntity[] = [];
  private _bypassAuthorization: boolean;
  private _canOmitScopes: boolean;
  private _contactInformation: ContactInformationEntity;
  private _createdAt: Date;
  private _corsUris: CorsUriEntity[] = [];
  private _description: string;
  private _developers: DeveloperEntity[] = [];
  private _devMode: boolean;
  private _id: string;
  private _keyOption: number;
  private _loginProviders?: LoginProviderEntity[];
  private _name: string;
  private _postLogoutRedirectUris: PostLogoutRedirectUriEntity[] = [];
  private _public: boolean;
  private _redirectUris: RedirectUriEntity[] = [];
  private _requireInformationUpdate: boolean;
  private _scopes: ScopeEntity[] = [];
  private _testUsers: ClientTestUserEntity[] = [];
  private _type: ClientTypesEnum;
  private _lastUsedAt: Date;

  constructor(name?: string) {
    this._name = name;
  }

  get lastUsedAt(): Date {
    return this._lastUsedAt;
  }

  set lastUsedAt(lastUsedAt: Date) {
    this._lastUsedAt = lastUsedAt;
  }

  get allowedGrants(): GrantEntity[] {
    return this._allowedGrants;
  }

  set allowedGrants(value: GrantEntity[]) {
    this._allowedGrants = value;
  }

  get active(): boolean {
    return this._active;
  }

  set active(value: boolean) {
    this._active = value;
  }

  get bypassAuthorization(): boolean {
    return this._bypassAuthorization;
  }

  set bypassAuthorization(value: boolean) {
    this._bypassAuthorization = value;
  }

  get canOmitScopes(): boolean {
    return this._canOmitScopes;
  }

  set canOmitScopes(value: boolean) {
    this._canOmitScopes = value;
  }

  get contactInformation(): ContactInformationEntity {
    return this._contactInformation;
  }

  set contactInformation(value: ContactInformationEntity) {
    this._contactInformation = value;
  }

  get corsUris(): CorsUriEntity[] {
    return this._corsUris;
  }

  set corsUris(value: CorsUriEntity[]) {
    this._corsUris = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get devMode(): boolean {
    return this._devMode;
  }

  set devMode(value: boolean) {
    this._devMode = value;
  }

  get developers(): DeveloperEntity[] {
    return this._developers;
  }

  set developers(value: DeveloperEntity[]) {
    this._developers = value;
  }

  get id(): string {
    return this._id;
  }

  set id(value: string) {
    this._id = value;
  }

  get keyOption(): number {
    return this._keyOption;
  }

  set keyOption(value: number) {
    this._keyOption = value;
  }

  get loginProviders(): LoginProviderEntity[] | undefined {
    return this._loginProviders;
  }

  set loginProviders(value: LoginProviderEntity[]) {
    this._loginProviders = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get public(): boolean {
    return this._public;
  }

  set public(value: boolean) {
    this._public = value;
  }

  get redirectUris(): RedirectUriEntity[] {
    return this._redirectUris;
  }

  set redirectUris(value: RedirectUriEntity[]) {
    this._redirectUris = value;
  }

  get postLogoutRedirectUris(): PostLogoutRedirectUriEntity[] {
    return this._postLogoutRedirectUris;
  }

  set postLogoutRedirectUris(value: PostLogoutRedirectUriEntity[]) {
    this._postLogoutRedirectUris = value;
  }

  get requireInformationUpdate(): boolean {
    return this._requireInformationUpdate;
  }

  set requireInformationUpdate(value: boolean) {
    this._requireInformationUpdate = value;
  }

  get scopes(): ScopeEntity[] {
    return this._scopes;
  }

  set scopes(value: ScopeEntity[]) {
    this._scopes = value;
  }

  get apis(): ApiEntity[] {
    return this._apis;
  }

  set apis(value: ApiEntity[]) {
    this._apis = value;
  }

  get testUsers(): ClientTestUserEntity[] {
    return this._testUsers;
  }

  set testUsers(value: ClientTestUserEntity[]) {
    this._testUsers = value;
  }

  get type(): ClientTypesEnum {
    return this._type;
  }

  set type(value: ClientTypesEnum) {
    this._type = value;
  }
}

export type PutClientEntity = Omit<ClientEntity, "_secret" | "secret">;
