<div [class.open]="isOpen" class="drop-down-component">
  @if (useOverlay && isOpen) {
    <div (click)="toggleState()" class="backdrop"></div>
  }

  <div
    #selectRef
    (click)="toggleState()"
    (keyup.enter)="toggleState()"
    (keyup.escape)="isOpen = false"
    [class.is-disabled]="isDisabled"
    class="select"
    role="combobox"
    tabindex="0"
    >
    @if (currentItem) {
      <span [title]="resolveText(currentItem)">
        {{ selectPrefix }}{{ resolveText(currentItem) }}
      </span>
    }

    @if (!currentItem && !isOpen) {
      <span [title]="selectText">
        {{ selectText }}
      </span>
    }

    <span class="dropdown-indicator">
      <img alt="arrows" src="assets/icons/arrows_up_down.svg" height="16" width="16" />
    </span>
  </div>

  <div (blur)="isOpen = false" [class.open]="isOpen" class="list" role="list">
    @if (selectText) {
      <div
        (click)="pickItem(null)"
        (keyup.enter)="pickItem(null)"
        (keyup.escape)="isOpen = false"
        class="item"
        role="listitem"
        tabindex="0"
        >
        {{ selectText }}
      </div>
    }

    @for (item of items; track item) {
      <div
        (click)="pickItem(item)"
        (keyup.enter)="pickItem(item)"
        (keyup.escape)="isOpen = false"
        class="item"
        role="listitem"
        tabindex="0"
        >
        {{ resolveText(item) }}
      </div>
    }
  </div>
</div>
