import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PhoneCountryCodeEntity } from '@core/entities/country-codes/phone-country-code.entity';
import {
  PHONE_ERROR_MAPPING_API,
  PhoneErrorApi,
  PHONE_ERROR_MAPPING,
  PhoneError,
} from '@shared/validators/phone.validator';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { PhoneForm } from '../../types/phone-form.type';

@Component({
  selector: 'app-phone-form',
  templateUrl: './phone-form.component.html',
  styleUrls: ['./phone-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneFormComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input()
  countryCodes$: Observable<PhoneCountryCodeEntity[]>;

  @Input()
  phoneFormGroup: FormGroup<PhoneForm>;

  @Input()
  showErrors = true;

  @ViewChild('phoneInput', { static: false })
  inputRef: ElementRef;

  countryCodeControl = new FormControl<PhoneCountryCodeEntity>(null);
  errorSubject$ = new BehaviorSubject<string[]>([]);
  errors$ = this.errorSubject$.asObservable();
  private _onDestroy = new Subject<void>();

  constructor(private _cdr: ChangeDetectorRef) {}

  /**
   * Focus the input after clicking add new
   */
  ngAfterViewInit(): void {
    if (this.phoneFormGroup.controls.autofocus.value && !this.phoneFormGroup.controls.uuid.value) {
      this.inputRef.nativeElement.focus();
    }
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit(): void {
    this._initInternalCountryCodeControl();
    this._subscribeToCountryCodeSync();
    this._subscribeToValidationSync();
  }
  private _initInternalCountryCodeControl(): void {
    this.countryCodes$
      .pipe(
        takeUntil(this._onDestroy),
        tap((codes) => {
          this.countryCodeControl.setValue(
            codes.find((code) => code.phoneCode === this.phoneFormGroup.value.countryCode),
          );
        }),
        finalize(() => this._cdr.detectChanges()),
      )
      .subscribe();
  }

  private _subscribeToCountryCodeSync(): void {
    this.countryCodeControl.valueChanges
      .pipe(
        takeUntil(this._onDestroy),
        tap((countryCode) => {
          if (countryCode) {
            this.phoneFormGroup.controls.countryCode.setValue(countryCode?.phoneCode);
          }
        }),
      )
      .subscribe();
  }

  private _subscribeToValidationSync(): void {
    this.phoneFormGroup.statusChanges
      .pipe(
        takeUntil(this._onDestroy),
        tap(() => {
          const errors: Array<PHONE_ERROR_MAPPING_API | PHONE_ERROR_MAPPING> = Object.keys(
            this.phoneFormGroup.errors ?? {},
          ) as PHONE_ERROR_MAPPING_API[];
          errors.push(
            ...(Object.keys(this.phoneFormGroup.controls.number.errors ?? {}).filter(
              (error: PHONE_ERROR_MAPPING) => error !== PHONE_ERROR_MAPPING.UNSAVED_CHANGES,
            ) as PHONE_ERROR_MAPPING[]),
          );

          this.errorSubject$.next(errors.map((error) => PhoneErrorLabels[error.toUpperCase()]));
        }),
      )
      .subscribe();
  }
}

export const PhoneErrorLabels: Record<PhoneErrorApi | PhoneError, string> = {
  CREATE_FAILED: 'Lagring feilet',
  DUPLICATE_NUMBER: 'Nummeret eksisterer allerede',
  INVALID_COUNTRY: 'Ugyldig landskode',
  INVALID_LENGTH: 'Ugyldig lengde',
  INVALID_PHONE_NUMBER: 'Uyldig mobilnummer',
  INVALID_TYPE: 'Dette ser ikke ut som et mobilnummer',
  NOT_A_NUMBER: 'Selve nummeret er ugyldig',
  REQUIRED: 'Nummeret må fylles ut',
  TOO_LONG: 'Nummeret er for langt',
  TOO_SHORT: 'Nummeret er for kort',
  UNSAVED_CHANGES: 'Nummeret må lagres',
  UPDATE_FAILED: 'Lagring av endringen feilet',
  INVALID: 'Nummeret er ugyldig',
  NOT_UNIQUE_ERROR: 'Nummeret eksisterer allerede',
} as const;
