import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import {
  API_ENTITY_SCHEMA,
  CLIENT_ENTITY_SCHEMA,
} from "@core/entities/api/api-client-entity.schema";
import { ApiEntity } from "@core/entities/api/api.entity";
import { ClientTestUserEntity } from "@core/entities/api/client-test-user.entity";
import { DeveloperEntity } from "@core/entities/api/developer.entity";
import { ClientEntity } from "@core/entities/client/client.entity";
import { PageableClientsEntity } from "@core/entities/client/pageable-clients.entity";
import { PostLogoutRedirectUriEntity } from "@core/entities/oauth/post-logout-redirect-uri.entity";
import { RedirectUriEntity } from "@core/entities/oauth/redirect-uri.entity";
import { ScopeEntity } from "@core/entities/oauth/scope.entity";
import { User } from "@core/entities/user/user.entity";
import { ClientToggleablePropertiesEnum } from "@core/enums/client-toggleable-properties.enum";
import { ClientTypesEnum } from "@core/enums/client-types.enum";
import { EnvironmentHelper } from "@core/helpers/environment.helper";
import { ClientSecret } from "@core/interfaces/client-secret.interface";
import { CorsUriEntity } from "@shared/components/cors-uris/cors-uri.entity";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { deserialize, serialize } from "serializr";
import { RequestService } from "@core/services/request.service";
import { IdApiEndpointsEnum } from "@core/enums/api-endpoints/id-api.enum";
import { GetOauthClientResponseDto } from "@core/dtos/clients/oauth/get-oauth-client.response-dto";
import { GetSsoClientResponseDto } from "@core/dtos/clients/sso/get-sso-client.response-dto";
import { OauthClient } from "@core/domain-models/clients/oauth/oauth-client";
import { SsoClient } from "@core/domain-models/clients/sso/sso-client";
import { GetClientScopesResponseDto } from "@core/dtos/clients/oauth/get-scopes.response-dto";
import { GetClientApisResponseDto } from "@core/dtos/clients/get-client-apis.response-dto";
import { GetClientRedirectUrisResponseDto } from "@core/dtos/clients/get-client-redirect-uris.response-dto";
import { GetOauthClientPostLogoutRedirectUrisResponseDto } from "@core/dtos/clients/oauth/get-oauth-client-post-logout-redirect-uris.response-dto";
import { GetClientCorsUrlsResponseDto } from "@core/dtos/clients/oauth/get-client-cors-urls.response-dto";
import { GetClientDevelopersResponseDto } from "@core/dtos/clients/get-client-developers.response-dto";
import { GetClientTestUsersResponseDto } from "@core/dtos/clients/get-client-test-users.response-dto";

@Injectable({
  providedIn: "root",
})
export class ClientService {
  readonly #httpClient = inject(HttpClient);
  readonly #requestService = inject(RequestService);

  assignApi(client: OauthClient, api: ApiEntity): Observable<ApiEntity> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase(
          "v1/apis/" + api.uuid + "/clients/" + client.uuid
        ),
        {}
      )
      .pipe(
        map((response: { api: object }) => {
          return deserialize<ApiEntity>(API_ENTITY_SCHEMA, response.api);
        })
      );
  }

  assignDeveloper(
    client: OauthClient | SsoClient,
    developer: User
  ): Observable<ClientEntity> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase(
          "v1/" +
            client.type +
            "-clients/" +
            client.uuid +
            "/developers/" +
            developer.uuid
        ),
        {}
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  assignScope(
    client: OauthClient,
    scope: ScopeEntity
  ): Observable<ClientEntity> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase(
          "v1/oauth-clients/" + client.uuid + "/scopes/" + scope.uuid
        ),
        {
          required: scope.required,
        }
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  assignTestUser(
    client: OauthClient | SsoClient,
    user: User
  ): Observable<ClientEntity> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase(
          "v1/" +
            client.type +
            "-clients/" +
            client.uuid +
            "/test-users/" +
            user.uuid
        ),
        {}
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  deleteCors(corsId: string): Observable<CorsUriEntity> {
    return this.#httpClient
      .delete(EnvironmentHelper.fetchAPIBase("v1/client-cors-urls/" + corsId))
      .pipe(
        map((response: { clientCorsUrl: object }) => {
          return deserialize(CorsUriEntity, response.clientCorsUrl);
        })
      );
  }

  deleteLoginRedirect(redirId: string): Observable<RedirectUriEntity> {
    return this.#httpClient
      .delete(
        EnvironmentHelper.fetchAPIBase("v1/client-redirect-uris/" + redirId)
      )
      .pipe(
        map((response: { clientRedirectUri: object }) => {
          return deserialize(RedirectUriEntity, response.clientRedirectUri);
        })
      );
  }

  deleteLogoutRedirect(
    redirId: string
  ): Observable<PostLogoutRedirectUriEntity> {
    return this.#httpClient
      .delete(
        EnvironmentHelper.fetchAPIBase(
          "v1/client-post-logout-redirect-uris/" + redirId
        )
      )
      .pipe(
        map((response: { clientPostLogoutRedirectUri: object }) => {
          return deserialize(
            PostLogoutRedirectUriEntity,
            response.clientPostLogoutRedirectUri
          );
        })
      );
  }

  getClients(
    paginationParams: PageableClientsEntity
  ): Observable<PageableClientsEntity> {
    return this.#httpClient
      .get(EnvironmentHelper.fetchAPIBase("v1/clients"), {
        observe: "response",
        params: paginationParams.apiRequestParams(),
      })
      .pipe(
        map((response: HttpResponse<{ clients: object[]; count: string }>) => {
          if (response.status === 204) {
            return new PageableClientsEntity();
          } else {
            return deserialize(PageableClientsEntity, response.body);
          }
        })
      );
  }

  /**
   * This endpoint requires elevated access to use.
   * Call elevateAccess() prior to this.
   * @see ElevatedAccessService
   */
  getClientSecrets(args: {
    clientUuid: string;
    includeRevoked?: boolean;
  }): Observable<ClientSecret[]> {
    const params = new HttpParams({ fromObject: args });
    return this.#httpClient
      .get(EnvironmentHelper.fetchAPIBase("v1/client-secrets"), { params })
      .pipe(
        map((response: { clientSecrets: ClientSecret[] }) => {
          return response.clientSecrets;
        })
      );
  }

  getInfo(
    clientId: string,
    clientType: ClientTypesEnum = ClientTypesEnum.OAUTH
  ): Observable<ClientEntity> {
    return this.#httpClient
      .get(
        EnvironmentHelper.fetchAPIBase(
          "v1/" + clientType + "-clients/info/" + clientId
        )
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  getInfoByRedirectUri(
    uri: string,
    clientType: ClientTypesEnum = ClientTypesEnum.OAUTH
  ): Observable<ClientEntity> {
    return this.#httpClient
      .get(
        EnvironmentHelper.fetchAPIBase(
          "v1/" + clientType + "-clients/info/by-redirect-uri/" + uri
        )
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  patchClient(
    client: OauthClient | SsoClient,
    property: ClientToggleablePropertiesEnum,
    value: any
  ): Observable<ClientEntity> {
    return this.#httpClient
      .patch(
        EnvironmentHelper.fetchAPIBase(
          "v1/" + client.type + "-clients/" + client.uuid
        ),
        {
          attribute: property,
          value,
        }
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  addClientLoginProvider(
    clientId: string,
    loginProviderId: string
  ): Observable<ClientEntity> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase(
          `v1/oauth-clients/${clientId}/login-providers/${loginProviderId}`
        ),
        {}
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  /**
   * This endpoint requires elevated access to use.
   * Call elevateAccess() prior to this.
   * @see ElevatedAccessService
   */
  revokeClientSecret(secretUuid: string): Observable<ClientSecret> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase(
          "v1/client-secrets/" + secretUuid + "/revoke"
        ),
        {}
      )
      .pipe(
        map((response: { clientSecret: ClientSecret }) => {
          return response.clientSecret;
        })
      );
  }

  /**
   * This endpoint requires elevated access to use.
   * Call elevateAccess() prior to this.
   * @see ElevatedAccessService
   */
  postClientSecret(body: {
    clientUuid: string;
    clientSecret: { secret: string };
  }): Observable<ClientSecret> {
    return this.#httpClient
      .post(EnvironmentHelper.fetchAPIBase("v1/client-secrets"), body)
      .pipe(
        map((response: { clientSecret: ClientSecret }) => {
          return response.clientSecret;
        })
      );
  }

  postCors(clientId: string, url: string): Observable<CorsUriEntity> {
    return this.#httpClient
      .post(EnvironmentHelper.fetchAPIBase("v1/client-cors-urls"), {
        clientUuid: clientId,
        clientCorsUrl: { url },
      })
      .pipe(
        map((response: { clientCorsUrl: object }) => {
          return deserialize(CorsUriEntity, response.clientCorsUrl);
        })
      );
  }

  postLoginRedirect(
    clientId: string,
    uri: RedirectUriEntity
  ): Observable<RedirectUriEntity> {
    return this.#httpClient
      .post(EnvironmentHelper.fetchAPIBase("v1/client-redirect-uris"), {
        clientUuid: clientId,
        clientRedirectUri: serialize(uri),
      })
      .pipe(
        map((response: { clientRedirectUri: object }) => {
          return deserialize(RedirectUriEntity, response.clientRedirectUri);
        })
      );
  }

  postLogoutRedirect(
    clientId: string,
    uri: PostLogoutRedirectUriEntity
  ): Observable<PostLogoutRedirectUriEntity> {
    return this.#httpClient
      .post(
        EnvironmentHelper.fetchAPIBase("v1/client-post-logout-redirect-uris"),
        {
          clientUuid: clientId,
          clientPostLogoutRedirectUri: serialize(uri),
        }
      )
      .pipe(
        map((response: { clientPostLogoutRedirectUri: object }) => {
          return deserialize(
            PostLogoutRedirectUriEntity,
            response.clientPostLogoutRedirectUri
          );
        })
      );
  }

  putLoginRedirect(uri: RedirectUriEntity): Observable<RedirectUriEntity> {
    return this.#httpClient
      .put(
        EnvironmentHelper.fetchAPIBase("v1/client-redirect-uris/" + uri.id),
        {
          clientRedirectUri: serialize(uri),
        }
      )
      .pipe(
        map((response: { clientRedirectUri: object }) => {
          return deserialize(RedirectUriEntity, response.clientRedirectUri);
        })
      );
  }

  putLogoutRedirect(
    uri: PostLogoutRedirectUriEntity
  ): Observable<PostLogoutRedirectUriEntity> {
    return this.#httpClient
      .put(
        EnvironmentHelper.fetchAPIBase(
          "v1/client-post-logout-redirect-uris/" + uri.id
        ),
        {
          clientPostLogoutRedirectUri: serialize(uri),
        }
      )
      .pipe(
        map((response: { clientPostLogoutRedirectUri: object }) => {
          return deserialize(
            PostLogoutRedirectUriEntity,
            response.clientPostLogoutRedirectUri
          );
        })
      );
  }

  removeClientLoginProvider(
    clientId: string,
    loginProviderId: string
  ): Observable<ClientEntity> {
    return this.#httpClient
      .delete(
        EnvironmentHelper.fetchAPIBase(
          `v1/oauth-clients/${clientId}/login-providers/${loginProviderId}`
        ),
        {}
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  toggleScopeRequired(
    client: OauthClient,
    scope: ScopeEntity
  ): Observable<ClientEntity> {
    return this.#httpClient
      .put(
        EnvironmentHelper.fetchAPIBase(
          "v1/oauth-clients/" + client.uuid + "/scopes/" + scope.uuid
        ),
        {
          required: scope.required,
        }
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  unAssignApi(client: OauthClient, api: ApiEntity): Observable<ApiEntity> {
    return this.#httpClient
      .delete(
        EnvironmentHelper.fetchAPIBase(
          "v1/apis/" + api.uuid + "/clients/" + client.uuid
        ),
        {}
      )
      .pipe(
        map((response: { api: object }) => {
          return deserialize<ApiEntity>(API_ENTITY_SCHEMA, response.api);
        })
      );
  }

  unAssignDeveloper(
    client: OauthClient | SsoClient,
    developer: DeveloperEntity
  ): Observable<ClientEntity> {
    return this.#httpClient
      .delete(
        EnvironmentHelper.fetchAPIBase(
          "v1/" +
            client.type +
            "-clients/" +
            client.uuid +
            "/developers/" +
            developer.uuid
        ),
        {}
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  unAssignScope(
    client: OauthClient,
    scope: ScopeEntity
  ): Observable<ClientEntity> {
    return this.#httpClient
      .delete(
        EnvironmentHelper.fetchAPIBase(
          "v1/oauth-clients/" + client.uuid + "/scopes/" + scope.uuid
        ),
        {}
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  unAssignTestUser(
    client: OauthClient | SsoClient,
    user: ClientTestUserEntity
  ): Observable<ClientEntity> {
    return this.#httpClient
      .delete(
        EnvironmentHelper.fetchAPIBase(
          "v1/" +
            client.type +
            "-clients/" +
            client.uuid +
            "/test-users/" +
            user.uuid
        ),
        {}
      )
      .pipe(
        map((response: { client: object }) => {
          return deserialize<ClientEntity>(
            CLIENT_ENTITY_SCHEMA,
            response.client
          );
        })
      );
  }

  getOauthClient(clientUuid: string): Observable<OauthClient> {
    return this.#requestService
      .get<GetOauthClientResponseDto>(IdApiEndpointsEnum.GET_OAUTH_CLIENT, {
        clientUuid,
      })
      .pipe(
        map((getClientResponse: GetOauthClientResponseDto) => {
          return {
            uuid: getClientResponse.client.uuid,
            allowedAcrValues: getClientResponse.client.allowedAcrValues, // TODO: Map?
            allowedGrants: getClientResponse.client.allowedGrants, // TODO: Map?
            loginProviders: getClientResponse.client.loginProviders, // TODO: Map?
            active: getClientResponse.client.active,
            bypassAuthorization: getClientResponse.client.bypassAuthorization,
            canOmitScopes: getClientResponse.client.canOmitScopes,
            contactInformation: getClientResponse.client.contactInformation,
            createdAt: getClientResponse.client.createdAt,
            description: getClientResponse.client.description,
            devMode: getClientResponse.client.devMode,
            name: getClientResponse.client.name,
            public: getClientResponse.client.public,
            requireInformationUpdate:
              getClientResponse.client.requireInformationUpdate,
            type: ClientTypesEnum.OAUTH,
            refreshTokenTtl: getClientResponse.client.refreshTokenTtl,
            accessTokenTtl: getClientResponse.client.accessTokenTtl,
            idTokenTtl: getClientResponse.client.idTokenTtl,
          };
        })
      );
  }

  getSsoClient(clientUuid: string): Observable<SsoClient> {
    return this.#requestService
      .get<GetSsoClientResponseDto>(IdApiEndpointsEnum.GET_SSO_CLIENT, {
        clientUuid,
      })
      .pipe(
        map((getClientResponse: GetSsoClientResponseDto) => {
          return {
            uuid: getClientResponse.client.uuid,
            contactInformation: getClientResponse.client.contactInformation,
            createdAt: getClientResponse.client.createdAt,
            name: getClientResponse.client.name,
            public: getClientResponse.client.public,
            active: getClientResponse.client.active,
            devMode: getClientResponse.client.devMode,
            description: getClientResponse.client.description,
            keyOption: getClientResponse.client.keyOption,
            type: ClientTypesEnum.SSO,
          };
        })
      );
  }

  getScopes(clientUuid: string): Observable<ScopeEntity[]> {
    return this.#requestService
      .get<GetClientScopesResponseDto>(IdApiEndpointsEnum.GET_CLIENT_SCOPES, {
        clientUuid,
      })
      .pipe(
        map((getClientScopesResponse: GetClientScopesResponseDto) => {
          return deserialize(ScopeEntity, getClientScopesResponse.scopes) ?? [];
        })
      );
  }

  getApis(clientUuid: string): Observable<ApiEntity[]> {
    return this.#requestService
      .get<GetClientApisResponseDto>(IdApiEndpointsEnum.GET_CLIENT_APIS, {
        clientUuid,
      })
      .pipe(
        map((getClientApisResponse: GetClientApisResponseDto) => {
          return deserialize(ApiEntity, getClientApisResponse.apis) ?? [];
        })
      );
  }

  getRedirectUris(clientUuid: string): Observable<RedirectUriEntity[]> {
    return this.#requestService
      .get<GetClientRedirectUrisResponseDto>(
        IdApiEndpointsEnum.GET_CLIENT_REDIRECT_URIS,
        {
          clientUuid,
        }
      )
      .pipe(
        map(
          (getClientRedirectUrisResponse: GetClientRedirectUrisResponseDto) => {
            return (
              deserialize(
                RedirectUriEntity,
                getClientRedirectUrisResponse.redirectUris
              ) ?? []
            );
          }
        )
      );
  }

  getPostLogoutRedirectUris(
    clientUuid: string
  ): Observable<PostLogoutRedirectUriEntity[]> {
    return this.#requestService
      .get<GetOauthClientPostLogoutRedirectUrisResponseDto>(
        IdApiEndpointsEnum.GET_CLIENT_POST_LOGOUT_REDIRECT_URIS,
        {
          clientUuid,
        }
      )
      .pipe(
        map(
          (
            getOauthClientPostLogoutRedirectUrisResponse: GetOauthClientPostLogoutRedirectUrisResponseDto
          ) => {
            return (
              deserialize(
                PostLogoutRedirectUriEntity,
                getOauthClientPostLogoutRedirectUrisResponse.postLogoutRedirectUris
              ) ?? []
            );
          }
        )
      );
  }

  getCorsUris(clientUuid: string): Observable<CorsUriEntity[]> {
    return this.#requestService
      .get<GetClientCorsUrlsResponseDto>(
        IdApiEndpointsEnum.GET_CLIENT_CORS_URIS,
        {
          clientUuid,
        }
      )
      .pipe(
        map((getClientCorsUrisResponse: GetClientCorsUrlsResponseDto) => {
          return (
            deserialize(CorsUriEntity, getClientCorsUrisResponse.corsUrls) ?? []
          );
        })
      );
  }

  getTestUsers(clientUuid: string): Observable<ClientTestUserEntity[]> {
    return this.#requestService
      .get<GetClientTestUsersResponseDto>(
        IdApiEndpointsEnum.GET_CLIENT_TEST_USERS,
        {
          clientUuid,
        }
      )
      .pipe(
        map((getClientTestUsersResponse: GetClientTestUsersResponseDto) => {
          return (
            deserialize(
              ClientTestUserEntity,
              getClientTestUsersResponse.testUsers
            ) ?? []
          );
        })
      );
  }

  getDevelopers(clientUuid: string): Observable<DeveloperEntity[]> {
    return this.#requestService
      .get<GetClientDevelopersResponseDto>(
        IdApiEndpointsEnum.GET_CLIENT_DEVELOPERS,
        {
          clientUuid,
        }
      )
      .pipe(
        map((getClientDevelopersResponse: GetClientDevelopersResponseDto) => {
          return (
            deserialize(
              DeveloperEntity,
              getClientDevelopersResponse.developers
            ) ?? []
          );
        })
      );
  }
}
