import { alias, serializable } from 'serializr';

export class PhoneCountryCodeEntity {
  @serializable(alias('code'))
  private _code: string;

  @serializable(alias('name'))
  private _name: string;

  @serializable(alias('phoneCode'))
  private _phoneCode: string;

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get phoneCode(): string {
    return this._phoneCode;
  }

  set phoneCode(value: string) {
    this._phoneCode = value;
  }
}
