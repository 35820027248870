import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class DateHelper {
  /**
   * Regex for format DD.MM.YYYY
   * Requires DD to be 01-31
   * Requires MM to be 01-12
   * Requires YYYY to be any 4 digits
   *
   * @type {RegExp}
   */
  static readonly REGEX_DATE_NOR_SHORT = /^([0-2]?[1-9]|10|20|3[0-1])\.(0[1-9]|1[0-2])\.\d{4}$/;

  /**
   * Return Date from input "DD.MM.YYYY"
   */
  static parseDateStringShortNor(dateStr: string): Date {
    const segments = dateStr.split('.');
    return new Date(segments[2] + '-' + segments[1] + '-' + segments[0]);
  }

  /**
   * PHP doesnt seem to handle the "Z notation" of ISO8601 which JS produces. Z meaning zero time zone offset.
   * PHP also doesnt seem to expect the microseconds after the decimal point
   * @example Turns JS ISO  "2005-08-15T15:52:01.000Z" into
   * @example PHP parseable "2005-08-15T15:52:01+00:00",
   * @link https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toISOString
   * @link https://www.php.net/manual/en/class.datetimeinterface.php#datetime.constants.atom
   * @param {Date} date
   * @returns {string}
   */
  static toPhpAtomFormat(date: Date): string {
    return date.toISOString().replace('.000Z', '+00:00').replace('Z', '+00:00');
  }

  /**
   * Return date in format "DD.MM.YYYY"
   */
  static toShortNorString(date: Date): string {
    return [
      date.getDate().toString().padStart(2, '0'),
      (date.getMonth() + 1).toString().padStart(2, '0'), // Use 1-index and prefix '0' if needed
      date.getFullYear(),
    ].join('.');
  }
}
