import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PostalCodeLookupResponse } from '@core/entities/response/postal-lookup-response.entity';
import { Country } from '@core/entities/user/country.entity';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { AddressesStoreService } from '@core/services/stores/addresses-store.service';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, tap } from 'rxjs/operators';
import { deserialize } from 'serializr';

@Injectable({
  providedIn: 'root',
})
export class AddressLookupService {
  private _fetchingCountries: boolean = false;

  constructor(private _addressesStore: AddressesStoreService, private _http: HttpClient) {}

  /**
   * Get localized list of countries, cached if available
   * @param ietf The ietf code for which language you want the results to be in
   */
  getCountries(ietf: string): Observable<Country[]> {
    const countriesFromStore$ = this._addressesStore.countries$.pipe(
      filter((countries: Country[]) => {
        return countries !== null;
      }),
      map((countries: Country[]) => {
        return countries;
      }),
    );

    // If countries or fetching -> return store
    if (this._addressesStore.countries || this._fetchingCountries) {
      return countriesFromStore$;
    } else {
      // If not -> fetch & return store
      this._fetchingCountries = true;
      return this._fetchCountries(ietf).pipe(
        concatMap(() => {
          this._fetchingCountries = false;
          return countriesFromStore$;
        }),
      );
    }
  }

  getPostalInfoByPostalCode(postalCode: string, countryCode: string = 'no'): Observable<PostalCodeLookupResponse> {
    return this._http
      .get(
        EnvironmentHelper.fetchAPIBase('v1/postal-codes/lookup/country/' + countryCode + '/postal-code/' + postalCode),
      )
      .pipe(
        map((response: { postalCode: PostalCodeLookupResponse }) => {
          return deserialize(PostalCodeLookupResponse, response.postalCode);
        }),
        catchError(() => {
          return of(deserialize(PostalCodeLookupResponse, { valid: false, hasLookup: false }));
        }),
      );
  }

  /**
   * Fetch localized list of countries from API
   * @see getCountries for cached version
   * @param ietf The ietf code for which language you want the results to be in
   */
  private _fetchCountries(ietf: string): Observable<Country[]> {
    return this._http
      .get(EnvironmentHelper.fetchAPIBase('v1/countries/' + ietf), {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<{ countries: Array<object> }>) => {
          if (response.status === 204) {
            return [];
          } else {
            return deserialize(Country, response.body.countries);
          }
        }),
        tap((countries: Country[]) => {
          this._addressesStore.countries = countries;
        }),
      );
  }
}
