import { Component, Input } from '@angular/core';
import { FfConfirmModalDataInterface } from '@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal-data.interface';
import { FfModalBaseClassDirective } from '@shared/components/ff-modals/ff-modal-base.class';

@Component({
  selector: 'app-ff-confirm-modal',
  templateUrl: './ff-confirm-modal.component.html',
  styleUrls: ['./ff-confirm-modal.component.scss'],
})
export class FfConfirmModalComponent extends FfModalBaseClassDirective {
  @Input()
  override data: FfConfirmModalDataInterface;

  /**
   * Callback on cancel/decline
   */
  cancel(): void {
    this.closeModal.emit();

    if (this.data.cancelCallback) {
      this.data.cancelCallback();
    }
  }

  /**
   * Callback on confirm
   */
  confirm(): void {
    this.closeModal.emit();

    if (this.data.confirmCallback) {
      this.data.confirmCallback();
    }
  }
}
