<div class="form container">
  <app-ff-typeahead
    #typeheadRef
    [itemPropertiesForSearching]="['label']"
    [formCtrl]="searchControl"
    [items$]="searchOptions$"
    itemDisplayFormat="%label%"
    inputHtmlId="globalSearchInput"
    textPlaceholder="Navigasjon..."
    iconPosition="left"
  >
  </app-ff-typeahead>
  <div class="hotkey-box">{{ isMacOS ? '⌘' : 'ctrl' }} + k</div>
</div>
