import { Component, Input } from '@angular/core';

/**
 * @deprecated  Use the <svg-icon> component directly instead
 * This is a shim which is just a wrapper for svg-icon,
 * this comp is deprecated as of January 2020.
 *
 * Renders an SVG asset with our defaults and classes loaded
 * @yields svg-icon
 */
@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.html',
  styleUrls: ['./svg.component.scss'],
})
export class SvgComponent {
  static readonly ASSETS_PATH = 'assets/';
  static readonly DEFAULT_SIZE = 28;

  /**
   * The path to the asset to display, will be prefixed with constant ASSETS_PATH
   */
  @Input()
  svgPath: string;

  /**
   * Specify pixel height as integer, default 28
   */
  @Input()
  heightPx?: number;

  /**
   * Specify pixel width as integer, default 28
   */
  @Input()
  widthPx?: number;

  get assetPathWithPrefix() {
    return SvgComponent.ASSETS_PATH + this.svgPath;
  }

  get height(): number {
    return this.heightPx !== undefined ? this.heightPx : SvgComponent.DEFAULT_SIZE;
  }

  get width(): number {
    return this.widthPx !== undefined ? this.widthPx : SvgComponent.DEFAULT_SIZE;
  }
}
