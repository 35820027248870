import { primitive } from 'serializr';

export class BaseResponse {}

export const BaseResponseSchema = {
  factory: () => new BaseResponse(),
  props: {
    status: primitive(),
  },
};
