import { Component, EventEmitter, Input, OnInit, Output, SecurityContext } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ClientEntity } from '@core/entities/client/client.entity';
import { ConsentStatusEntity } from '@core/entities/oauth/consent-status.entity';
import { ScopeEntity } from '@core/entities/oauth/scope.entity';
import { ArrayHelper } from '@core/helpers/array.helper';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';
import { StringHelper } from '@core/helpers/string.helper';
import { ProcessableInterface } from '@core/interfaces/processable.interface';
import { OauthConsentResponseEntity } from '@shared/components/oauth-consent/oauth-consent-response.entity';

@Component({
  selector: 'app-oauth-consent',
  templateUrl: './oauth-consent.component.html',
  styleUrls: ['./oauth-consent.component.scss'],
})
export class OauthConsentComponent implements OnInit, ProcessableInterface {
  @Output()
  authorizeCallback: EventEmitter<OauthConsentResponseEntity> = new EventEmitter();

  @Input()
  clientInfo: ClientEntity;

  @Input()
  consentStatus: ConsentStatusEntity;

  /**
   * Set true if you're just showing an example of the resulting Oauth consent box
   */
  @Input()
  example?: boolean = false;

  consentFrm: UntypedFormGroup;
  processing: boolean;
  scopesCtrl: UntypedFormGroup;

  constructor(private _fb: UntypedFormBuilder, private _sanitizer: DomSanitizer) {}

  get scopesOrdered() {
    if (!this.consentStatus || !this.consentStatus.scopes) {
      return [];
    }

    return this.consentStatus.scopes.sort(ArrayHelper.fieldSorter(['-required', '-global', '-locked', 'name']));
  }

  ngOnInit() {
    const scopesKey: string = 'scopes';
    this.consentFrm = this._fb.group({
      [scopesKey]: this._fb.group([]),
    });

    this.scopesCtrl = ReactiveFormsHelper.getGroup(this.consentFrm, scopesKey);

    this.consentStatus.scopes.forEach((scope: ScopeEntity) => {
      const scopeCtrl: UntypedFormControl = this._fb.control({ value: true, disabled: true }, [
        Validators.requiredTrue,
      ]);

      if (!scope.required) {
        scopeCtrl.enable();
        scopeCtrl.setValidators(null);
      }

      this.scopesCtrl.addControl(scope.name, scopeCtrl);
    });
  }

  authorize() {
    this.processing = true;
    const response = new OauthConsentResponseEntity();
    response.consentGiven = true;

    Object.keys(this.scopesCtrl.controls).forEach((scopeName) => {
      const ctrl = ReactiveFormsHelper.getCtrl(this.scopesCtrl, [scopeName]);
      if (ctrl.value) {
        response.consentedScopes.push(scopeName);
      } else {
        response.rejectedScopes.push(scopeName);
      }
    });

    this.authorizeCallback.emit(response);
  }

  cancel() {
    this.processing = true;
    const response = new OauthConsentResponseEntity();
    response.consentGiven = false;

    this.authorizeCallback.emit(response);
  }

  getScopeCtrl(name: string) {
    return ReactiveFormsHelper.getCtrl(this.consentFrm, ['scopes', name]);
  }

  formatScopeDescription(scope: ScopeEntity): string {
    return this._sanitizer.sanitize(
      SecurityContext.HTML,
      StringHelper.nl2br(scope.description ? scope.description : '')
    );
  }
}
