export enum IdApiEndpointsEnum {
  DELETE_API = "v1/apis/:uuid",
  DELETE_OAUTH_CLIENT = "v1/oauth-clients/:uuid",
  GET_API = "v1/apis/:uuid",
  GET_CLIENT_APIS = "v1/clients/:clientUuid/apis",
  GET_CLIENT_CORS_URIS = "v2/oauth-clients/:clientUuid/cors-urls",
  GET_CLIENT_DEVELOPERS = "v1/clients/:clientUuid/developers",
  GET_CLIENT_POST_LOGOUT_REDIRECT_URIS = "v1/oauth-clients/:clientUuid/post-logout-redirect-uris",
  GET_CLIENT_REDIRECT_URIS = "v1/clients/:clientUuid/redirect-uris",
  GET_CLIENT_SCOPES = "v1/oauth-clients/:clientUuid/scopes",
  GET_CLIENT_TEST_USERS = "v1/clients/:clientUuid/test-users",
  GET_OAUTH_CLIENT = "v2/oauth-clients/:clientUuid",
  GET_SSO_CLIENT = "v2/sso-clients/:clientUuid",
  GET_USERS = "v1/users",
  POST_API = "v1/apis",
  POST_OAUTH_CLIENT = "v1/oauth-clients",
  PUT_API = "v1/apis/:uuid",
  PUT_CONTACT_INFORMATION = "v1/contact-informations/:uuid",
  PUT_OAUTH_CLIENT = "v1/oauth-clients/:uuid",
}
