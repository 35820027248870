import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { EnvironmentHelper } from '@core/helpers/environment.helper';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(private _httpClient: HttpClient) {}

  post<T>(path: string, payload: any, params: object = {}, queryParams: object = {}): Observable<T> {
    return this._httpClient.post(this._createURI(path, params, queryParams), payload).pipe(
      map((response: object) => {
        return response as T;
      }),
    );
  }

  get<T>(path: string, params: object = {}, queryParams: object = {}): Observable<T> {
    return this._httpClient
      .get(this._createURI(path, params, queryParams), {
        observe: 'response',
      })
      .pipe(
        map((response: HttpResponse<any>) => {
          if (response.status === 204) {
            return {} as T;
          } else {
            return response.body as T;
          }
        }),
      );
  }

  put<T>(path: string, payload: any, params: object = {}, queryParams: object = {}): Observable<T> {
    return this._httpClient.put(this._createURI(path, params, queryParams), payload).pipe(
      map((response: object) => {
        return response as T;
      }),
    );
  }

  patch<T>(path: string, payload: any, params: object = {}, queryParams: object = {}): Observable<T> {
    return this._httpClient.patch(this._createURI(path, params, queryParams), payload).pipe(
      map((response: object) => {
        return response as T;
      }),
    );
  }

  delete<T>(path: string, params: object = {}, queryParams: object = {}): Observable<T> {
    return this._httpClient.delete(this._createURI(path, params, queryParams)).pipe(
      map((response: object) => {
        return response as T;
      }),
    );
  }

  private _createURI(path: string, params: object = {}, queryParams: object = {}): string {
    const baseUrl = EnvironmentHelper.fetchAPIBase('');

    const pathArray = path.split('/');

    // Replace params with actual values
    pathArray.forEach((segment: any, index: number) => {
      if (segment.charAt(0) === ':') {
        pathArray[index] = params[segment.substring(1)];
      }
    });

    let url = new URL(baseUrl + pathArray.join('/'));

    for (const [key, value] of Object.entries(queryParams)) {
      if (value !== undefined) {
        url.searchParams.set(key, value);
      }
    }

    return url.toString();
  }
}
