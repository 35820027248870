import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { User } from '@core/entities/user/user.entity';
import { BehaviorSubject, Observable, Subject, catchError, finalize, forkJoin, takeUntil, tap, throwError } from 'rxjs';
import { UserFeedbackEntity } from '@core/entities/user/user-feedback.entity';
import { ReactiveFormsHelper } from '@core/helpers/reactive-forms.helper';
import { LogService } from '@core/services/log/log.service';
import { UserFormErrorsEnum, UserFormResultEntity } from '../user-form.component';
import { deserialize, serialize } from 'serializr';
import { Address } from '@core/entities/user/address.entity';
import { AddressService } from '@core/services/user/address.service';
import { SaveUserRequestEntity } from '@core/entities/request/save-user-request.entity';
import { UserService } from '@core/services/user/user.service';
import { UserFeedbackTypesEnum } from '@core/enums/user-feedback-types.enum';
import { UserFormBuilder } from '../user-form.factory';
import { NameAndAddressForm } from '../types/name-address-form.type';

@Component({
  selector: 'app-user-name-and-address',
  templateUrl: './user-name-and-address.component.html',
  styleUrls: ['./user-name-and-address.component.scss'],
})
export class UserNameAndAddressComponent implements OnInit, OnDestroy {
  @Input()
  user: User;

  @Input()
  validateAddress = true;

  @Input()
  validateNames = true;

  @Input()
  validationTrigger?: Observable<boolean>;

  @Input()
  enabled = false;

  @Input()
  isAdminContext = false;

  @Input()
  nameAddrForm: FormGroup<NameAndAddressForm>;

  @Output()
  validationChanged = new EventEmitter<UserFormResultEntity>();

  @Output()
  userChanged = new EventEmitter<UserFormResultEntity>();

  submitted = false;
  processing = false;
  userMessages$ = new BehaviorSubject<UserFeedbackEntity[]>([]);

  private readonly _onDestroy$ = new Subject<void>();

  constructor(
    private _logService: LogService,
    private _addressService: AddressService,
    private _userService: UserService,
    private _ufb: UserFormBuilder,
    private _cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (!this.nameAddrForm) {
      this.nameAddrForm = this._ufb.NameAndAddress({
        names: { firstName: this.user.firstName, lastName: this.user.lastName },
        postalAddresses: this.user.postalAddresses,
      });
    }

    if (this.enabled) {
      this.nameAddrForm.enable();
    } else {
      this.nameAddrForm.disable();
    }

    // Subscribe to external validation trigger
    if (!this.validationTrigger) {
      return;
    }
    this.validationTrigger
      .pipe(
        takeUntil(this._onDestroy$),
        tap((validate) => {
          if (validate) {
            this.submitted = true;
            this.makeErrorMessages(true);
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next();
    this._onDestroy$.complete();
  }

  makeErrorMessages(triggeredExternally: boolean = false): void {
    if (!this.submitted) {
      return;
    }

    this.userMessages$.next(
      ReactiveFormsHelper.getErrorStrings(this.nameAddrForm, UserFormErrorsEnum, this._logService),
    );

    const result = this.userMessages$.value.length === 0;

    if (triggeredExternally) {
      const response = new UserFormResultEntity(result);
      this.validationChanged.emit(response);
    }
  }

  resetNameAndAddress(): void {
    this.nameAddrForm.reset({
      names: this.user,
      postalAddresses: this.user.postalAddresses,
    });
    this.nameAddrForm.disable({ emitEvent: false });
  }

  submitForm() {
    if (this.processing) {
      return;
    }
    this.userMessages$.next([]);
    this.submitted = true;
    if (this.nameAddrForm.invalid) {
      this.makeErrorMessages();
      return;
    }
    this.processing = true;

    forkJoin(this._getUserSaveRequests())
      .pipe(
        takeUntil(this._onDestroy$),
        tap(([user, address]: [User, Address]) => {
          this.submitted = false;

          if (user !== null) {
            this.user = user;
          }

          this.user.postalAddresses[0] = address;
          const userFormResult = new UserFormResultEntity(true);
          userFormResult.user = this.user;

          this.resetNameAndAddress();

          if (!this.user.uuid) {
            userFormResult.created = true;
          }

          this.userMessages$.next([new UserFeedbackEntity('Navn og adresse ble lagret', UserFeedbackTypesEnum.INFORMATIVE)]);

          this._userService.syncUser(this.user).subscribe();
          this.userChanged.emit(userFormResult);
        }),
        catchError((error) => {
          return throwError(() => error);
        }),
        finalize(() => {
          this.processing = false;
        }),
      )
      .subscribe();
  }

  cancel(): void {
    this.userMessages$.next([]);
    this.resetNameAndAddress();
  }

  private _getPutUserRequests(): Array<Observable<any>> {
    const requests: Array<Observable<any>> = [];

    const adrForm = this.nameAddrForm.controls.postalAddresses;

    if (adrForm) {
      adrForm.controls.forEach((adrGroup, idx) => {
        // Enough address details to make a save request?
        if (adrGroup.controls.line1.value && adrGroup.controls.postalArea.value && adrGroup.controls.postalCode.value) {
          const adrData = deserialize(Address, adrGroup.value);
          if (this.user.postalAddresses.length) {
            requests.push(
              this._addressService.putAddress(this.user.uuid, this.user.postalAddresses[idx].uuid, adrData),
            );
          } else if (adrForm.dirty) {
            requests.push(this._addressService.postAddress(this.user.uuid, adrData));
          }
        }
      });
    }
    return requests;
  }

  private _getUserSaveRequests(): Array<Observable<any>> {
    const requests: Array<Observable<any>> = [];

    const usrReq = deserialize(
      User,
      Object.assign(serialize(this.user), this.nameAddrForm.controls.names.value),
    );

    // If existing user, also add sub collections
    if (this.user.uuid) {
      if (this.isAdminContext) {
        requests.push(this._userService.putUser(this.user.uuid, usrReq, true));
      } else {
        requests.push(this._userService.putSelf(usrReq));
      }
      requests.push(...this._getPutUserRequests());
    } else {
      requests.push(this._userService.postUser(usrReq));
    }

    return requests;
  }
}
