import { ProcessableInterface } from '@core/interfaces/processable.interface';
import { alias, identifier, serializable } from 'serializr';

export class CorsUriEntity implements ProcessableInterface {
  processing: boolean;

  @serializable(alias('uuid', identifier()))
  private _id: string;

  @serializable(alias('url'))
  private _url: string;

  /**
   * @returns {string}
   */
  get id(): string {
    return this._id;
  }

  /**
   * @returns {string}
   */
  get url(): string {
    return this._url;
  }

  /**
   * @param {string} value
   */
  set url(value: string) {
    this._url = value;
  }
}
