import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { User } from '@core/entities/user/user.entity';
import { UserService } from '@core/services/user/user.service';
import { NinValidator } from '@shared/validators/nin.validator';

@Component({
  selector: 'app-user-ssn-form',
  templateUrl: './user-ssn-form.component.html',
  styleUrls: ['./user-ssn-form.component.scss'],
})
export class UserSsnFormComponent implements OnChanges, OnInit {
  @Output()
  changed: EventEmitter<any> = new EventEmitter();

  @Input()
  ssnFormCtrl: FormControl<string>;

  @Input()
  userToBeEdited: User;

  constructor(private _userService: UserService) {}

  /**
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userToBeEdited'] && !changes['userToBeEdited'].isFirstChange()) {
      this._buildForm();
    }
  }

  ngOnInit() {
    this._buildForm();
  }

  /**
   * @private
   */
  private _buildForm() {
    const existingSsn = this.userToBeEdited.nin || null;

    this.ssnFormCtrl.setValue(existingSsn);
    this.ssnFormCtrl.setValidators([NinValidator.ninSyntaxInvalid, Validators.required]);
    this.ssnFormCtrl.setAsyncValidators([
      NinValidator.ninTaken(this._userService, existingSsn),
      NinValidator.ninApiValidate(this._userService, existingSsn),
    ]);
  }
}
