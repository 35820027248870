import { alias, date, identifier, serializable } from 'serializr';

export class Address {
  @serializable(alias('country'))
  private _country: string;

  @serializable(alias('countryCode'))
  private _countryCode: string;

  @serializable(alias('createdAt', date()))
  private readonly _createdAt: Date;

  @serializable(alias('line1'))
  private _line1: string;

  @serializable(alias('line2'))
  private _line2: string;

  @serializable(alias('line3'))
  private _line3: string;

  @serializable(alias('primary'))
  private _primary: boolean = false;

  @serializable(alias('postalCode'))
  private _postalCode: string;

  @serializable(alias('uuid', identifier()))
  private readonly _uuid: string;

  @serializable(alias('postalArea'))
  private _postalArea: string;

  get postalArea(): string {
    return this._postalArea;
  }

  set postalArea(value: string) {
    this._postalArea = value;
  }

  get country(): string {
    return this._country;
  }

  set country(value: string) {
    this._country = value;
  }

  get countryCode() {
    return this._countryCode;
  }

  set countryCode(value: string) {
    this._countryCode = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  get line1(): string {
    return this._line1;
  }

  set line1(value: string) {
    this._line1 = value;
  }

  get line2(): string {
    return this._line2;
  }

  set line2(value: string) {
    this._line2 = value;
  }

  get line3(): string {
    return this._line3;
  }

  set line3(value: string) {
    this._line3 = value;
  }

  get primary(): boolean {
    return this._primary;
  }

  set primary(value: boolean) {
    this._primary = value;
  }

  get postalCode(): string {
    return this._postalCode;
  }

  set postalCode(value: string) {
    this._postalCode = value;
  }

  get uuid(): string {
    return this._uuid;
  }
}
