import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Email } from '@core/entities/user/email.entity';

function isEmptyInputValue(value: any): boolean {
  return value === null || value.length === 0;
}

export class EmailValidator {
  static readonly EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)+$/i;
  static readonly ERROR_DUPLICATE_EMAIL = 'duplicate_email';
  static readonly ERROR_INVALID_EMAIL = 'invalid';
  static readonly ERROR_REQUIRED = 'required';
  static readonly ERROR_UNSAVED_CHANGES = 'unsaved_changes';

  /**
   * Check if email exists
   *
   * @param {Email[]} userEmails
   * @returns {ValidatorFn}
   */
  static duplicateEmail(userEmails: Email[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value || control.value.length < 1) {
        return null;
      }

      if (userEmails.map((obj) => obj.emailAddress).indexOf(control.value) > -1) {
        return { [EmailValidator.ERROR_DUPLICATE_EMAIL]: true };
      }

      return null;
    };
  }

  /**
   * Check if the control has been saved to API (has uuid)
   *
   * @param {AbstractControl} control
   * @returns {ValidationErrors | null}
   */
  static parentHasUuid(control: AbstractControl): ValidationErrors | null {
    // Void check if email not filled
    if (isEmptyInputValue(control.value) || !control.parent || !control.parent.get('uuid')) {
      return null;
    }

    const error: ValidationErrors = { [EmailValidator.ERROR_UNSAVED_CHANGES]: true };
    return control.parent.get('uuid').value && control.parent.get('uuid').value.length ? null : error;
  }

  /**
   * A validator which returns result of this.isValidEmail() method
   *
   * @param {AbstractControl} control
   * @returns {ValidationErrors | null}
   */
  static validEmailSyntax(control: AbstractControl): ValidationErrors | null {
    // Void check if email not filled
    if (isEmptyInputValue(control.value)) {
      return null;
    }

    const error: ValidationErrors = { [EmailValidator.ERROR_INVALID_EMAIL]: true };
    return EmailValidator.isValidEmailSyntax(control.value) ? null : error;
  }

  /**
   * Regular method that returns whether given string is valid email.
   * Separate method so it can be called directly and not only as validator
   *
   * @param {string} emailAdr
   * @returns {boolean}
   */
  static isValidEmailSyntax(emailAdr: string): boolean {
    const regex = new RegExp(EmailValidator.EMAIL_REGEXP);
    return regex.test(emailAdr);
  }
}
