<div #inputWrap>
  <ng-content class="input" select="input"></ng-content>
</div>

@if (showToggleButton) {
  <app-ff-tooltip
    (clicked)="toggle()"
    (focusin)="active = true"
    (focusout)="active = false"
    (mouseenter)="active = true"
    (mouseleave)="active = false"
    [options]="tooltipOptions"
    class="tooltip"
    text="Slå synlighet av/på"
    >
    <svg-icon
      [applyClass]="true"
      [class.active]="active"
      [class]="iconClassList"
      [src]="revealing ? 'assets/icons/eye_closed_24.svg' : 'assets/icons/eye_24.svg'"
      role="presentation"
    ></svg-icon>
  </app-ff-tooltip>
}
