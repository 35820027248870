<!-- existing / none -->
<ul class="existing">
  @if (uris.length < 1) {
    <li class="redirect well">Ingen videresendingsadresser angitt</li>
  }

  @for (uri of uris; track uri; let idx = $index) {
    <li class="redirect well">
      <app-ff-delete-button
        (clicked)="confirmRemoval(uri)"
        [processing]="uri.processing"
        class="delete"
        titleText="Slett denne videresendingsadressen"
      ></app-ff-delete-button>
      <div class="uri">{{ uri.uri }}</div>
      <app-ff-checkbox
        (changed)="toggleDevUri(uri)"
        [checked]="uri.isDevUri"
        class="toggle"
        helpText="Hvis videresendingsadressen markeres for utviklingsmodus vil den kun fungere når klienten er i utviklingsmodus, og omvendt"
        labelText="Utviklingsmodus"
      ></app-ff-checkbox>
    </li>
  }
</ul>

<!-- Add new -->
@if (showAddForm) {
  <form [formGroup]="uriForm" class="form well new-redirect">
    <h3>Ny videresendingsadresse</h3>
    <div class="subtitle">Oppgi URL og alternativer for ny videresendingsadresse</div>
    <div class="form-group">
      <div class="form-control redirect-uri">
        <input
          [formControl]="formHelper.getCtrl(uriForm, 'uri')"
          class="uri"
          id="new_redir_uri"
          inputmode="url"
          placeholder="URI"
          type="text"
          />
      </div>
    </div>
    <div class="buttons">
      @if (showAddForm) {
        <app-ff-button
          (clicked)="save()"
          [processing]="processing"
          class="inline padded"
          label="Legg til videresendingsadresse"
        ></app-ff-button>
      }
      @if (showAddForm) {
        <app-ff-button
          (clicked)="toggleAddForm()"
          class="inline padded"
          color="gray"
          label="Avbryt"
        ></app-ff-button>
      }
    </div>
  </form>
}

<!-- New trigger toggle -->
<div class="buttons">
  @if (!showAddForm) {
    <app-ff-button
      (clicked)="toggleAddForm()"
      (keyup.enter)="toggleAddForm()"
      class="inline padded"
      label="Legg til videresendingsadresse"
    ></app-ff-button>
  }
</div>
