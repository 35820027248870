import { Highlightable } from "@angular/cdk/a11y";
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "app-ff-menu-item",
  templateUrl: "./ff-menu-item.component.html",
  styleUrls: ["./ff-menu-item.component.scss"],
})
export class FfMenuItemComponent implements Highlightable {
  #isActive = false;

  @Output()
  clicked: EventEmitter<any> = new EventEmitter();

  @HostBinding("attr.disabled")
  @Input({ required: false })
  disabled?: boolean = null;

  @Input({ required: false })
  iconPath?: string;

  @HostBinding("attr.aria-label")
  @Input({ required: true })
  label: string;

  @HostBinding("attr.processing")
  @Input({ required: false })
  processing?: boolean = false;

  @HostBinding("attr.role")
  role = "listitem";

  elementRef: ElementRef;

  constructor(private _elementRef: ElementRef) {
    this.elementRef = _elementRef;
  }

  @HostBinding("class.active")
  get isActive() {
    return this.#isActive && !this.disabled;
  }

  @HostBinding("class.disabled")
  get isDisabled(): boolean {
    return this.disabled || this.processing;
  }

  @HostBinding("tabindex")
  get tabIndex() {
    return "0";
  }

  @HostListener("mouseenter", ["$event"]) onEnter() {
    this.setActiveStyles();
  }

  @HostListener("mouseleave", ["$event"]) onLeave() {
    this.setInactiveStyles();
  }

  @HostListener("click")
  click() {
    if (!this.disabled && !this.processing) {
      this.clicked.emit();
    }
  }

  getLabel() {
    return this.label;
  }

  setActiveStyles() {
    this.#isActive = true;
  }

  setInactiveStyles() {
    this.#isActive = false;
  }
}
