<div class="modal__body">
  <form [formGroup]="modalForm" [class.submitted]="submitted" class="form">
    @if (!!data.showSecretInput) {
      <div class="form-group">
        <div class="form-control">
          <label for="secret">Ny nøkkel</label>
          <input
            (input)="showErrorsIfSubmitted()"
            [formControl]="secretCtrl"
            id="secret"
            placeholder="Generer automatisk"
            />
          <div class="helper-text">Det anbefales å la feltet stå tomt for å generere ny nøkkel</div>
        </div>
      </div>
    }

    <div class="form-group">
      <div class="form-control">
        <label for="passReprompt">Ditt passord</label>
        <input (input)="showErrorsIfSubmitted()" [formControl]="passCtrl" id="passReprompt" type="password" />
        <div class="helper-text">
          Du behandler sensitiv info og bes derfor oppgi ditt personlige passord for å fortsette
        </div>
      </div>
    </div>
  </form>
</div>

<footer class="modal__footer buttons">
  <app-ff-button
    (clicked)="elevate()"
    [processing]="processing"
    class="buttons__button padded"
    label="Fortsett"
  ></app-ff-button>

  <app-ff-button (clicked)="cancel()" class="buttons__button padded" color="beige" label="Avbryt"></app-ff-button>
</footer>

<app-feedback-messages [messages]="feedbackMessages"></app-feedback-messages>
