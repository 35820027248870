import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ClientEntity } from '@core/entities/client/client.entity';
import { ModalService } from '@core/services/modal/modal.service';
import { FfConfirmModalDataInterface } from '@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal-data.interface';
import { FfConfirmModalComponent } from '@shared/components/ff-modals/ff-confirm-modal/ff-confirm-modal.component';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
})

export class ClientListComponent implements OnChanges {
  @Input()
  clients: ClientEntity[];

  @Input()
  hideDeleteBtn: boolean = false;

  @Input()
  linkedTitle: boolean = false;

  @Input()
  processing: boolean = false;

  @Output()
  removed: EventEmitter<ClientEntity> = new EventEmitter<ClientEntity>();

  @Input()
  removeLabel: string = 'Fjern';

  constructor(private _modalService: ModalService) {}

  /**
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['processing'] && !changes['processing'].isFirstChange()) {
      this.processing = !!changes['processing'].currentValue;
    }
  }

  /**
   * @param {ClientEntity} client
   */
  confirmRemoval(client: ClientEntity) {
    const confirmContent: FfConfirmModalDataInterface = {
      component: FfConfirmModalComponent,
      title: 'Er du sikker?',
      body: 'Vil du faktisk fjerne klienten «' + client.name + '»?',
      confirmCallback: () => {
        this.removed.emit(client);
      },
    };

    this._modalService.openModal(confirmContent);
  }
}
