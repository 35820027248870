<div class="container">
  <div class="content">
    <h1>Fortsett</h1>
    <div class="subtitle">
      Du har nå logget inn i Fagforbundet ID.
      @if (clientName) {
        <span>Fortsett til {{ clientName }}</span>
      }
    </div>

    <a class="button" [href]="proceedUrl">Fortsett</a>
  </div>
</div>
