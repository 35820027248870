import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FfModalDataInterface } from '@shared/components/ff-modals/ff-modal-data.interface';

@Directive({
  selector: '[appFfModalBaseClass]',
})
export class FfModalBaseClassDirective {
  @Output()
  closeModal: EventEmitter<void> = new EventEmitter();

  @Input()
  data: FfModalDataInterface;
}
