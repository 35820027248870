import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bankAccount' })
export class BankAccountPipe implements PipeTransform {
  /**
   * @returns {string}
   * @param bankAccountNumber
   */
  transform(bankAccountNumber: string): string {
    return !bankAccountNumber ? '' : /^(\d{4})(\d{2})(\d{5})$/
      [Symbol.split](bankAccountNumber.replace(/\./g, '').trim())
      .filter((item) => item)
      .join('.');
  }
}
