import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Address } from '@core/entities/user/address.entity';
import { AddressHelper } from '@core/helpers/address.helper';
import { EnvironmentHelper } from '@core/helpers/environment.helper';
import { UserService } from '@core/services/user/user.service';
import { Observable } from 'rxjs';
import { concatMap, map, tap } from 'rxjs/operators';
import { deserialize, serialize } from 'serializr';
import { UserStoreService } from '../stores/user-store.service';

@Injectable({ providedIn: 'root' })
export class AddressService {
  /**
   * @param _http
   * @param _userService
   * @param _userStore
   */
  constructor(private _http: HttpClient, private _userService: UserService, private _userStore: UserStoreService) {}

  /**
   *  Convert new Address class to old definition
   */
  private _convertToOld(newAddress: Address) {
    const address = serialize(newAddress);
    address['city'] = address.postalArea;
    return address;
  }

  /**
   *  Convert to Address class from old definition
   */
  private _convertToNew(oldAddress: object) {
    const address = deserialize(Address, oldAddress);
    address.postalArea = oldAddress['city'];
    return address;
  }

  /**
   * @param userUuid
   * @param newAddress
   */
  postAddress(userUuid: string, newAddress: Address): Observable<Address> {
    const address$ = this._http
      .post(EnvironmentHelper.fetchAPIBase('v1/addresses'), {
        address: this._convertToOld(newAddress),
        userUuid,
      })
      .pipe(
        map((response: { address: object }) => {
          return this._convertToNew(response.address);
        }),
      );

    return this._userService.isSelf(userUuid).pipe(
      concatMap((isSelf: boolean) => {
        return address$.pipe(
          tap((address: Address) => {
            if (isSelf) {
              const user = this._userStore.user;
              const existingAddresses = [...user.postalAddresses];
              existingAddresses.push(address);
              user.postalAddresses = existingAddresses;
              this._userStore.user = user;
            }
          }),
        );
      }),
    );
  }

  /**
   * @param userUuid
   * @param uuid
   * @param newAddress
   */
  putAddress(userUuid: string, uuid: string, newAddress: Address): Observable<Address> {
    const address$ = this._http
      .put(EnvironmentHelper.fetchAPIBase('v1/addresses/' + uuid), {
        address: this._convertToOld(newAddress),
        userUuid,
      })
      .pipe(
        map((response: { address: object }) => {
          return this._convertToNew(response.address);
        }),
      );

    return this._userService.isSelf(userUuid).pipe(
      concatMap((isSelf: boolean) => {
        return address$.pipe(
          tap((address: Address) => {
            if (isSelf) {
              const user = this._userStore.user;
              const existingAddresses = [...user.postalAddresses];
              user.postalAddresses = AddressHelper.replaceAddress(existingAddresses, address);
              this._userStore.user = user;
            }
          }),
        );
      }),
    );
  }
}
