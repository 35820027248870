import { ClientEntity } from "@core/entities/client/client.entity";
import { GrantEntity } from "@core/entities/oauth/grant.entity";
import { ScopeExpirationDateEntity } from "@core/entities/oauth/scope-expiration-date.entity";
import { ProcessableInterface } from "@core/interfaces/processable.interface";
import {
  alias,
  custom,
  date,
  identifier,
  list,
  object,
  serializable,
} from "serializr";
import { noop } from "rxjs";

export class ScopeEntity implements ProcessableInterface {
  expand: boolean;
  highlight: boolean;
  processing: boolean;

  @serializable(alias("allowedGrants", list(object(GrantEntity))))
  private _allowedGrants: GrantEntity[] = [];

  @serializable(alias("createdAt", date()))
  private _createdAt: Date;

  @serializable(alias("description"))
  private _description: string;

  @serializable(alias("editable"))
  private readonly _editable: boolean;

  @serializable(alias("global"))
  private _global: boolean;

  @serializable(alias("inUse"))
  private readonly _inUse: boolean;

  @serializable(alias("locked"))
  private readonly _locked: boolean;

  @serializable(alias("name"))
  private _name: string;

  @serializable(alias("required"))
  private _required: boolean;

  @serializable(
    alias(
      "expirationDate",
      custom(
        () => noop,
        (val, context) => {
          context.target.scopeExpirationDate = val;
        }
      )
    )
  )
  private _expirationDate?: ScopeExpirationDateEntity;

  @serializable(alias("scopeExpirationDate", object(ScopeExpirationDateEntity)))
  private _scopeExpirationDate?: ScopeExpirationDateEntity;

  @serializable(alias("uuid", identifier()))
  private _uuid: string;

  private _clients: ClientEntity[] = [];

  constructor(name: string, description?: string, required: boolean = false) {
    this._description = description;
    this._name = name;
    this._required = required;
  }

  get clients(): ClientEntity[] {
    return this._clients;
  }

  set clients(value: ClientEntity[]) {
    this._clients = value;
  }

  get allowedGrants(): GrantEntity[] {
    return this._allowedGrants;
  }

  set allowedGrants(value: GrantEntity[]) {
    this._allowedGrants = value;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get editable(): boolean {
    return this._editable;
  }

  get global(): boolean {
    return this._global;
  }

  set global(value: boolean) {
    this._global = value;
  }

  get inUse(): boolean {
    return this._inUse;
  }

  get locked(): boolean {
    return this._locked;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get required(): boolean {
    return this._required;
  }

  set required(value: boolean) {
    this._required = value;
  }

  get scopeExpirationDate(): ScopeExpirationDateEntity {
    return this._scopeExpirationDate;
  }

  set scopeExpirationDate(value: ScopeExpirationDateEntity) {
    this._scopeExpirationDate = value;
  }

  get uuid(): string {
    return this._uuid;
  }

  set uuid(value: string) {
    this._uuid = value;
  }
}
