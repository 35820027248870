import { Injectable } from '@angular/core';
import { EnvironmentHelper } from '@core/helpers/environment.helper';

@Injectable({
  providedIn: 'root',
})
export class LogService {
  private _logConsented = false;

  init(): Promise<void> {
    return new Promise<void>((resolve) => {
      resolve();
    });
  }

  logException(exception: Error) {
    if (EnvironmentHelper.isDevelopment()) {
      console.error('[DEV EXCEPTION LOG]: ', exception);
    } else if (this._logConsented) {
      // Log to an API
    }
  }

  set logConsented(consented: boolean) {
    this._logConsented = consented;
  }
}
