<pagination-template
  #p="paginationApi"
  (pageBoundsCorrection)="pageBoundsCorrection.emit($event)"
  (pageChange)="pageChange.emit($event)"
  [id]="id"
  [maxSize]="maxSize"
  >
  @if (!(autoHide && p.pages.length <= 1)) {
    <ul
      [attr.aria-label]="screenReaderPaginationLabel"
      [class.responsive]="responsive"
      class="ngx-pagination"
      role="toolbar"
      >
      @if (directionLinks) {
        <li [class.disabled]="p.isFirstPage()" class="pagination-previous">
          @if (1 < p.getCurrent()) {
            <a
              (click)="p.previous()"
              (keyup.enter)="p.previous()"
              [attr.aria-label]="previousLabel + ' ' + screenReaderPageLabel"
              tabindex="0"
        title="{{ previousLabel + ' ' + (screenReaderPageLabel | lowercase) }}: {{ p.getCurrent() - 1 }} / {{
          p.getLastPage()
        }}"
              >
              {{ previousLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </a>
          }
          @if (p.isFirstPage()) {
            <span>
              {{ previousLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </span>
          }
        </li>
      }
      <li class="small-screen">{{ p.getCurrent() }} / {{ p.getLastPage() }}</li>
      @for (page of p.pages; track page) {
        <li
          [class.current]="p.getCurrent() === page.value"
          [class.ellipsis]="page.label === '...'"
          >
          @if (p.getCurrent() !== page.value) {
            <a
              (click)="p.setCurrent(page.value)"
              (keyup.enter)="p.setCurrent(page.value)"
              tabindex="0"
              title="Side {{ page.label }} / {{ p.getLastPage() }}"
              >
              <span class="show-for-sr">{{ screenReaderPageLabel }} </span>
              <span>{{ page.label === '...' ? page.label : (page.label | number: '') }}</span>
            </a>
          }
          @if (p.getCurrent() === page.value) {
            <span class="show-for-sr">{{ screenReaderCurrentLabel }} </span>
            <span title="Gjeldende side: {{ page.label }} av {{ p.getLastPage() }}">
              {{ page.label === '...' ? page.label : (page.label | number: '') }}
            </span>
          }
        </li>
      }
      @if (directionLinks) {
        <li [class.disabled]="p.isLastPage()" class="pagination-next">
          @if (!p.isLastPage()) {
            <a
              (click)="p.next()"
              (keyup.enter)="p.next()"
              [attr.aria-label]="nextLabel + ' ' + screenReaderPageLabel"
              tabindex="0"
        title="{{ nextLabel + ' ' + (screenReaderPageLabel | lowercase) }}: {{ p.getCurrent() + 1 }} av {{
          p.getLastPage()
        }}"
              >
              {{ nextLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </a>
          }
          @if (p.isLastPage()) {
            <span>
              {{ nextLabel }} <span class="show-for-sr">{{ screenReaderPageLabel }}</span>
            </span>
          }
        </li>
      }
    </ul>
  }
</pagination-template>
