@if (data.body) {
  <div class="modal__body">
    {{ data.body }}
  </div>
}

<footer class="modal__footer buttons">
  <app-ff-button
    (clicked)="confirm()"
    class="buttons__button padded"
    label="{{ data.confirmLabel ? data.confirmLabel : 'Bekreft' }}"
  ></app-ff-button>

  <app-ff-button
    (clicked)="cancel()"
    class="buttons__button padded"
    color="beige"
    label="{{ data.cancelLabel ? data.cancelLabel : 'Avbryt' }}"
  ></app-ff-button>
</footer>
