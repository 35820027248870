import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'app-ff-loading-indicator',
  templateUrl: './ff-loading-indicator.component.html',
  styleUrls: ['./ff-loading-indicator.component.scss'],
})

export class FfLoadingIndicatorComponent {
  @Input()
  done: boolean;

  @Input()
  error: boolean;

  @Input()
  size?: 'small' | 'medium' | 'large' | 's16' | 's18' | 's20' | 's24';

  @Input()
  stroke?: string;

  @HostBinding('attr.role')
  readonly role: string = 'spinner';
}
